<template>
  <div class="popup" v-if="visible == 1">
    <div class="shadow" @click="close()"></div>
    <div class="container">
      <header>
        <h5>Zmień status</h5>
        <div class="close" @click="close()">
          <i class="material-icons">close</i>
        </div>
      </header>
      <div class="wrapper">
        <div class="flex">
          <div class="status orange" :class="{ 'bg-orange': clicked == 8 }" v-if="eventEdit.type == 1 && eventEdit.status != 1 && eventEdit.status != 8" @click="confirm(8)">
            <i class="material-icons">place</i>
            <h5>Umowa na miejscu</h5>
          </div>
          <div class="status blue" :class="{ 'bg-blue': clicked == 3 }" v-if="eventEdit.type == 1 && eventEdit.status != 1 && eventEdit.status != 3" @click="confirm(3)">
            <i class="material-icons">more_horiz</i>
            <h5>Oczekiwanie na przesyłkę</h5>
          </div>
           <div class="status purple" :class="{ 'bg-purple': clicked == 4 }" v-if="eventEdit.type == 1 && eventEdit.status != 1 && eventEdit.status != 4" @click="confirm(4)">
            <i class="material-icons">email</i>
            <h5>Umowa wysłana</h5>
          </div>
          <div class="status green" :class="{ 'bg-green': clicked == 5 }" v-if="eventEdit.type == 1 && eventEdit.status != 1 && eventEdit.status != 5" @click="confirm(5)">
            <i class="material-icons">done</i>
            <h5>Umowa podpisana</h5>
          </div>
          <div class="status gray" :class="{ 'bg-gray': clicked == 7 }" v-if="eventEdit.type == 4 && eventEdit.status == 6" @click="confirm(7)">
            <i class="material-icons">done</i>
            <h5>Zakończone</h5>
          </div>
        </div>
        <div class="flex-between">
          <button class="default small reverse" @click="close()">Anuluj</button>
          <button class="default small" @click="changeStatus()">Potwierdź</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PopupVirtualOfficeStatus',
  data(){
    return{
      clicked: 0,
    }
  },
  computed:{
    visible(){
      return this.$store.state.popup;
    },
    api() {
      return this.$store.state.api;
    },
    eventEdit() {
      return this.$store.state.eventEdit;
    },
  },
  methods: {
    close(){
      this.clicked = -1;
      this.$store.commit('togglePopup', 0);
      document.body.style = "overflow-y: overlay";
    },
    confirm(type){
      this.clicked = type;
    },
    changeStatus(){
      if(this.clicked == -1){
        this.$store.commit('toggleAlert', { visibility: 1, type: 0, msg: 'Proszę potwierdzić zmianę klikając na ikonę' });
      }else{
        let self = this;
        this.axios
        .put(this.api + 'employee/events/virtual-office/status', { id: this.eventEdit.id, status: this.clicked }, { headers: { Authorization: localStorage.accessToken } })
        .then(response => {
          if(response.data.status){
            self.close();
            self.$store.commit('reloadEvents');
          }
          self.$store.commit('toggleAlert', {
            visibility: 1,
            type: response.data.status,
            msg: response.data.msg
          });
        });
      }
    }
  },
}
</script>
<style scoped lang="scss">
  div.flex { display: flex; flex-wrap: wrap; margin: 40px 0; }
  div.flex-between { display: flex; flex-wrap: wrap; justify-content: space-between; margin: 20px 0; }

  div.status { display: flex; flex-direction: column; justify-content: flex-end; align-items: center; width: 140px; height: 140px; margin: 0 10px 10px 0;
   border: 2px solid $grayLighter2; border-radius: 3px; cursor: pointer; }
  div.status i.material-icons { margin: 0 0 13px 0; font-size: 40px; color: $grayLighter2; }
  div.status h5 { height: 50px; margin: 0; padding: 0 15px; font-weight: 300; font-size: 14px; color: $grayLighter2; text-align: center; }

  div.status.bg-red, div.status.red:hover { background: $redGradient; border-color: $colorRed; }
  div.status.bg-red i.material-icons, div.status.red:hover i.material-icons{ color: #fff;}
  div.status.bg-red h5, div.status.red:hover h5 { color: #fff; font-weight: 500; }
  div.status.bg-green, div.status.green:hover { background: $greenGradient; border-color: $colorGreen; }
  div.status.bg-green i.material-icons, div.status.green:hover i.material-icons{ color: #fff;}
  div.status.bg-green h5, div.status.green:hover h5 { color: #fff; font-weight: 500; }
  div.status.bg-blue, div.status.blue:hover { background: $blueGradient; border-color: $colorBlue; }
  div.status.bg-blue i.material-icons, div.status.blue:hover i.material-icons{ color: #fff;}
  div.status.bg-blue h5, div.status.blue:hover h5 { color: #fff; font-weight: 500; }
  div.status.bg-purple, div.status.purple:hover { background: $purpleGradient; border-color: $colorPurple; }
  div.status.bg-purple i.material-icons, div.status.purple:hover i.material-icons{ color: #fff;}
  div.status.bg-purple h5, div.status.purple:hover h5 { color: #fff; font-weight: 500; }
  div.status.bg-gray, div.status.gray:hover { background: $grayGradient; border-color: $grayLighter; }
  div.status.bg-gray i.material-icons, div.status.gray:hover i.material-icons{ color: #fff;}
  div.status.bg-gray h5, div.status.gray:hover h5 { color: #fff; font-weight: 500; }
  div.status.bg-orange, div.status.orange:hover { background: $orangeGradient; border-color: $colorOrange; }
  div.status.bg-orange i.material-icons, div.status.orange:hover i.material-icons{ color: #fff;}
  div.status.bg-orange h5, div.status.orange:hover h5 { color: #fff; font-weight: 500; }
</style>
