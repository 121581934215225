<template>
  <div class="popup" v-if="visible == 4">
    <div class="shadow" @click="close()"></div>
    <div class="container">
      <header>
        <h5>Szczegóły</h5>
        <div class="close" @click="close()">
          <i class="material-icons">close</i>
        </div>
      </header>
      <div class="wrapper" v-if="eventDetails != 0">
        <div class="label orange" v-if="eventDetails.serviceData.courierData == null">
          <i class="material-icons">place</i>
          <p>Umowa na miejscu</p>
        </div>
        <div class="label blue" v-if="eventDetails.serviceData.courierData != null">
          <i class="material-icons">email</i>
          <p>Umowa wysyłana kurierem</p>
        </div>
        <div class="data" v-if="eventDetails.serviceData.courierData != null">
          <header>
            <h6>Dane dla kuriera</h6>
          </header>
          <div class="container">
            <ul>
              <li><span>Imię: </span>{{ eventDetails.serviceData.courierData.name }}</li>
              <li><span>Nazwisko: </span>{{ eventDetails.serviceData.courierData.surname }}</li>
              <li><span>Nazwa firmy: </span>{{ eventDetails.serviceData.courierData.companyName }}</li>
              <li><span>Telefon: </span>{{ eventDetails.serviceData.courierData.number }}</li>
              <li><span>Ulica: </span>{{ eventDetails.serviceData.courierData.street }}</li>
              <li><span>Numer domu/mieszkania: </span>{{ eventDetails.serviceData.courierData.houseNumber }}</li>
              <li><span>Kod pocztowy: </span>{{ eventDetails.serviceData.courierData.postCode }}</li>
              <li><span>Miasto: </span>{{ eventDetails.serviceData.courierData.city }}</li>
              <li><span>Uwagi dla kuriera: </span>{{ eventDetails.serviceData.courierData.comments }}</li>
            </ul>
          </div>
        </div>
        <div class="data">
          <header>
            <h6>Dane firmowe</h6>
          </header>
          <div class="container">
            <ul>
              <li><span>NIP:</span> {{ eventDetails.companyData.nip }}</li>
							<li><span>Nazwa firmy:</span> {{ eventDetails.companyData.company_name }}</li>
							<li><span>Ulica:</span> {{ eventDetails.companyData.street }}</li>
							<li><span>Numer lokalu:</span> {{ eventDetails.companyData.number_house }}</li>
							<li><span>Miejsowość:</span> {{ eventDetails.companyData.city }}</li>
							<li><span>Kod pocztowy:</span> {{ eventDetails.companyData.post_code }}</li>
            </ul>
          </div>
        </div>
        <div class="data">
          <header>
            <h6>Osoby podpisujące umowę</h6>
          </header>
          <div class="container">
           <ul v-for="(person, index) in eventDetails.serviceData.contractSigners" :key="'contract' + index">
							<li><span>Imię:</span> {{ person.name }}</li>
							<li><span>Nazwisko:</span> {{ person.surname }}</li>
							<li><span>Pesel / paszport:</span> {{ person.id }}</li>
							<li v-if="person.otherPosition"><span>Stanowisko:</span> {{ person.otherPosition }}</li>
							<li v-if="!person.otherPosition"><span>Stanowisko:</span> {{ person.position }}</li>
						</ul>
          </div>
        </div>
        <div class="data">
          <header>
            <h6>Dane do korespondencji</h6>
          </header>
          <div class="container">
            <ul>
              <li><span>Miasto:</span> {{ eventDetails.serviceData.correspondenceAddress.city }}</li>
              <li><span>Ulica:</span> {{ eventDetails.serviceData.correspondenceAddress.street }}</li>
              <li><span>Numer domu/mieszkania:</span> {{ eventDetails.serviceData.correspondenceAddress.houseNumber }}</li>
              <li><span>Kod pocztowy:</span> {{ eventDetails.serviceData.correspondenceAddress.postCode }}</li>
              <li><span>Dodatkowe uwagi:</span> {{ eventDetails.serviceData.correspondenceAddress.comments }}</li>
            </ul>
            <ul>
              <li v-for="(number, index) in eventDetails.serviceData.correspondenceNumber" :key="'number' + index">
                <span>Telefon nr. {{ index + 1 }}:</span> {{ number.number }}
              </li>
            </ul>
            <ul>
              <li v-for="(email, index) in eventDetails.serviceData.correspondenceEmail" :key="'email' + index">
                <span>Adres email nr. {{ index + 1 }}:</span> {{ email.email }}
              </li>
            </ul>
          </div>
        </div>
        <div class="data">
          <header>
            <h6>Osoby upoważnione do odbioru korespondencji</h6>
          </header>
          <div class="container">
            <ul v-for="(person, index) in eventDetails.serviceData.correspondencePeople" :key="'correspondence' + index">
              <li><span>Imię: </span>{{ person.name }}</li>
              <li><span>Nazwisko: </span>{{ person.surname }}</li>
              <li><span>Pesel / paszport: </span>{{ person.id }}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PopupVirtualOfficeDetails',
  data(){
    return{
      eventDetails: 0,
    }
  },
  computed:{
    visible(){
      return this.$store.state.popup;
    },
    api() {
      return this.$store.state.api;
    },
    eventId() {
      return this.$store.state.eventId;
    },
  },
  watch: {
    visible() {
      this.getEventDetailsData();
    }
  },
  methods: {
    close(){
      this.$store.commit('togglePopup', 0);
      document.body.style = "overflow-y: overlay";
    },
    getEventDetailsData(){
      let self = this;
      this.axios
      .get(this.api + 'employee/events/virtual-office/event/details', { params: { eventId: this.eventId }, headers: { Authorization: localStorage.accessToken } })
      .then(response => {
        self.eventDetails = response.data.data;
      });
    },
  },
}
</script>
<style scoped lang="scss">
  div.popup div.container { width: 700px; }

  div.label { display: flex; justify-content: space-between; width: 450px; height: 40px; margin: 10px auto; }
  div.label i.material-icons { font-size: 22px; }
  div.label p { font-size: 16px; }

  div.data { width: calc(100% - 40px); max-width: 450px; margin: 15px auto; }
  div.data header h6 { margin: 0; padding: 0 21px; color: $grayLighter; font-weight: 400; font-size: 13px; }
  div.data div.container { width: 100%; padding: 0 15px; border-radius: 5px; background: rgba(228, 228, 230, 1); }
  div.data ul { display: block; width: 100%; margin: 0; padding: 10px 0; list-style: none; border-top: 2px solid rgba(255, 255, 255, 0.2);  }
  div.data ul:first-child { border: 0; }
  div.data ul li { margin: 5px; font-size: 15px; word-wrap: break-word; }
  div.data ul li span { color: $grayLight; }

  @media (max-width: 700px) {
    div.popup div.container { width: calc(100vw - 20px); }
  }
</style>
