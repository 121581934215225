<template>
  <div class="mailing-theme">
    <div class="flex">
      <section class="email">
        <div class="box title">
          <header class="box">
            <h6>Wprowadź tytuł maila</h6>
          </header>
          <div class="input-container" title="Tytuł emaila">
            <div class="input-box">
              <input type="text" class="data" placeholder="Tytuł emaila" v-model="title">
            </div>
            <p v-if="!titleChecked">Tytuł musi mieć od 1 do 50 znaków.</p>
          </div>
        </div>
        <div class="box emails">
          <header class="box">
            <h6>Wprowadź emaile</h6>
          </header>
          <div class="input-container" title="Treść">
            <div class="input-box textarea">
              <textarea class="data" placeholder="Wprowadź emaile rozdzielone średnikiem i spacją, na przykład: example.com; example1.com;" v-model="emails"></textarea>
            </div>
            <p :class="{ good: emailsChecked.status == 1 }" v-if="emailsChecked.status != -1">{{ emailsChecked.msg }}</p>
          </div>
          <button class="default small" @click="checkEmails()">Sprawdź poprawność emaili</button>
        </div>
        <div class="box containers">
          <header class="box">
            <h6>Modyfikacja kontenerów</h6>
          </header>
          <div class="container" v-for="(container, index) in theme1Containers" :key="index">
            <header>
              <h6>Kontener #{{ index + 1 }}</h6>
              <i class="material-icons"@click="theme1ContainersDelete(index)">delete</i>
            </header>
            <div class="titles">
              <div class="title" :style="{ background: headliner.color }" v-for="headliner in constants.headliners" :key="headliner.id" 
                @click="chooseHeadliner(index, headliner.id)">
                <h5>{{ headliner.title }}</h5>
                <i class="material-icons" v-if="container.categoryId == headliner.id">done</i>
              </div>
              <p v-if="checkingData.containers[index].category != 0">{{ checkingData.containers[index].category }}</p>
            </div>
            <div class="input-container" title="Podtytuł">
              <div class="input-box">
                <input type="text" class="data" placeholder="Podtytuł" v-model="container.subtitle">
              </div>
              <p v-if="checkingData.containers[index].subtitle != 0">{{ checkingData.containers[index].subtitle }}</p>
            </div>
            <div class="input-container" title="Treść">
              <div class="input-box textarea">
                <textarea class="data" placeholder="Treść" v-model="container.text"></textarea>
              </div>
              <p v-if="checkingData.containers[index].text != 0">{{ checkingData.containers[index].text }}</p>
            </div>
            <div class="file">
              <h5>Załącznik: <span>{{ container.attachmentName }}</span></h5>
              <div>
                <button class="default small" v-if="container.attachmentId != 0" @click="deleteAttachment({ index: index })">Usuń załącznik</button>
                <button class="default small" @click="addAttachment({ index: index })">{{ getAttachmentButtonName(container.attachmentId) }}</button>
              </div>
            </div>
          </div>
          <button class="default small" @click="theme1ContainersAdd()">Dodaj nowy kontener</button>
        </div>
        <div class="box banner">
          <header class="box">
            <h6>Banner</h6>
          </header>
          <div class="img">
            <img v-if="theme1Banner.bannerId != 0" :src="theme1Banner.bannerLink">
            <h5 v-if="theme1Banner.bannerId == 0">Brak banneru</h5>
          </div>
          <div class="flex-end">
            <button class="default small" v-if="theme1Banner.bannerId != 0" @click="deleteBanner()">Usuń banner</button>
            <button class="default small" @click="addBanner()">{{ getBannerButtonName(theme1Banner.bannerId) }}</button>
          </div>
        </div>
        <button class="default view" @click="viewEmail">Podejrzyj i wyślij</button>
      </section>
      <aside>
        <mailing-attachments></mailing-attachments>
        <mailing-banners></mailing-banners>
      </aside>
    </div>
    <popup-mailing-upload-file></popup-mailing-upload-file>
    <popup-mailing-add-attachment></popup-mailing-add-attachment>
    <popup-mailing-add-banner></popup-mailing-add-banner>
    <popup-mailing-view></popup-mailing-view>
  </div>
</template>

<script>
import Loader from '@/components/Loader.vue'
import MailingAttachments from '@/components/MailingAttachments.vue'
import MailingBanners from '@/components/MailingBanners.vue'
import PopupMailingUploadFile from '@/components/popups/MailingUploadFile.vue'
import PopupMailingAddAttachment from '@/components/popups/MailingAddAttachment.vue'
import PopupMailingAddBanner from '@/components/popups/MailingAddBanner.vue'
import PopupMailingView from '@/components/popups/MailingView.vue'

export default {
  name: 'MailingTheme',
  components: {
    Loader,
    MailingAttachments,
    MailingBanners,
    PopupMailingUploadFile,
    PopupMailingAddAttachment,
    PopupMailingAddBanner,
    PopupMailingView
  },
  data(){
    return{
     loader: 1,
     themes: 0,
     constants: 0,
     title: '',
     titleChecked: 1,
     emails: '',
     emailsChecked: { status: -1, msg: '' },
     emailsArray: 0,
     theme1Containers: [],
     theme1Banner: { bannerId: 0, bannerLink: '' },
     checkingData: 0,
    }
  },
  computed: {
    api() {
      return this.$store.state.api;
    },
    mailingAttachment() {
      return this.$store.state.mailingAttachment;
    },
    mailingBanner() {
      return this.$store.state.mailingBanner;
    },
  },
  created(){
    this.getThemes();
    this.getConstants();
    if(this.$route.params.themeId == 1){
      this.checkingData = { containers: [] }
    }
  },
  watch:{
    $route(to, from) {
      if(this.$route.params.themeId == 1){
        this.checkingData = { containers: [] }
      }
    },
    themes(){
      if(this.themes != 0){
        let error = 1;
        for(let i = 0; i < this.themes.length; i++){
          if(this.themes[i].id == this.$route.params.themeId){
            error = 0;
            break;
          }
        }
        if(error){
          this.$router.push({ name: 'Mailing' })
        }
      }
    },
    mailingAttachment(){
      if(this.$route.params.themeId == 1 && this.mailingAttachment.attachmentId != 0){
        this.theme1Containers[this.mailingAttachment.index].attachmentId = this.mailingAttachment.attachmentId;
        this.theme1Containers[this.mailingAttachment.index].attachmentName = this.mailingAttachment.attachmentName;
        this.theme1Containers[this.mailingAttachment.index].attachmentLink = this.mailingAttachment.attachmentLink;
      }   
    },
    mailingBanner(){
      if(this.$route.params.themeId == 1 && this.mailingBanner.bannerId != 0){
        this.theme1Banner.bannerId = this.mailingBanner.bannerId;
        this.theme1Banner.bannerLink = this.mailingBanner.bannerLink;
      }   
    },
  },
  methods: {
    checkTitle(){
      if(this.title.length <= 0 || this.title.length > 50){
        this.titleChecked = 0;
      }else{
        this.titleChecked = 1;
      }
    },
    checkEmails(){
      const validator = require('validator')
      this.emailsChecked = { status: -1, msg: '' };
      let emailsReplaced = this.emails.replace(/ /g, '');
      if(this.emails == ''){
        this.emailsChecked = { status: 0, msg: 'Nie podano emaili' }
      }else{
        this.emailsArray = emailsReplaced.split(';');
        let emptyValues = []
        for(let i = 0; i < this.emailsArray.length; i++){
          if(this.emailsArray[i] == ''){
            emptyValues.push(i);
          }
        }
        for(let i = 0; i < emptyValues.length; i++){
          this.emailsArray.splice(emptyValues[i], 1);
        }
        for(let i = 0; i < this.emailsArray.length; i++){
          if(!validator.isEmail(this.emailsArray[i])){
            this.emailsChecked = { status: 0, msg: 'Email nr. ' + (i + 1) + ' jest niepoprawny - ' + this.emailsArray[i]}
            break;
          }
        }
        if(this.emailsChecked.status == -1){
          loop:
          for(let i = 0; i < this.emailsArray.length; i++){
            for(let j = 0; j < this.emailsArray.length; j++){
              if(this.emailsArray[i] == this.emailsArray[j] && i != j){
                this.emailsChecked = { status: 0, msg: 'Email nr. ' + (i + 1) + ' i email nr. '  + (j + 1) + ' są takie same - ' + this.emailsArray[i]}
                break loop;
              }
            }
          }
        }
      }
      if(this.emailsChecked.status == -1){
        this.emailsChecked = { status: 1, msg: 'Podane emaile są poprawne' }
      }
    },
    theme1ContainersAdd(){
      this.theme1Containers.push({ categoryId: 0, subtitle: '', text: '', attachmentId: 0, attachmentName: '-brak załącznika-', attachmentLink: '' })
      this.checkingData.containers.push({ category: 0, subtitle: 0, text: 0 })
    },
    theme1ContainersDelete(index){
      this.theme1Containers.splice(index, 1);
      this.checkingData.containers.splice(index, 1);
    },
    checkData(){
      let error = 0;
      if(this.$route.params.themeId == 1){
        for(let i = 0; i < this.theme1Containers.length; i++){
          if(this.theme1Containers[i].categoryId == 0){
            this.checkingData.containers[i].category = 'Nie zaznaczono nagłówka';
            error = 1;
          }else{
            this.checkingData.containers[i].category = 0;
          }
          if(this.theme1Containers[i].subtitle.length < 1 || this.theme1Containers[i].subtitle.length > 100){
            this.checkingData.containers[i].subtitle = 'Podtytuł może mieć od 1 do 100 znaków';
            error = 1;
          }else{
            this.checkingData.containers[i].subtitle = 0;
          }
          if(this.theme1Containers[i].text.length < 1 || this.theme1Containers[i].text.length > 500){
            this.checkingData.containers[i].text = 'Treść może mieć od 1 do 500 znaków';
            error = 1;
          }else{
            this.checkingData.containers[i].text = 0;
          }
        }
        if(this.theme1Containers.length < 1 || this.theme1Containers.length > 10){
          this.$store.commit('toggleAlert', {
            visibility: 1,
            type: 0,
            msg: 'Można wstawić od 1 do 10 kontenerów'
          });
          error = 1;
        }
      }
      return error;
    },
    getAttachmentButtonName(id){
      if(id != 0){
        return 'Zmień załącznik';
      }else{
        return 'Dodaj załącznik'
      }
    },
    addAttachment(data){
      if(this.$route.params.themeId == 1){
        this.$store.commit('changeMailingAttachment', { index: data.index, attachmentId: 0 });
      }
      this.$store.commit('togglePopup', 3);
    },
    deleteAttachment(data){
      if(this.$route.params.themeId == 1){
        this.theme1Containers[data.index].attachmentId = 0;
        this.theme1Containers[data.index].attachmentName = '-brak załącznika-';
        this.theme1Containers[data.index].attachmentLink = '';
      }
    },
    getBannerButtonName(id){
      if(id == 1){
        return 'Zmień banner';
      }else{
        return 'Dodaj banner'
      }
    },
    addBanner(data){
      if(this.$route.params.themeId == 1){
        this.$store.commit('changeMailingBanner', { bannerId: 0 });
      }
      this.$store.commit('togglePopup', 4);
    },
    deleteBanner(data){
      if(this.$route.params.themeId == 1){
        this.theme1Banner = { bannerId: 0, bannerLink: '' }
      }
    },
    chooseHeadliner(containerIndex, headlinerId){
      if(this.$route.params.themeId == 1){
        this.theme1Containers[containerIndex].categoryId = headlinerId;
      }
    },
    getConstants(){
      let self = this;
      this.axios
      .get(this.api + 'employee/mailing/constants', { params: { }, headers: { Authorization: localStorage.accessToken } })
      .then(response => {
        self.constants = response.data;
      });
    },
    getThemes(){
      let self = this;
      this.axios
      .get(this.api + 'employee/mailing/themes', { params: { }, headers: { Authorization: localStorage.accessToken } })
      .then(response => {
        self.themes = response.data;
      });
    },
    viewEmail(){
      this.checkTitle();
      this.checkEmails();
      let error = this.checkData();
      if(!error && this.titleChecked == 1 && this.emailsChecked.status == 1){
        if(this.$route.params.themeId == 1){
          this.$store.commit('changeMailingView', { themeId: this.$route.params.themeId, title: this.title, emails: this.emailsArray, data: { containers: this.theme1Containers, banner: this.theme1Banner } });
        }
        this.$store.commit('togglePopup', 5);
      }
    }
  }
}
</script>
<style scoped lang="scss">
  div.mailing-theme { padding: 10px; }
  div.flex { display: flex; flex-wrap: wrap; justify-content: space-between; }

  div.title, div.emails, div.containers, div.banner { width: 720px; }

  div.title div.input-container { margin: 10px; }
  div.title div.input-container div.input-box { width: 100%; }
  div.title div.input-container div.input-box input { width: 100%; }

  div.emails div.input-container { margin: 10px; }
  div.emails div.input-container div.input-box { width: 100%; height: 300px; }
  div.emails div.input-container div.input-box textarea { height: 300px; }
  div.emails button { margin: 10px; }

  div.containers div.container { margin: 10px; padding: 0 10px; background: $grayLighter4; border-radius: 3px; overflow: hidden; }
  div.containers div.container header { display: flex; align-items: center; justify-content: space-between; height: 40px; }
  div.containers div.container header h6 { margin: 0; padding: 0; font-size: 15px; font-weight: 400; }
  div.containers div.container header i.material-icons { width: 30px; height: 30px; text-align: center; line-height: 30px; color: $grayLighter;
    font-size: 22px; cursor: pointer; }
  div.containers div.container header i.material-icons:hover { color: $orangeLight; }

  div.containers div.container div.input-container { margin: 10px 0; }
  div.containers div.container div.input-container div.input-box { width: 100%; }
  div.containers div.container div.input-container div.input-box.textarea { height: 200px; }
  div.containers div.container div.input-container div.input-box.textarea textarea { height: 200px; }

  div.containers div.container div.input-container input { width: 100%; }

  div.containers div.container div.titles div.title { display: flex; align-items: center; justify-content: space-between; width: 100%; height: 40px; margin: 10px 0; border-radius: 3px; 
   cursor: pointer; }
  div.containers div.container div.titles div.title h5 { margin: 0 10px; padding: 0; color: #fff; font-weight: 400; font-size: 15px; }
  div.containers div.container div.titles div.title i.material-icons { display: block; width: 40px; height: 40px; line-height: 40px; color: #fff; text-align: center;
    font-size: 20px; }
  div.containers div.container div.titles p { margin: 3px 0 0 0; padding: 0 11px; color: $red; font-size: 11px; }

  div.file { display: flex; align-items: center; justify-content: space-between; }
  div.file h5 { margin: 0; padding: 0; font-weight: 400; font-size: 16px; color: $grayLight;}
  div.file h5 span { color: $blackLight; }
  div.file div { display: flex; flex-wrap: wrap; justify-content: flex-end; }

  div.banner div.img { display: flex; justify-content: center; align-items: center; height: 200px; }
  div.banner div.img h5 { margin: 0; padding: 0; font-size: 22px; color: $grayLighter; font-weight: 300; }
  div.banner div.img img { display: block; height: 200px; }
  div.banner div.flex-end { display: flex; justify-content: flex-end; }
  div.banner div.flex-end button { margin: 10px; }

  div.containers button { margin: 10px; }

  button.view { margin: 0 10px; }

</style>
