<template>
  <div class="popup" v-if="visible == 6">
    <div class="shadow" @click="close()"></div>
    <div class="container">
      <header>
        <h5>Usuwanie pliku</h5>
        <div class="close" @click="close()">
          <i class="material-icons">close</i>
        </div>
      </header>
      <div class="wrapper">
        <p>Czy na pewno chcesz usunąć<br><span>{{ mailingDeleteFile.name }}</span> ?</p>
        <div class="flex-between">
          <button class="default small reverse" @click="close()">Anuluj</button>
          <button class="default small" @click="deleteFile()">Usuń</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PopupMailingDeleteFile',
  data(){
    return{
      path: 0,
    }
  },
  computed:{
    visible(){
      return this.$store.state.popup;
    },
    api() {
      return this.$store.state.api;
    },
    mailingDeleteFile() {
      return this.$store.state.mailingDeleteFile;
    },
  },
  methods: {
    close(){
      this.$store.commit('togglePopup', 0);
      document.body.style = "overflow-y: overlay";
      this.path = 0;
    },
    deleteFile(){
      if(this.mailingDeleteFile.type == 1){
        this.path = 'mailing/attachment'
      }else if(this.mailingDeleteFile.type == 2){
        this.path = 'mailing/banner'
      }
      let self = this;
      this.axios
      .delete(this.api + 'employee/' + this.path, { data: { id: this.mailingDeleteFile.id }} )
      .then(response => {
        self.$store.commit('toggleAlert', {
          visibility: 1,
          type: response.data.status,
          msg: response.data.msg
        });
      if(response.data.status == 1){
          self.$store.commit('changeMailingReload', this.mailingDeleteFile.type);
          self.close();
        }
      });
    }
  },
}
</script>
<style scoped lang="scss">
  p { margin: 30px 0 40px 0; padding: 0; color: $blackLight; text-align: center; }
  p span { color: $orangeLight; }
  div.flex-between { display: flex; justify-content: space-between; margin: 10px 0; }
</style>
