<template>
  <div id="pagination" v-if="visibility">
    <div title="Pierwsza strona" @click="firstPage()" v-if="pages > 5"><i class="material-icons">first_page</i></div>
    <div title="Poprzednia strona" @click="previousPage()" v-if="pages > 5"><i class="material-icons">chevron_left</i></div>
    <div :class="{ active: pageActive == visiblePages[0].value }" @click="choosePage(0)">{{ visiblePages[0].value }}</div>
    <div :class="{ active: pageActive == visiblePages[1].value }" @click="choosePage(1)" v-if="pages > 1">{{ visiblePages[1].value }}</div>
    <div :class="{ active: pageActive == visiblePages[2].value }" @click="choosePage(2)" v-if="pages > 2">{{ visiblePages[2].value }}</div>
    <div :class="{ active: pageActive == visiblePages[3].value }" @click="choosePage(3)" v-if="pages > 3">{{ visiblePages[3].value }}</div>
    <div :class="{ active: pageActive == visiblePages[4].value }" @click="choosePage(4)" v-if="pages > 4">{{ visiblePages[4].value }}</div>
    <div title="Wybierz stronę" :class="{ 'no-active': chooseActive == 1 }" @click="choose()" v-if="pages > 5">
      <i class="material-icons" v-if="chooseActive == 0">more_horiz</i>
      <input type="text" ref="chooseNumber" :class="{ error: chooseError == 1 }" v-if="chooseActive == 1" v-model="chooseNumber">
    </div>
    <div title="Następna strona" @click="nextPage()" v-if="pages > 5"><i class="material-icons">chevron_right</i></div>
    <div title="Ostatnia strona" @click="lastPage()" v-if="pages > 5"><i class="material-icons">last_page</i></div>
  </div>
</template>

<script>
export default {
  name: 'Pagination',
  data(){
    return{
      visibility: 1,
      pageActive: 1,
      visiblePages: [{ value: 1 }, { value: 2 }, { value: 3 }, { value: 4 }, { value: 5 }],
      chooseActive: 0,
      chooseNumber: '',
      chooseError: 0,
    }
  },
  props:{
    source: String,
    pages: Number,
  },
   computed:{
    reloadedPageActions(){
      return this.$store.state.reloadedPageActions;
    },
    reloadedPageClients(){
      return this.$store.state.reloadedPageClients;
    },
    reloadedPageEvents(){
      return this.$store.state.reloadedPageEvents;
    },
  },
  created(){
    this.updateVisibility();
    this.adjustPagesNumber();
  },
  watch:{
    chooseNumber(){
      let regex = new RegExp('[^0-9]')
      if(parseInt(this.chooseNumber) > this.pages || parseInt(this.chooseNumber) < 1 || regex.exec(this.chooseNumber) || this.chooseNumber == ''){
        this.chooseError = 1;
      }else{
        this.chooseError = 0;
        this.pageActive = parseInt(this.chooseNumber);
        this.adjustPages(this.chooseNumber)
      }
    },
    pageActive(){
      this.updateStores();
    },
    reloadedPageActions(){
      this.updateVisibility();
      this.adjustPagesNumber();
    },
    reloadedPageClients(){
      this.updateVisibility();
      this.adjustPagesNumber();
    },
    reloadedPageEvents(){
      this.updateVisibility();
      this.adjustPagesNumber();
    },
  },
  methods:{
    updateVisibility(){
      if(this.pages <= 1){
        this.visibility = 0;
      }else{
        this.visibility = 1;
      }
    },
    updateStores(){
      switch(this.source){
        case 'actions':
          localStorage.pageActions = this.pageActive;
          this.$store.commit('changePageActions', this.pageActive);
        break;
        case 'clients':
          localStorage.pageClients = this.pageActive;
          this.$store.commit('changePageClients', this.pageActive);
        break;
        case 'events':
          localStorage.pageEvents = this.pageActive;
          this.$store.commit('changePageEvents', this.pageActive);
        break;
      }
    },
    adjustPagesNumber(){
      switch(this.source){
        case 'actions':
          if(typeof localStorage.pageActions === "undefined"){
            localStorage.setItem("pageActions", 1);
            this.$store.commit('changePageActions', 1);
          }else{
            if(localStorage.pageActions > this.pages){
              this.pageActive = 1;
              localStorage.setItem("pageActions", 1);
              this.$store.commit('changePageActions', 1);
            }else{
              this.pageActive = localStorage.pageActions;
              this.$store.commit('changePageActions', localStorage.pageActions);
              this.adjustPages(localStorage.pageActions)
            }
          }
        break;
        case 'clients':
          if(typeof localStorage.pageClients === "undefined"){
            localStorage.setItem("pageClients", 1);
            this.$store.commit('changePageClients', 1);
          }else{
            if(localStorage.pageClients > this.pages){
              this.pageActive = 1;
              localStorage.setItem("pageClients", 1);
              this.$store.commit('changePageClients', 1);
            }else{
              this.pageActive = localStorage.pageClients;
              this.$store.commit('changePageClients', localStorage.pageClients);
              this.adjustPages(localStorage.pageClients)
            }
          }
        break;
        case 'events':
          if(typeof localStorage.pageEvents === "undefined"){
            localStorage.setItem("pageEvents", 1);
            this.$store.commit('changePageEvents', 1);
          }else{
            if(localStorage.pageEvents > this.pages){
              this.pageActive = 1;
              localStorage.setItem("pageEvents", 1);
              this.$store.commit('changePageEvents', 1);
            }else{
              this.pageActive = localStorage.pageEvents;
              this.$store.commit('changePageEvents', localStorage.pageEvents);
              this.adjustPages(localStorage.pageEvents)
            }
          }
        break;
      }
    },
    adjustPages(page){
      if((parseInt(page) - 2) > 0 && (parseInt(page) + 2) <= this.pages){
          this.visiblePages[0].value = parseInt(page) - 2;
          this.visiblePages[1].value = parseInt(page) - 1;
          this.visiblePages[2].value = parseInt(page);
          this.visiblePages[3].value = parseInt(page) + 1;
          this.visiblePages[4].value = parseInt(page) + 2;
        }else if(page <= 5){
          this.visiblePages[0].value = 1;
          this.visiblePages[1].value = 2;
          this.visiblePages[2].value = 3;
          this.visiblePages[3].value = 4;
          this.visiblePages[4].value = 5;
        }else{
          this.visiblePages[0].value = this.pages - 4;
          this.visiblePages[1].value = this.pages - 3;
          this.visiblePages[2].value = this.pages - 2;
          this.visiblePages[3].value = this.pages - 1;
          this.visiblePages[4].value = this.pages;
        }
    },
    choose(){
      this.chooseActive = 1;
    },
    choosePage(index){
      this.pageActive = this.visiblePages[index].value;
    },
    firstPage(){
      this.pageActive = 1;
      this.visiblePages[0].value = 1;
      this.visiblePages[1].value = 2;
      this.visiblePages[2].value = 3;
      this.visiblePages[3].value = 4;
      this.visiblePages[4].value = 5;
    },
    previousPage(){
      if((this.pageActive - 1) != 0){
        if(this.pageActive == this.visiblePages[0].value){
          for(let i = 0; i < this.visiblePages.length; i++){
            this.visiblePages[i].value -= 1;
          }
        }
        this.pageActive -= 1;
      }
    },
    nextPage(){
      if(this.pageActive != this.pages){
        if(this.pageActive == this.visiblePages[4].value){
          for(let i = 0; i < this.visiblePages.length; i++){
            this.visiblePages[i].value += 1;
          }
        }
        this.pageActive += 1;
      }
    },
    lastPage(){
      this.pageActive = this.pages;
      this.visiblePages[0].value = this.pages - 4;
      this.visiblePages[1].value = this.pages - 3;
      this.visiblePages[2].value = this.pages - 2;
      this.visiblePages[3].value = this.pages - 1;
      this.visiblePages[4].value = this.pages;
    },
  }
}
</script>

<style scoped lang="scss">
  div#pagination { display: flex; flex-wrap: wrap; justify-content: center; margin: 50px 0; }
  div#pagination div { display: flex; justify-content: center; align-items: center; width: 35px; height: 35px; margin: 10px; border-radius: 50%; background: #fff;
    color: $grayLight; font-size: 13px; font-weight: 600; text-align: center; line-height: 35px; cursor: pointer; box-shadow: 0px 0px 10px $grayLighter2; }
  div#pagination div input { width: 35px; height: 35px; background: #fff; border-radius: 50%; border: 0; outline: 0; line-height: 35px; color: $orangeLight; 
    font-size: 13px; font-weight: 600; text-align: center; }
  div#pagination div input.error { color: $red; }
  div#pagination div i.material-icons { font-size: 20px; color: $orangeLight; }
  div#pagination div.active, div#pagination div:hover { background: $orangeLight; color: #fff; }
  div#pagination div.active i.material-icons, div#pagination div:hover i.material-icons { color: #fff; }
  div#pagination div.no-active:hover { background: $grayLight; }

  @media (max-width: 700px) {
    div#pagination { margin: 20px 0; }
  }
</style>
