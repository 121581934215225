<template>
  <header>
    <div class="menu">
      <i class="material-icons">menu</i>
    </div>
    <h1><router-link to="/dashboard">AFK Panel Klienta</router-link></h1>
    <nav>
      <ul>
        <li title="Dashboard" :class="{ active: activeRoute == 'Dashboard' }">
          <router-link to="/dashboard"><i class="material-icons">dashboard</i></router-link>
        </li>
        <li title="Wyloguj" @click="logoutRequest()">
          <i class="material-icons">exit_to_app</i>
        </li>
      </ul>
    </nav>
  </header>
</template>

<script>
export default {
  name: 'TheHeader',
  data(){
    return{
      activeRoute: '',
    }
  },
  computed:{
    api(){
      return this.$store.state.api;
    },
  },
  created(){
    this.activeRoute = this.$route.name;
  },
  watch: {
    $route(to, from) {
      this.activeRoute = this.$route.name;
    },
  },
  methods: {
    logoutRequest(){
      let self = this;
      this.axios
      .put(this.api + 'employee/auth/logout', {}, { headers: { Authorization: localStorage.accessToken } })
      .then(response => {
      }).then(function () {
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");
        self.$router.push('/')
      });
    },
  }
}
</script>
<style scoped lang="scss">
  header { display: flex; width: 100%; height: 60px; background: #fff; border-bottom: 1px solid #EEEEEE; box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.3); }
  header div.menu { display: none; justify-content: center; align-items: center; flex-shrink: 0; width: 50px; height: 50px; margin: 5px; border-radius: 3px; cursor: pointer; }
  header div.menu i.material-icons { font-size: 23px; color: $grayLight; }
  header h1 { margin: 0; padding: 0; text-indent: -9999px; }
  header h1 a { display: block; width: 100px; height: 60px; background: url('../assets/logo_30_normal.png') no-repeat 50% 50%; text-decoration: none; }
  header nav { margin-left: auto; }
  header nav ul { display: flex; align-items: center; height: 60px; margin: 0; padding: 0; list-style: none; }
  header nav ul li { display: flex; justify-content: center; align-items: center; width: 50px; height: 50px; margin: 0 10px; position: relative; border-radius: 3px; }
  header nav ul li a { display: flex; justify-content: center; align-items: center; width: 50px; height: 50px; text-decoration: none; }
  header nav ul li i.material-icons { font-size: 23px; color: $grayLight; }

  header nav ul li.active { background: $orangeLight; }
  header nav ul li.active i.material-icons { color: #fff; }

  header div.menu:hover i.material-icons { color: #fff; }
  header div.menu:hover { background: $orangeLight; cursor: pointer; }
  header nav ul li:hover { background: $orangeLight; cursor: pointer; }
  header nav ul li:hover i.material-icons { color: #fff; }

  @media (max-width: 1100px) {
    header div.menu { display: flex; }
  }

</style>
