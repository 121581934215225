<template>
  <div class="clients" :class="{ center: loader  == 1 || permission == 0 }">
    <h2 class="permission" v-if="!permission && loader == 0">Nie wystarczających uprawnień do przeglądania tej strony</h2>
    <loader v-if="loader == 1"></loader>
    <div v-show="loader == 0" v-if="permission">
    <div class="search-block">
      <div class="criteria">
        <div :class="{ active: searchType == 1 }" @click="changeSearchType(1)">
          <h5>ID</h5>
        </div>
         <div :class="{ active: searchType == 2 }" @click="changeSearchType(2)">
          <h5>Email</h5>
        </div>
        <div :class="{ active: searchType == 3 }" @click="changeSearchType(3)">
          <h5>Firma</h5>
        </div>
        <div :class="{ active: searchType == 4 }" @click="changeSearchType(4)">
          <h5>Data dołączenia</h5>
        </div>
      </div>
      <div class="input-search">
        <input class="search" type="text" :placeholder="getSearchTypeText()" v-model="searchPattern" @keyup="getClientsPages()">
        <div class="icon search">
          <i class="material-icons">search</i>
        </div>
        <div class="icon closing" @click="clearPattern()">
          <i class="material-icons">close</i>
        </div>
      </div>
      <div class="criteria">
        <div :class="{ active: searchFilter[0].value == 1 }" @click="changeSearchFilter(0)">
          <h5>Wszyscy</h5>
        </div>
        <div :class="{ active: searchFilter[1].value == 1 }" @click="changeSearchFilter(1)">
          <h5>Aktywni</h5>
        </div>
         <div :class="{ active: searchFilter[2].value == 1 }" @click="changeSearchFilter(2)">
          <h5>Nieaktywni</h5>
        </div>
        <div :class="{ active: searchFilter[3].value == 1 }" @click="changeSearchFilter(3)">
          <h5>Zbanowani</h5>
        </div>
      </div>
    </div>
    <div class="table">
      <header>
        <div>
          <h5>ID</h5>
          <h6>Data dołączenia</h6>
          <h6>Firmy</h6>
        </div>
        <h5>Email</h5>
        <div>
          <h5>Liczba firm</h5>
          <h6>Liczba aktywnych usług</h6>
        </div>
        <h5>Ostatnia akcja</h5>
        <div>
          <h5>Wartość netto</h5>
          <h6>Wartość brutto</h6>
        </div>
        <h5>Status</h5>
        <h5>Akcje</h5>
      </header>
      <div class="box row" v-for="client in clients" :key="client.id">
        <div class="info">
          <div>
            <h5>{{ client.id }}</h5>
            <h6>{{ formatDate(client.createdAt) }}</h6>
          </div>
          <h5 :title="client.email">{{ client.email }}</h5>
          <div>
            <h5>{{ client.companies.length }}</h5>
            <h6>{{ client.activeServices }}</h6>
          </div>
          <h5>{{ formatDate(client.last_action) }}</h5>
          <div>
            <h5>{{ client.valueNetto }}</h5>
            <h6>{{ client.valueBrutto }}</h6>
          </div>
          <div>
            <div class="label" :class="{ blue: client.status == 0, green: client.status == 1, orange: client.status == 2 }">
              <i class="material-icons">{{ getStatusIcon(client.status) }}</i>
              <p>{{ getStatusText(client.status) }}</p>
            </div>
          </div>
          <div class="actions">
            <div class="action">
              <router-link :to="{ name: 'Client', params: { clientId: client.id }}">
                <i class="material-icons">person</i>
              </router-link>
            </div>
            <div class="action" @click="changeStatus(client.id, client.status)">
                <i class="material-icons">edit</i>
            </div>
          </div>
        </div>
        <div class="companies">
          <h6 v-for="company in client.companies" :key="company.company_name">{{ company.company_name }}</h6>
        </div>
      </div>
    </div>
    <pagination source="clients" :pages="pagesClients"></pagination>
    </div>
    <popup-client-status></popup-client-status>
  </div>
</template>

<script>
import Loader from '@/components/Loader.vue'
import Pagination from '@/components/Pagination.vue'
import PopupClientStatus from '@/components/popups/ClientStatus.vue'

export default {
  name: 'Clients',
  components: {
    Loader,
    Pagination,
    PopupClientStatus
  },
  data(){
    return{
      loader: 1,
      searchType: 1,
      searchFilter: [],
      pagesClients: 0,
      clients: 0,
      searchPattern: '',
      permission: 1,
    }
  },
  computed: {
    api() {
      return this.$store.state.api;
    },
    loaderTime() {
      return this.$store.state.loaderTime;
    },
    pageClients() {
      return this.$store.state.pageClients;
    },
    reloadedClients() {
      return this.$store.state.reloadedClients;
    },
  },
  created(){
    if(typeof localStorage.clientsSearch == 'undefined'){
      localStorage.setItem('clientsSearch', JSON.stringify({ searchType: 1, searchFilter:  [{ value: 1 }, { value: 0 }, { value: 0 }, { value: 0 }] }));
      this.searchType = 1;
      this.searchFilter = [{ value: 1 }, { value: 0 }, { value: 0 }, { value: 0 }];
    }else{
      let clientsSearch = JSON.parse(localStorage.clientsSearch);
      this.searchType = clientsSearch.searchType;
      this.searchFilter = clientsSearch.searchFilter;
    }
    this.getClientsPages();
  },
  watch: {
    reloadedClients(){
      this.getClientsPages();
    },
    pageClients(){
      if(this.pageClients != -1){
        this.getClients();
      }
    },
    searchType(){
      this.getClientsPages();
    },
  },
  methods: {
    clearPattern(){
      this.searchPattern = '';
      this.getClientsPages();
    },
    changeStatus(id, status){
      this.$store.commit('changeClientEdit', { id: id, status: status });
      this.$store.commit('togglePopup', 1);
    },
    changeSearchType(type){
      this.searchType = type;
      localStorage.clientsSearch = JSON.stringify({ searchType: this.searchType, searchFilter:  this.searchFilter });
    },
    getSearchTypeText(){
      switch(this.searchType){
        case 1:
          return 'Szukaj po ID';
        break;
        case 2:
          return 'Szukaj po emailu';
        break;
        case 3:
          return 'Szukaj po firmie';
        break;
        case 4:
          return 'Szukaj po dacie dołączenia';
        break;
      }
    },
    changeSearchFilter(index){
      if(this.searchFilter[index].value){
        let on = 0;
        for(let i = 0; i < this.searchFilter.length; i++){
          if(this.searchFilter[i].value == 1){
            on += 1;
          }
        }
        if(on > 1){
          this.searchFilter[index].value = 0;
        }
      }else{
        this.searchFilter[index].value = 1;
      }
      if(index == 0 && this.searchFilter[index].value == 1){
        for(let i = 1; i < this.searchFilter.length; i++){
          this.searchFilter[i].value = 0;
        }
      }
      if(index != 0 && this.searchFilter[index].value == 1){
        this.searchFilter[0].value = 0;
      }
      localStorage.clientsSearch = JSON.stringify({ searchType: this.searchType, searchFilter:  this.searchFilter });
      this.getClientsPages();
    },
    formatDate(date){
      if(date == null){
        return 'Brak'        
      }
      let dateObj = new Date(date);
      let chosenDays = dateObj.getDate();
      if(chosenDays < 10){
        chosenDays = '0' + chosenDays;
      }
      let chosenMonths = dateObj.getMonth() + 1;
      if(chosenMonths < 10){
        chosenMonths = '0' + chosenMonths
      }
      let chosenHours = dateObj.getHours();
      if(chosenHours < 10){
        chosenHours = '0' + chosenHours;
      }
      let chosenMinutes = dateObj.getMinutes();
      if(chosenMinutes < 10){
        chosenMinutes = '0' + chosenMinutes;
      }
      return dateObj.getFullYear() + '-' + chosenMonths + '-' + chosenDays + ' ' + chosenHours + ':' + chosenMinutes;
    },
    getStatusText(status){
      switch(status){
        case 0:
          return 'niekatywny'
        break;
        case 1:
          return 'aktywny'
        break;
        case 2:
          return 'zbanowany'
        break;
      }
    },
    getStatusIcon(status){
    switch(status){
        case 0:
          return 'more_horiz'
        break;
        case 1:
          return 'done'
        break;
        case 2:
          return 'close'
        break;
      }
    },
    getClientsPages(){
      let self = this;
      this.axios
      .get(this.api + 'employee/clients', { params: { page: 0, searchType: this.searchType, searchFilter: { searchFilter: this.searchFilter }, searchPattern: this.searchPattern }, headers: { Authorization: localStorage.accessToken } })
      .then(response => {
        if(response.data.permission == 0){
          this.permission = 0;
        }
        self.$store.commit('changePageClients', -1);
        self.pagesClients = response.data.pages;
        self.$store.commit('reloadPageClients');
      });
    },
    getClients(){
      let self = this;
      this.axios
      .get(this.api + 'employee/clients', { params: { page: this.pageClients, searchType: this.searchType, searchFilter: { searchFilter: this.searchFilter }, searchPattern: this.searchPattern }, headers: { Authorization: localStorage.accessToken } })
      .then(response => {
        self.clients = response.data.data;
        setTimeout(function(){ self.loader = 0; }, self.loaderTime);
      });
    }
  }
}
</script>
<style scoped lang="scss">
  div.clients { padding: 10px; }
  div.clients.center { display: flex; justify-content: center; align-items: center; min-height: calc(100vh - 60px); }

  div.search-block { display: flex; flex-direction: column; align-items: flex-end; }

  div.input-search { width: 500px; }

  div.table header div:first-child { width: 130px; }
  div.table header h5:nth-child(2) { width: 200px; }
  div.table header h5:nth-child(3) { width: 120px; }
  div.table header h5:nth-child(4) { width: 150px; }
  div.table header div:nth-child(5) { width: 150px; }
  div.table header h5:nth-child(6) { width: 200px; }
  div.table header h5:last-child { width: 72px; }

  div.table div.box.row div.info > div:first-child { width: 130px; }
  div.table div.box.row div.info > h5:nth-child(2) { width: 200px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; }
  div.table div.box.row div.info > div:nth-child(3) { width: 120px; }
  div.table div.box.row div.info > h5:nth-child(4) { width: 150px; }
  div.table div.box.row div.info > div:nth-child(5) { width: 150px; }
  div.table div.box.row div.info > div:nth-child(6) { width: 200px; }


</style>
