<template>
  <div id="panel">
    <the-header></the-header>
    <div class="flex">
      <the-nav></the-nav>
      <div class="wrapper">
        <router-view />
      </div>
    </div>
    <alert></alert>
  </div>
</template>

<script>
import TheHeader from '@/components/TheHeader.vue'
import TheNav from '@/components/TheNav.vue'
import Alert from '@/components/Alert.vue'

export default {
  name: 'Panel',
  components: {
    TheHeader,
    TheNav,
    Alert,
  },
  computed:{
    api(){
      return this.$store.state.api;
    },
  },
  beforeRouteEnter (to, from, next) {
    if (((typeof(localStorage.accessToken) === "undefined" || typeof(localStorage.refreshToken) === "undefined")) || typeof(localStorage.accountStatus) !== "undefined" ) {
      next(false);
    }else{
      next();
    }
  },
  created(){
    this.getEmployeeSelf();
  },
  methods: {
    getEmployeeSelf(){
      let self = this;
      this.axios
      .get(this.api + 'employee/employee/self', { headers: { Authorization: localStorage.accessToken } })
      .then(response => {
        let rank = -1;
        let rankNames = [];
        for(let i = 0; i < response.data.data.EmployeeRanks.length; i++){
          rankNames.push(response.data.data.EmployeeRanks[i].name)
          if(response.data.data.EmployeeRanks[i].level > rank){
            rank = response.data.data.EmployeeRanks[i].level;
          }
        }
        self.$store.commit('changeEmployeeLevel', { level: rank, names: rankNames });
      });
    },
  }
}
</script>
<style scoped lang="scss">
  div#panel { background: $grayLighter5; }
  div.flex { display: flex; }
  div.wrapper { width: calc(100vw - 250px); height: calc(100vh - 60px); overflow-y: overlay; }
</style>
