<template>
  <div class="popup" v-if="visible == 1 || visible == 2">
    <div class="shadow" @click="close()"></div>
    <div class="container">
      <header>
        <h5>{{ getHeaderText() }}</h5>
        <div class="close" @click="close()">
          <i class="material-icons">close</i>
        </div>
      </header>
      <div class="wrapper">
        <button class="default choose" @click="clickUpload()">{{ getButtonText() }}</button>
        <input id="files" name="attachment" type="file" ref="files" :multiple="visible == 1" style="display: none" @change="handleFileUpload()">
        <ul v-if="files != 0">
          <li v-for="(file, index) in files" :key="index">
            <h6>{{ index + 1 }}</h6>
            <h5>{{ file.name }}</h5>
            <p>{{ getMb(file.size) }}mb</p>
          </li>
        </ul>
        <div class="flex-between">
          <button class="default small reverse" @click="close()">Anuluj</button>
          <button class="default small" @click="uploadFiles()">Wgraj</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PopupMailingUploadFile',
  data(){
    return{
      files: 0,
      formData: 0,
    }
  },
  computed:{
    visible(){
      return this.$store.state.popup;
    },
    api() {
      return this.$store.state.api;
    },
  },
  methods: {
    close(){
      this.$store.commit('togglePopup', 0);
      document.body.style = "overflow-y: overlay";
      this.files = 0;
      this.formData = 0;
    },
    getHeaderText(){
      if(this.visible == 1){
        return 'Dodaj załącznik'
      }else{
        return 'Dodaj banner'
      }
    },
    getButtonText(){
      if(this.visible == 1){
        return 'Wybierz plik/pliki'
      }else{
        return 'Wybierz plik'
      }
    },
    getMb(size){
      return (size / 1000000).toFixed(2);
    },
    clickUpload(){
      document.getElementById('files').click()
    },
    handleFileUpload(){
      this.files = this.$refs.files.files;
    },
    uploadFiles(){
      if(this.visible == 1 && this.files.length > 10){
        this.$store.commit('toggleAlert', {
          visibility: 1,
          type: 0,
          msg: 'Maksymalna ilość plików to 10'
        });
      }else if(this.visible == 2 && this.files.length > 1){
        this.$store.commit('toggleAlert', {
          visibility: 1,
          type: 0,
          msg: 'Maksymalna ilość plików to 1'
        });
      }else if(this.files == 0){
        this.$store.commit('toggleAlert', {
          visibility: 1,
          type: 0,
          msg: 'Nie wybrano żadnego pliku'
        });
      }else{
        if(this.visible == 1){
          this.formData = new FormData();
          for(let i = 0; i < this.files.length; i++){
            this.formData.append("attachment", this.files[i]);
          }
          let self = this;
          this.axios
          .post(this.api + 'employee/upload/attachment', this.formData, { headers: { Authorization: localStorage.accessToken, 'Content-Type': `multipart/form-data; boundary=${this.formData._boundary}` } })
          .then(response => {
            self.$store.commit('toggleAlert', {
              visibility: 1,
              type: response.data.status,
              msg: response.data.msg
            });
            if(response.data.status == 1){
              self.$store.commit('changeMailingReload', 1);
              self.close();
            }
          });
        }else if(this.visible == 2){
           this.formData = new FormData();
          for(let i = 0; i < this.files.length; i++){
            this.formData.append("banner", this.files[i]);
          }
          let self = this;
          this.axios
          .post(this.api + 'employee/upload/banner', this.formData, { headers: { Authorization: localStorage.accessToken, 'Content-Type': `multipart/form-data; boundary=${this.formData._boundary}` } })
          .then(response => {
            self.$store.commit('toggleAlert', {
              visibility: 1,
              type: response.data.status,
              msg: response.data.msg
            });
            if(response.data.status == 1){
              self.$store.commit('changeMailingReload', 2);
              self.close();
            }
          });
        }
      }
    }
  },
}
</script>
<style scoped lang="scss">
  div.flex-between { display: flex; justify-content: space-between; margin: 10px 0; }
  button.choose { display: block; margin: 40px auto; }
  ul { margin: 40px 0; padding: 0; list-style: none; }
  ul li { display: flex; margin: 5px; background: $grayLighter4; }
  ul li h6 { width: 20px; margin: 0; padding: 0; font-size: 14px; font-weight: 400; color: $blackLight; text-align: center; }
  ul li h5 { margin: 0 10px 0 0; padding: 0; font-size: 14px; font-weight: 400; color: $blackLight; }
  ul li p { margin: 0; padding: 0; font-size: 14px; font-weight: 400; color: $blackLight; }
</style>
