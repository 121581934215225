<template>
  <div class="employee" :class="{ center: loader  == 1 }">
    <loader v-if="loader == 1"></loader>
    <div v-show="loader == 0">
      <header>
        <div class="box">
          <h3>{{ employee.name }} {{ employee.surname }}</h3>
        </div>
        <div>
          <div class="criteria">
            <div :class="{ active: searchType == 1 }" @click="changeSearchType(1)">
              <h5>Akcja</h5>
            </div>
            <div :class="{ active: searchType == 2 }" @click="changeSearchType(2)">
              <h5>Typ</h5>
            </div>
            <div :class="{ active: searchType == 3 }" @click="changeSearchType(3)">
              <h5>Cel</h5>
            </div>
            <div :class="{ active: searchType == 4 }" @click="changeSearchType(4)">
              <h5>Data</h5>
            </div>
          </div>
          <div class="input-search">
            <input class="search" type="text" :placeholder="getSearchTypeText()" v-model="searchPattern" @keyup="getActionsPages()">
            <div class="icon search">
              <i class="material-icons">search</i>
            </div>
            <div class="icon closing" @click="clearPattern()">
              <i class="material-icons">close</i>
            </div>
          </div>
        </div>
      </header>
      <div class="table">
        <header>
          <h5>Akcja</h5>
          <h5>Typ</h5>
          <h5>Cel</h5>
          <h5>Data</h5>
        </header>
        <div class="box row" v-for="action in actions" :key="action.id">
          <div class="info">
            <h5 :title="action.action">{{ action.action }}</h5>
            <h5>{{ action.EmployeeActionType.name }}</h5>
            <h5 :title="action.target">{{ action.target }}</h5>
            <h5>{{ formatDate(action.createdAt) }}</h5>
          </div>
        </div>
      </div>
      <pagination source="actions" :pages="pagesActions"></pagination>
    </div>
  </div>
</template>

<script>
import Loader from '@/components/Loader.vue'
import Pagination from '@/components/Pagination.vue'

export default {
  name: 'Employees',
  components: {
    Loader,
    Pagination
  },
  data(){
    return{
      loader: 1,
      searchType: 1,
      pagesActions: 0,
      actions: 0,
      employee: 0,
      searchPattern: '',
    }
  },
  computed: {
    api() {
      return this.$store.state.api;
    },
    loaderTime() {
      return this.$store.state.loaderTime;
    },
    pageActions() {
      return this.$store.state.pageActions;
    },
    reloadedActions() {
      return this.$store.state.reloadedActions;
    },
  },
  created(){
    if(typeof localStorage.employeeSearch == 'undefined'){
      localStorage.setItem('employeeSearch', JSON.stringify({ searchType: 1 }));
      this.searchType = 1;
    }else{
      let employeeSearch = JSON.parse(localStorage.employeeSearch);
      this.searchType = employeeSearch.searchType;
    }
    this.getActionsPages();
  },
  watch: {
    reloadedActions(){
      this.getActionsPages();
    },
    pageActions(){
      if(this.pageActions != -1){
        this.getActions();
      }
    },
    searchType(){
      this.getActionsPages();
    },
  },
  methods: {
    clearPattern(){
      this.searchPattern = '';
      this.getActionsPages();
    },
    changeSearchType(type){
      this.searchType = type;
      localStorage.employeeSearch = JSON.stringify({ searchType: this.searchType });
    },
    getSearchTypeText(){
      switch(this.searchType){
        case 1:
          return 'Szukaj po akcji';
        break;
        case 2:
          return 'Szukaj po typie';
        break;
        case 3:
          return 'Szukaj po celu';
        break;
        case 4:
          return 'Szukaj po dacie';
        break;
      }
    },
    formatDate(date){
      let dateObj = new Date(date);
      let chosenDays = dateObj.getDate();
      if(chosenDays < 10){
        chosenDays = '0' + chosenDays;
      }
      let chosenMonths = dateObj.getMonth() + 1;
      if(chosenMonths < 10){
        chosenMonths = '0' + chosenMonths
      }
      let chosenHours = dateObj.getHours();
      if(chosenHours < 10){
        chosenHours = '0' + chosenHours;
      }
      let chosenMinutes = dateObj.getMinutes();
      if(chosenMinutes < 10){
        chosenMinutes = '0' + chosenMinutes;
      }
      return dateObj.getFullYear() + '-' + chosenMonths + '-' + chosenDays + ' ' + chosenHours + ':' + chosenMinutes;
    },
    getActionsPages(){
      let self = this;
      this.axios
      .get(this.api + 'employee/actions', { params: { page: 0, employee: this.$route.params.employeeId, searchType: this.searchType, searchPattern: this.searchPattern }, headers: { Authorization: localStorage.accessToken } })
      .then(response => {
        if(response.data.status == 0){
          self.$router.push('/pracownicy')
        }
        self.$store.commit('changePageActions', -1);
        self.pagesActions = response.data.pages;
        self.$store.commit('reloadPageActions');
      });
    },
    getActions(){
      let self = this;
      this.axios
      .get(this.api + 'employee/actions', { params: { page: this.pageActions, employee: this.$route.params.employeeId, searchType: this.searchType, searchPattern: this.searchPattern }, headers: { Authorization: localStorage.accessToken } })
      .then(response => {
        self.actions = response.data.data.actions;
        self.employee = response.data.data.employee;
        setTimeout(function(){ self.loader = 0; }, self.loaderTime);
      });
    }
  }
}
</script>
<style scoped lang="scss">
  div.employee { padding: 10px; }
  div.employee.center { display: flex; justify-content: center; align-items: center; min-height: calc(100vh - 60px); }

  header { display: flex; flex-wrap: wrap; justify-content: space-between; }
  header div.box { width: 400px; height: 50px; background: $greenGradient;}
  header div.box h3 { margin: 0; padding: 0 15px; font-weight: 400; font-size: 18px; line-height: 50px; color: #fff; text-align: center; }

  div.input-search { width: 400px; }

  div.table header h5:first-child { width: 600px; }
  div.table header h5:nth-child(2) { width: 150px; }
  div.table header h5:nth-child(3) { width: 300px; }
  div.table header h5:last-child { width: 120px; }

  div.table div.box.row div.info > h5:first-child { width: 600px; }
  div.table div.box.row div.info > h5:nth-child(2) { width: 150px; text-transform: capitalize; }
  div.table div.box.row div.info > h5:nth-child(3) { width: 300px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; }
  div.table div.box.row div.info > h5:last-child { width: 120px; }

</style>
