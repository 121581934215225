import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    api: process.env.VUE_APP_API,
    loaderTime: 350,
    alert:{
      visibility: 0,
      type: 0,
      msg: ''
    },
    employeeLevel: -1,
    popup: 0,
    reloadedPageActions: 0,
    pageActions: -1,
    reloadedPageClients: 0,
    reloadedClients: 0,
    pageClients: -1,
    reloadedPageEvents: 0,
    reloadedEvents: 0,
    pageEvents: -1,
    reloadedEmployees: 0,
    employeeEditId: 0,
    clientEdit: 0,
    eventEdit: 0,
    orderId: 0,
    eventId: 0,
    mailingAttachment: 0,
    mailingBanner: 0,
    mailingView: 0,
    mailingDeleteFile: 0,
    mailingReload: 0,
  },
  mutations: {
    toggleAlert(state, payload){
      state.alert.visibility = payload.visibility;
      state.alert.type = payload.type;
      state.alert.msg = payload.msg;
    },
    changeEmployeeLevel(state, payload){
      state.employeeLevel = payload;
    },
    togglePopup(state, payload){
      state.popup = payload;
    },
    changePageActions(state, payload){
      state.pageActions = payload;
    },
    reloadPageActions(state){
      if(state.reloadedPageActions == 1){
        state.reloadedPageActions = 0;
      }else{
        state.reloadedPageActions = 1;
      }
    },
    changePageClients(state, payload){
      state.pageClients = payload;
    },
    reloadPageClients(state){
      if(state.reloadedPageClients == 1){
        state.reloadedPageClients = 0;
      }else{
        state.reloadedPageClients = 1;
      }
    },
    reloadClients(state){
      if(state.reloadedClients == 1){
        state.reloadedClients = 0;
      }else{
        state.reloadedClients = 1;
      }
    },
    changePageEvents(state, payload){
      state.pageEvents = payload;
    },
    reloadPageEvents(state){
      if(state.reloadedPageEvents == 1){
        state.reloadedPageEvents = 0;
      }else{
        state.reloadedPageEvents = 1;
      }
    },
    reloadEvents(state){
      if(state.reloadedEvents == 1){
        state.reloadedEvents = 0;
      }else{
        state.reloadedEvents = 1;
      }
    },
    changeEmployeeEditId(state, payload){
      state.employeeEditId = payload;
    },
    reloadEmployees(state){
      if(state.reloadedEmployees == 1){
        state.reloadedEmployees = 0;
      }else{
        state.reloadedEmployees = 1;
      }
    },
    changeClientEdit(state, payload){
      state.clientEdit = payload;
    },
    changeEventEdit(state, payload){
      state.eventEdit = payload;
    },
    changeOrderId(state, payload){
      state.orderId = payload;
    },
    changeEventId(state, payload){
      state.eventId = payload;
    },
    changeMailingAttachment(state, payload){
      state.mailingAttachment = payload;
    },
    changeMailingBanner(state, payload){
      state.mailingBanner = payload;
    },
    changeMailingView(state, payload){
      state.mailingView = payload;
    },
    changeMailingDeleteFile(state, payload){
      state.mailingDeleteFile = payload;
    },
    changeMailingReload(state, payload){
      state.mailingReload = payload;
    },
  },
  actions: {
  },
  modules: {
  }
})
