<template>
  <div class="popup" v-if="visible == 2">
    <div class="shadow" @click="close()"></div>
    <div class="container">
      <header>
        <h5>Faktura</h5>
        <div class="close" @click="close()">
          <i class="material-icons">close</i>
        </div>
      </header>
      <div class="wrapper">
        <button class="default" @click="downloadPdf()" :disabled="downloading.status">{{ downloading.title }}</button>
        <div class="invoiceBox" v-if="invoice != 0">
            <div class="topPanel">
                <div class="logo">
                    <img src="../../assets/logo_invoice.jpg" style="width:60%;">
                </div>
                <div class="companyInfo">
                    <ul>
                        <li><span style="font-weight: bold;">AFK Centrum Obsługi Biznesu Sp. z o.o.</span></li>
                        <li>ul. Świeradowska 51 - 57 50-559 Wrocław</li>
                        <li>NIP: 8992664769, REGON: 020936750</li>
                        <li>tel.: +48 71 336 95 21, e-mail: bok@afkcob.pl</li>
                        <li>www.afkcob.pl | fb.me/cob.AFK</li>
                    </ul>
                </div>
            </div>
            <div class="invoiceInfo">
                <div class="invoiceNr">
                    <h1>Faktura {{ invoice.invoiceNumber }}</h1><br>
                </div>
                <div class="invoiceDate">
                    <div>
                        Data wystawienia:<br>
                        Data wykonania usługi: 
                    </div>
                    <div>
                        <b>{{ invoice.today }}</b><br>
                        <b>{{ invoice.dateOfService }}</b>
                    </div>
                </div>
            </div>
            <div class="participants">
                <div class="seller">
                    <h1>Sprzedawca</h1><br>
                    <b>AFK Centrum Obsługi Biznesu Sp. z o.o.</b><br>
                    ul. Świeradowska 51-57<br>
                    50-559 Wrocław<br>
                    NIP: 8992664769<br>
                    <h2>Konto bankowe</h2><br>
                    PKO BP SA o/Wrocław<br>
                    91 1020 5242 0000 2302 0254 6190
                </div>
                <div class="buyer">
                    <h1>Nabywca</h1><br>
                    <b>{{ invoice.buyer.name }}</b><br>
                    {{ invoice.buyer.address1 }}<br>
                    {{ invoice.buyer.address2 }}<br>
                    NIP: {{ invoice.buyer.nip }}<br>
                </div>
            </div>
            <div class="services">
                <table>
                    <tr>
                        <th style="width: 3%;"><b>Lp.</b></th>
                        <th style="width: 40%;"><b>Nazwa towaru/usługi</b></th>
                        <th style="width: 12%;"><b>Ilość    j.m.</b></th>
                        <th style="width: 12%;"><b>Cena netto</b></th>
                        <th style="width: 12%;"><b>Cena brutto</b></th>
                        <th style="width: 12%;"><b>Wartość netto</b></th>
                        <th><b>Stawka VAT</b></th>
                    </tr>
                    <tr v-for="(item, index) in invoiceArray" :key="index">
                        <td>{{ index + 1 }}</td>
                        <td style="text-align: left;" v-if="item.addon == 0">{{ item.data.name }} pakiet {{ item.data.packetName }} {{ item.data.period }}m</td>
                        <td style="text-align: left;" v-if="item.addon == 1">{{ item.data.title }}</td>
                        <td>{{ item.data.quantity }}szt</td>
                        <td>{{ item.data.priceNetto.toFixed(2) }} PLN</td>
                        <td>{{ item.data.priceBrutto.toFixed(2) }} PLN</td>
                        <td><b>{{ item.data.valueNetto.toFixed(2) }} PLN</b></td>
                        <td>{{ item.data.vat }}</td>
                    </tr>
                </table>
                <table style="width: 50%; float: right;">
                    <tr>
                        <td><b>Stawka VAT</b></td>
                        <td><b>Netto</b></td>
                        <td><b>Kwota VAT</b></td>
                        <td><b>Brutto</b></td>
                    </tr>
                    <tr>
                        <td>23%</td>
                        <td>{{ invoice.totalSum.totalNetto.toFixed(2) }} PLN</td>
                        <td>{{ invoice.totalSum.totalVat.toFixed(2) }}</td>
                        <td>{{ invoice.totalSum.totalBrutto.toFixed(2) }} PLN</td>
                    </tr>
                    <tr>
                        <td><b>Suma</b></td>
                        <td><b>{{ invoice.totalSum.totalNetto.toFixed(2) }} PLN</b></td>
                        <td><b>{{ invoice.totalSum.totalVat.toFixed(2) }}</b></td>
                        <td><b>{{ invoice.totalSum.totalBrutto.toFixed(2) }} PLN</b></td>
                    </tr>
                </table>
            </div>
            <div class="payment">
                <div style="display: flex; justify-content: space-between; border-bottom: 2px solid;">
                    <h1>Zapłacono:</h1>
                    <h1><b>{{ Math.floor(invoice.totalSum.totalBrutto) }} zł {{ getGR((Math.round((invoice.totalSum.totalBrutto - Math.floor(invoice.totalSum.totalBrutto)) * 100))) }} gr</b></h1>
                </div>
                <div style="font-style: italic; display: flex; justify-content: space-between;">
                    <span>Słownie:</span>
                    <span>{{ invoice.totalInWords }}</span>
                </div>
            </div>
            <div class="footer">
                <div>
                    <p class="invoice-person">Patrycja Kowalczyk</p> <br>
                    <p>Osoba upoważniona do wystawienia faktury</p>
                </div>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PopupInvoice',
  data(){
    return{
      invoice: 0,
      invoiceArray: [],
        downloadingTitles: ['Pobierz PDF', 'Pobieranie...'],
        downloading: {
            title: 'Pobierz PDF',
            status: false
        },
    }
  },
  computed:{
    visible(){
      return this.$store.state.popup;
    },
    api() {
      return this.$store.state.api;
    },
    orderId() {
      return this.$store.state.orderId;
    },
  },
  watch: {
    visible() {
        if(this.orderId != 0){
            this.getInvoiceData();
        }
    }
  },
  methods: {
    close(){
        this.invoice = 0;
        this.$store.commit('changeOrderId', 0);
      this.$store.commit('togglePopup', 0);
      document.body.style = "overflow-y: overlay";
    },
    getGR(value){
        if(value < 10){
            return '0' + value;
        }else{
            return value;
        }
    },
    getInvoiceData(){
      let self = this;
      this.axios
      .get(this.api + 'employee/invoice/data', { params: { orderId: this.orderId }, headers: { Authorization: localStorage.accessToken } })
      .then(response => {
        self.invoiceArray = [];
        self.invoice = response.data.data;
        for(let i = 0; i < self.invoice.services.length; i++){
            self.invoiceArray.push({ addon: 0, data: self.invoice.services[i]});
            if(self.invoice.services[i].serviceAddon){
                self.invoice.services[i].serviceAddon.forEach(addon => {
                    self.invoiceArray.push({ addon: 1, data: addon});
                });
            };
        }
      });
    },
    downloadPdf(){
        this.downloading.status = true;
        this.downloading.title = this.downloadingTitles[1];
        let self = this;
        this.axios
        .get(this.api + 'employee/invoice/download', { params: { orderId: this.orderId }, headers: { Authorization: localStorage.accessToken }, responseType: 'blob' })
        .then(response => {
            if(response.data.status != 0){
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'Faktura ' + this.invoice.invoiceNumber + '.pdf');
                document.body.appendChild(link);
                link.click();
                this.downloading.status = false;
                this.downloading.title = this.downloadingTitles[0];
            }
        });
    }
  },
}
</script>
<style scoped lang="scss">
  div.flex { display: flex; flex-wrap: wrap; }
  div.popup div.container { width: 1000px; }

  div.wrapper { margin: 20px 0; }
  button { margin: 0 0 20px 0; }

  .invoiceBox {
            padding: 30px;

            border: 1px solid #eee;
            box-shadow: 0 0 10px rgba(0, 0, 0, .15);
        }

    .topPanel {
            display: flex;
            display: -webkit-flex;
            justify-content: space-between;
            align-items: center;
            font-size: 13px;
            margin-bottom: 20px;
            color: #555;
        }

    .topPanel ul {
        margin: 0;
        list-style: none;
    }

    .topPanel li {
        width: 100%;
        text-align: justify;
        text-align-last: justify;
    }

    .invoiceInfo {
        display: flex;
        display: -webkit-flex;
        justify-content: space-between;
        align-items: center;
        border-top: 1px solid;
        border-bottom: 1px solid;
    }

    .invoiceNr {
        display: flex;
        align-items: center;
        
    }

    .invoiceNr h1 {
        font-size: 20px;
    }

    .invoiceDate {
        width: 30%;
        display: flex;
        display: -webkit-flex;
        justify-content: space-between;
        font-size: 10px;
    }

    .participants {
        display: flex;
        display: -webkit-flex;
        justify-content: space-between;
        font-size: 10px;
        margin: 10px 0;
    }

    .seller h1, h2 {
        margin: 0;
        padding: 0;
        text-decoration: underline;
        font-style: italic;
    }

    .seller h2 {
        margin-top: 5px;
    }

    .buyer {
        width: 50%;
    }

    .buyer h1 {
        margin: 0;
        padding: 0;
        text-decoration: underline;
        font-style: italic;
    }

    .services {
        font-size: 10px;
    }

    .services table {
        width: 100%;
        border-collapse: collapse;
        text-align: center;
        margin-bottom: 5px;
    }
    .services  table, tr, td, th {
        border: 2px solid #555;
    }

    .services td {
        text-align: right;
    }

    .payment {
        font-size: 10px;
        margin-top: 120px;
    }

    .payment h1 {
        margin: 0;
    }

    .paymentLeft table {
        width: 50%;
        border-collapse: collapse;
        text-align: center;
        border: 2px solid #555;
    }

    .footer {
        font-size: 10px;
        margin-top: 70px;
        // width: 100%;
        display: flex;
        display: -webkit-flex;
        justify-content: flex-end;
        margin-right: 70px;
        text-align: center;
    }

    .footer p {
        border-top: 1px dotted;
        margin: 0;
    }

    .footer p.invoice-person {
        font-size: 13px;
        border-top: none;
        margin: 0;
    }

  @media (max-width: 1000px) {
    div.popup div.container { width: calc(100vw - 20px); }
}
</style>
