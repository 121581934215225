import Vue from 'vue'
import VueRouter from 'vue-router'
import Error404 from '../views/Error404.vue'
import AuthorizationPage from '../views/AuthorizationPage.vue'
import Panel from '../views/Panel.vue'

import Dashboard from '../views/Dashboard.vue'

import Employees from '../views/employees/Employees.vue'
import Employee from '../views/employees/Employee.vue'

import Clients from '../views/clients/Clients.vue'
import Client from '../views/clients/Client.vue'

import Mailing from '../views/mailing/Mailing.vue'
import MailingTheme from '../views/mailing/MailingTheme.vue'

import VirtualOffice from '../views/VirtualOffice.vue'


Vue.use(VueRouter)

const routes = [
  { path: '*', 
    redirect: '/404' 
  },  
  {
    path: '/404',
    name: 'Error404',
    component: Error404
  },
  {
    path: '/',
    name: 'AuthorizationPage',
    component: AuthorizationPage
  },
  {
    path: '/panel',
    name: 'Panel',
    component: Panel,
    children: [
      { 
        path: '/dashboard', 
        name: 'Dashboard', 
        component: Dashboard 
      },
      { 
        path: '/klienci', 
        name: 'Clients', 
        component: Clients 
      },
      { 
        path: '/klienci/:clientId', 
        name: 'Client', 
        component: Client
      },
      { 
        path: '/mailing', 
        name: 'Mailing', 
        component: Mailing 
      },
      { 
        path: '/mailing/:themeId', 
        name: 'MailingTheme', 
        component: MailingTheme 
      },
      { 
        path: '/wirtualne-biuro', 
        name: 'VirtualOffice', 
        component: VirtualOffice
      },
      { 
        path: '/pracownicy', 
        name: 'Employees', 
        component: Employees 
      },
      { 
        path: '/pracownicy/:employeeId', 
        name: 'Employee', 
        component: Employee 
      },
      
    ]
  },
]

const router = new VueRouter({
  mode: process.env.VUE_APP_ROUTER_MODE,
  routes
})

export default router
