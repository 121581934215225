<template>
  <div id="authorization">
    <hr>
    <hr>
    <div class="img">
      <h1><span>AFK</span> Panel Pracownika</h1>
    </div>
    <div class="form">
      <div class="logo"></div>
      <div class="icon">
        <div class="icon-circle">
          <i class="material-icons">person</i>
        </div>
      </div>
      <div class="login">
        <div class="input-container">
          <input type="text" placeholder="Email" v-model="email" @keyup.enter="login()">
          <div class="icon-wrapper">
            <div class="icon-small">
              <i class="material-icons">email</i>
            </div>
          </div>
        </div>
        <div class="input-container">
          <input type="password" placeholder="Hasło" v-model="password" @keyup.enter="login()">
          <div class="icon-wrapper">
            <div class="icon-small">
              <i class="material-icons">lock</i>
            </div>
          </div>
        </div>
        <button class="orange" @click="login()">Zaloguj</button>
      </div>
      <div class="msg" :class="{ green: msgType == 1, red: msgType == 0 }" v-if="msg">
        <p>{{ msgText }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Authorization',
  data(){
    return{
      msg: 0,
      msgType: 0,
      msgText: '',
      email: '',
      password: '',
    }
  },
  computed:{
    api(){
      return this.$store.state.api;
    },
  },
  methods: {
    login(){
      this.msg = 0;
      if(this.email == ''){
        this.msg = 1;
        this.msgType = 0;
        this.msgText = "Pole Email jest puste.";
      }else if(this.password == ''){
        this.msg = 1;
        this.msgType = 0;
        this.msgText = "Pole Hasło jest puste.";
      }else{
        this.loginRequest();
      }
    },
    loginRequest(){
      let self = this;
      this.axios
      .get(this.api + 'employee/auth/login', { params: { email: this.email, password: this.password } })
      .then(response => {
        if(response.data.status == 1){
          if(typeof(localStorage.accessToken) !== "undefined") {
            localStorage.setItem("accessToken", response.data.accessToken);
          }else{
            localStorage.accessToken = response.data.accessToken;
          }
          if(typeof(localStorage.accessToken) !== "undefined") {
            localStorage.setItem("refreshToken", response.data.refreshToken);
          }else{
            localStorage.refreshToken = response.data.refreshToken;
          }
          localStorage.removeItem("accountStatus");
          self.$router.push('/dashboard');
        }else{
          self.msg = 1;
          self.msgType = 0;
          self.msgText = response.data.msg;
        }
      });
    },
  }
}
</script>
<style scoped lang="scss">
  div#authorization { display: flex; width: 800px; height: 460px; margin: calc(50vh - 230px) auto 0 auto; position: relative; background: $grayLighter2; border-radius: 25px; 
    box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.8); }

  div#authorization hr { display: block; width: 100%; height: 1px; position: absolute; top: 50px; outline: 0; border: 0; background: #fff; }
  div#authorization hr:nth-child(2) { top: initial; bottom: 50px; }

  div#authorization div.img { width: 422px; height: 460px; border-top-left-radius: 25px; border-bottom-left-radius: 25px; 
    background: url('../assets/authorization.webp') no-repeat 50% 50%; }
  div#authorization div.img h1 { height: 58px; margin: 0; padding: 0; font-size: 24px; color: $orangeLight; font-weight: 300; line-height: 58px;
     background: url('../assets/logo_27_normal.png') no-repeat 25px 50%; font-family: 'Aspergit Bold', sans-serif; letter-spacing: 1px; text-align: center; }
  div#authorization div.img h1 span { display: none;}

  div#authorization div.form { display: flex; flex-direction: column; width: 378px; position: relative; }
  div#authorization div.form div.logo { display: none; width: 57px; height: 27px; position: absolute; left: 25px; top: 15.5px; background: url('../assets/logo_27_white.png') no-repeat 50% 50%; }
  div#authorization div.form div.icon { display: flex; justify-content: center; align-items: center; width: 110px; height: 110px; margin: 5px auto 0 auto; 
    background: $grayLighter2;}
  div#authorization div.form div.icon div.icon-circle { display: flex; justify-content: center; align-items: center; width: 80px; height: 80px;
    border-radius: 50%; border: 2px solid #fff;  }
  div#authorization div.form div.icon div.icon-circle i.material-icons { color: #fff; font-size: 35px; }

  div#authorization div.form div.login { margin: 30px 0; }

  div#authorization div.form div.input-container { display: flex; justify-content: space-between; align-items: center; width: 250px; height: 35px; margin: 20px auto; position: relative;
     background: #fff; border-radius: 3px; }
  div#authorization div.form div.input-container div.icon-wrapper { display: flex; align-items: center; width: 45px; height: 35px; position: absolute;
    background: #fff; border-radius: 3px; }
  div#authorization div.form div.input-container div.icon-small { display: flex; justify-content: center; align-items: center; width: 25px; height: 25px; 
   margin-left: 5px; opacity: 1; }
  div#authorization div.form div.input-container div.icon-small i.material-icons { color: #fff; font-size: 16px; color: $grayLighter2; }
  div#authorization div.form div.input-container input { width: 250px; padding: 0 15px 0 15px; border: 0; outline: 0; text-align: center; border-radius: 3px; 
    color: $blackDark; font-size: 15px; }
  div#authorization div.form div.input-container input:focus { color: $orangeLight; }
  div#authorization div.form div.input-container input:focus + div.icon-wrapper div.icon-small i.material-icons { color: $orangeLight; }

  div#authorization div.form h6 { margin: 0 auto; padding: 0; width: 250px; font-size: 14px; font-weight: 400; color: $blackLight; text-align: center; }

  div#authorization div.form div.msg { display: flex; justify-content: center; align-items: center; width: 378px; height: 58px; margin: auto auto 0 auto; }
  div#authorization div.form div.msg p { width: 250px; margin: 0; padding: 5px; font-size: 12px; color: #fff; text-align: center; border-radius: 3px; }
  div#authorization div.form div.msg.red p { background: $red; }
  div#authorization div.form div.msg.green p { background: $greenLight; }


  div#authorization div.form button { display: block; width: 250px; margin: 20px auto; height: 30px; border: 0; outline: 0; background: $blackLight; color: #fff; 
    border-radius: 3px; font-size: 14px; cursor: pointer; }
  div#authorization div.form button.orange { background: $orangeDark; }

  @media (max-width: 800px) {
    div#authorization { width: 378px; }
    div#authorization div.img { display: none; }
    div#authorization div.form div.logo { display: block; }
  }

  @media (max-width: 378px) {
    div#authorization { width: calc(100vw - 40px); }
    div#authorization div.form { width: 100%; }
    div#authorization div.form div.msg{ width: 100%; }
  }

  @media (max-width: 290px) {
    div#authorization div.form div.input-container { width: calc(100% - 40px); }
    div#authorization div.form div.input-container input { width: calc(100% - 40px); }
    div#authorization div.form button { width: calc(100% - 40px); }
    div#authorization div.form div.msg { width: calc(100% - 40px); }
  }
</style>
