<template>
  <div class="popup" v-if="visible == 1 || visible == 2">
    <div class="shadow" @click="close()"></div>
    <div class="container">
      <header>
        <h5>{{ getHeaderText() }}</h5>
        <div class="close" @click="close()">
          <i class="material-icons">close</i>
        </div>
      </header>
      <div class="wrapper">
        <div class="input-container" title="Imię">
          <header>
            <h6>Imię</h6>
          </header>
          <div class="input-box">
            <input type="text" class="data" placeholder="Imię" v-model="firstName">
          </div>
        </div>
        <div class="input-container" title="Imię">
          <header>
            <h6>Nazwisko</h6>
          </header>
          <div class="input-box">
            <input type="text" class="data" placeholder="Nazwisko" v-model="surname">
          </div>
        </div>
        <div class="input-container" title="Email">
          <header>
            <h6>Email</h6>
          </header>
          <div class="input-box">
            <input type="text" class="data" placeholder="Email" v-model="email">
          </div>
        </div>
        <div class="input-container" title="Hasło">
          <header>
            <h6>Hasło</h6>
          </header>
          <div class="input-box">
            <input type="password" class="data" placeholder="Hasło" v-model="password1">
          </div>
        </div>
        <div class="input-container" title="Powtórz hasło">
          <header>
            <h6>Powtórz hasło</h6>
          </header>
          <div class="input-box">
            <input type="password" class="data" placeholder="Powtórz hasło" v-model="password2">
          </div>
        </div>
        <div class="ranks">
          <header>
            <h6>Wybierz rangi dla użytkownika</h6>
          </header>
          <div>
            <h5 v-for="(rank, index) in ranksChosen" :key="rank.id" :class="{ active: rank.value == 1 }" @click="chooseRank(index)">{{ rank.name }}</h5>
          </div>
        </div>
        <div class="flex-between">
          <button class="default small one" v-if="visible == 1" @click="createEmployee()">Dodaj</button>
          <button class="default small reverse" v-if="visible == 2" @click="deleteEmployee()">Usuń</button>
          <button class="default small" v-if="visible == 2" @click="editEmployee()">Edytuj</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PopupEmployee',
  components: {
  },
  data(){
    return{
      ranks: 0,
      ranksChosen: [],
      firstName: '',
      surname: '',
      email: '',
      password1: '',
      password2: '',
      employee: 0,
    }
  },
  computed:{
    visible(){
      return this.$store.state.popup;
    },
    api() {
      return this.$store.state.api;
    },
    employeeEditId() {
      return this.$store.state.employeeEditId;
    },
  },
  created(){
    this.getRanks();
  },
  watch: {
    visible(){
      if(this.visible == 2){
        this.getEmployee();
      }
    }
  },
  methods: {
    close(){
      if(this.visible == 2){
          this.firstName = '';
          this.surname = '';
          this.email = '';
          this.password1 = '';
          this.password2 = '';
          this.emptyRanks();
      }
      this.$store.commit('togglePopup', 0);
      this.$store.commit('changeEmployeeEditId', 0);
      document.body.style = "overflow-y: overlay";
    },
    getHeaderText(){
      if(this.visible == 1){
        return 'Dodaj nowego pracownika';
      }else{
        return 'Edytuj pracownika'
      }
    },
    fillRanks(ranks){
      for(let i = 0; i < ranks.length; i++){
        this.ranksChosen.push({ id: ranks[i].id, name: ranks[i].name, value: 0 })
      }
    },
    fillEditRanks(ranks){
      for(let i = 0; i < this.ranksChosen.length; i++){
        for(let j = 0; j < ranks.length; j++){
          if(this.ranksChosen[i].id == ranks[j].EmployeeEmployeeRank.rank_id){
            this.ranksChosen[i].value = 1;
          }
        }
      }
    },
    chooseRank(index){
      if(this.ranksChosen[index].value == 0){
        this.ranksChosen[index].value = 1;
      }else{
        this.ranksChosen[index].value = 0;
      }
    },
    emptyRanks(){
      for(let i = 0; i < this.ranksChosen.length; i++){
        this.ranksChosen[i].value = 0;
      }
    },
    getRanks(){
      let self = this;
      this.axios
      .get(this.api + 'employee/ranks', { headers: { Authorization: localStorage.accessToken } })
      .then(response => {
        self.ranks = response.data.data;
        self.fillRanks(self.ranks)
      });
    },
    async getEmployee(){
      this.ranksChosen = [];
      await this.getRanks();
      let self = this;
      this.axios
      .get(this.api + 'employee/employee', { params: { id: this.employeeEditId }, headers: { Authorization: localStorage.accessToken } })
      .then(response => {
        self.employee = response.data.data;
        self.firstName = self.employee.name;
        self.surname = self.employee.surname;
        self.email = self.employee.email;
        self.fillEditRanks(self.employee.EmployeeRanks)
      });
    },
    deleteEmployee(){
      let self = this;
      this.axios
      .delete(this.api + 'employee/employee', { data: { id: this.employeeEditId }} )
      .then(response => {
        if(response.data.status){
          self.firstName = '';
          self.surname = '';
          self.email = '';
          self.password1 = '';
          self.password2 = '';
          self.emptyRanks();
          self.close();
          self.$store.commit('reloadEmployees');
        }
        self.$store.commit('toggleAlert', {
          visibility: 1,
          type: response.data.status,
          msg: response.data.msg
        });
      });
    },
    editEmployee(){
      let self = this;
      this.axios
      .put(this.api + 'employee/employee', { id: this.employeeEditId, firstName: this.firstName, surname: this.surname, email: this.email, 
        password1: this.password1, password2: this.password2, ranks: JSON.stringify({ ranks: this.ranksChosen}) }, { headers: { Authorization: localStorage.accessToken } })
      .then(response => {
        if(response.data.status){
          self.firstName = '';
          self.surname = '';
          self.email = '';
          self.password1 = '';
          self.password2 = '';
          self.emptyRanks();
          self.close();
          self.$store.commit('reloadEmployees');
        }
        self.$store.commit('toggleAlert', {
          visibility: 1,
          type: response.data.status,
          msg: response.data.msg
        });
      });
    },
    createEmployee(){
      let self = this;
      this.axios
      .post(this.api + 'employee/employee', { firstName: this.firstName, surname: this.surname, email: this.email, 
        password1: this.password1, password2: this.password2, ranks: JSON.stringify({ ranks: this.ranksChosen}) }, { headers: { Authorization: localStorage.accessToken } })
      .then(response => {
        if(response.data.status){
          self.firstName = '';
          self.surname = '';
          self.email = '';
          self.password1 = '';
          self.password2 = '';
          self.emptyRanks();
          self.close();
          self.$store.commit('reloadEmployees');
        }
        self.$store.commit('toggleAlert', {
          visibility: 1,
          type: response.data.status,
          msg: response.data.msg
        });
      });
    },
  },
}
</script>
<style scoped lang="scss">
  div.flex { display: flex; flex-wrap: wrap; }
  div.flex-between { display: flex; flex-wrap: wrap; justify-content: space-between; margin: 20px 0; }

  div.input-container { margin: 10px auto; }
  button.one { margin-left: auto; }

  div.ranks { width: 350px; margin: 0 auto; }
  div.ranks header h6 { margin: 0; padding: 0 11px; font-size: 12px; letter-spacing: 0.5px; font-weight: 400; color: $grayLighter; }
  div.ranks div h5 { height: 25px; margin: 5px 0; padding: 0 15px; line-height: 25px; background: $grayLighter2; border-radius: 3px; font-weight: 500; font-size: 13px;
    color: $grayLight; cursor: pointer; }
  div.ranks div h5::first-letter { text-transform: uppercase; }
  div.ranks div h5:hover { background: $orangeLight; color: #fff; }
  div.ranks div h5.active { background: $orangeLight; color: #fff; }
</style>
