<template>
  <div class="popup" v-if="visible == 4">
    <div class="shadow" @click="close()"></div>
    <div class="container">
      <header>
        <h5>Wybierz banner</h5>
        <div class="close" @click="close()">
          <i class="material-icons">close</i>
        </div>
      </header>
      <div class="wrapper">
        <ul>
          <li :class="{ active: activeBanner == banner.id }" :title="banner.name" v-for="banner in banners" :key="banner.id" @click="activate(banner.id, banner.link)">
            <h6>{{ banner.id }}</h6>
            <h5>{{ banner.name }}</h5>
            <p>{{ banner.createdAt }}</p>
          </li>
        </ul>
        <div class="flex-between">
          <button class="default small reverse" @click="close()">Anuluj</button>
          <button class="default small" @click="choose()">Wybierz</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PopupMailingAddBanner',
  data(){
    return{
      banners: 0,
      activeBanner: 0,
      banner: { bannerId: 0, bannerLink: '' },
    }
  },
  computed:{
    visible(){
      return this.$store.state.popup;
    },
    api() {
      return this.$store.state.api;
    },
    mailingBanner() {
      return this.$store.state.mailingBanner;
    },
  },
  watch: {
    visible(){
      if(this.visible == 4){
        this.getBanners();
      }
    }
  },
  methods: {
    close(){
      this.$store.commit('togglePopup', 0);
      document.body.style = "overflow-y: overlay";
      this.activeBanner = 0;
      this.banner = { bannerId: 0, bannerLink: '' };
    },
    activate(id, link){
      this.activeBanner = id;
      this.banner = { bannerId: id, bannerLink: link };
    },
    choose(){
      if(this.banner.bannerId == 0){
        this.$store.commit('toggleAlert', {
            visibility: 1,
            type: 0,
            msg: 'Nie wybrano banneru'
          });
      }else{
        if(this.$route.params.themeId == 1){
          this.$store.commit('changeMailingBanner', { bannerId: this.banner.bannerId, bannerLink: this.banner.bannerLink });
          this.close();
        }
      }
    },
    getBanners(){
      let self = this;
      this.axios
      .get(this.api + 'employee/mailing/banner', { params: { }, headers: { Authorization: localStorage.accessToken } })
      .then(response => {
        self.banners = response.data;
        setTimeout(function(){ self.loader = 0; }, self.loaderTime);
      });
    },
  },
}
</script>
<style scoped lang="scss">
  div.flex-between { display: flex; justify-content: space-between; margin: 10px 0; }
  ul { margin: 40px 0; padding: 0; list-style: none; }
  ul li { display: flex; margin: 5px; background: $grayLighter4; cursor: pointer; }
  ul li:hover, ul li.active { background: $orangeLight; }
  ul li h6 { width: 20px; margin: 0; padding: 0; font-size: 14px; font-weight: 400; color: $blackLight; text-align: center; flex-shrink: 0; }
  ul li h5 { margin: 0 10px 0 0; padding: 0; font-size: 14px; font-weight: 400; color: $blackLight; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; }
  ul li p { width: 160px; margin: 0 5px 0 auto; padding: 0; font-size: 14px; font-weight: 400; text-align: right; color: $blackLight; flex-shrink: 0; }
  ul li:hover h6, ul li:hover h5, ul li:hover p, ul li.active h6, ul li.active h5, ul li.active p { color: #fff; }
</style>
