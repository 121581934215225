<template>
  <div class="virtual-office" :class="{ center: loader  == 1 || permission == 0 }">
    <h2 class="permission" v-if="!permission && loader == 0">Nie wystarczających uprawnień do przeglądania tej strony</h2>
    <loader v-if="loader == 1"></loader>
    <div v-show="loader == 0" v-if="permission">
    <div class="search-block">
      <div class="criteria">
        <div :class="{ active: searchType == 1 }" @click="changeSearchType(1)">
          <h5>NIP</h5>
        </div>
        <div :class="{ active: searchType == 2 }" @click="changeSearchType(2)">
          <h5>Firma</h5>
        </div>
        <div :class="{ active: searchType == 3 }" @click="changeSearchType(3)">
          <h5>Zdarzenie</h5>
        </div>
        <div :class="{ active: searchType == 4 }" @click="changeSearchType(4)">
          <h5>Data złożenia</h5>
        </div>
        <div :class="{ active: searchType == 5 }" @click="changeSearchType(5)">
          <h5>Data rozpoczęcia/zakończenia</h5>
        </div>
      </div>
      <div class="input-search">
        <input class="search" type="text" :placeholder="getSearchTypeText()" v-model="searchPattern" @keyup="getEventsPages()">
        <div class="icon search">
          <i class="material-icons">search</i>
        </div>
        <div class="icon closing" @click="clearPattern()">
          <i class="material-icons">close</i>
        </div>
      </div>
      <div class="criteria">
        <div :class="{ active: searchFilter[0].value == 1 }" @click="changeSearchFilter(0)">
          <h5>Wszystkie</h5>
        </div>
        <div :class="{ active: searchFilter[1].value == 1 }" @click="changeSearchFilter(1)">
          <h5>Nieopłacone</h5>
        </div>
        <div :class="{ active: searchFilter[2].value == 1 }" @click="changeSearchFilter(2)">
          <h5>Opłacone</h5>
        </div>
        <div :class="{ active: searchFilter[9].value == 1 }" @click="changeSearchFilter(9)">
          <h5>Anulowane</h5>
        </div>
        <div :class="{ active: searchFilter[8].value == 1 }" @click="changeSearchFilter(8)">
          <h5>Umowa na miejscu</h5>
        </div>
        <div :class="{ active: searchFilter[3].value == 1 }" @click="changeSearchFilter(3)">
          <h5>Oczekiwanie na wysyłkę</h5>
        </div>
        <div :class="{ active: searchFilter[4].value == 1 }" @click="changeSearchFilter(4)">
          <h5>Umowa wysłana</h5>
        </div>
        <div :class="{ active: searchFilter[5].value == 1 }" @click="changeSearchFilter(5)">
          <h5>Umowa podpisana</h5>
        </div>
        <div :class="{ active: searchFilter[6].value == 1 }" @click="changeSearchFilter(6)">
          <h5>Wygaśnięte</h5>
        </div>
        <div :class="{ active: searchFilter[7].value == 1 }" @click="changeSearchFilter(7)">
          <h5>Zakończono</h5>
        </div>
      </div>
    </div>
    <div class="table">
      <header>
        <div>
          <h5>NIP</h5>
          <h6>Data zdarzenia</h6>
          <h6>Firma</h6>
        </div>
        <h5>Zdarzenie</h5>
        <div>
          <h5>Data rozpoczęcia</h5>
          <h6>Data zakończenia</h6>
        </div>
        <div>
          <h5>Typ pakietu</h5>
          <h6>Okres</h6>
        </div>
        <div>
          <h5>Cena netto</h5>
          <h6>Cena brutto</h6>
        </div>
        <h5>Status wydarzenia</h5>
        <h5>Akcje</h5>
      </header>
      <div class="box row" v-for="event in events" :key="event.id">
        <div class="info">
          <div>
            <h5>{{ event.Company.nip }}</h5>
            <h6>{{formatDate(event.createdAt) }}</h6>
          </div>
          <h5>{{ event.EmployeeEventType.name }}</h5>
          <div>
            <h5>{{ getStartDate(event) }}</h5>
            <h6>{{ getEndDate(event) }}</h6>
          </div>
          <div>
            <h5>Pakiet <span>{{ event.Service.name }}</span></h5>
            <h6>{{ event.Service.period }} m-c</h6>
          </div>
          <div>
            <h5>{{ getNetto(event.Order) }}</h5>
            <h6>{{ getBrutto(event.Order) }}</h6>
          </div>
          <div>
            <div class="label" :class="{ red: event.event_status_id == 1 || event.event_status_id == 9 , green: event.event_status_id == 2 || event.event_status_id == 5, 
              blue: event.event_status_id == 3, purple: event.event_status_id == 4, gray: event.event_status_id == 6 || event.event_status_id == 7,
              orange: event.event_status_id == 8, }">
              <i class="material-icons">{{ getStatusIcon(event.event_status_id) }}</i>
              <p>{{ event.EmployeeEventStatus.name }}</p>
            </div>
          </div>
          <div class="actions">
            <div class="action" title="Szczegóły" :class="{ inactive: event.event_type_id == 2 || event.event_type_id == 3 || event.event_type_id == 4
              || event.event_status_id == 7 }" 
              @click="showDetails(event.id, event)">
              <i class="material-icons">search</i>
            </div>
            <div class="action" title="Umowa najmu" :class="{ inactive: event.event_type_id == 2 || event.event_type_id == 3 || event.event_type_id == 4 || event.event_type_id == 5
              || event.event_status_id == 1 || event.event_status_id == 7 || event.event_status_id == 9  }"
              @click="showDocument(event.company_id, event.order_id, event)">
              <i class="material-icons">content_copy</i>
            </div>
            <div class="action" title="Faktura" :class="{ inactive: event.event_type_id == 4 || event.event_status_id == 1 || event.event_status_id == 9 }"
              @click="showInvoice(event.order_id, event)">
              <i class="material-icons">sticky_note_2</i>
            </div>
            <div class="action" title="Dane klienta">
              <router-link :to="{ name: 'Client', params: { clientId: event.user_id }}">
                <i class="material-icons">person</i>
              </router-link>
            </div>
            <div class="action" title="Zmień status" :class="{ inactive: event.event_type_id == 2 || event.event_type_id == 3 || event.event_status_id == 7 || event.event_type_id == 5
              || event.event_status_id == 1 }" 
              @click="changeStatus(event.id, event.event_status_id, event.event_type_id)">
              <i class="material-icons">edit</i>
            </div>
          </div>
        </div>
        <div class="companies" :class="{ red: event.event_status_id == 1 || event.event_status_id == 9, green: event.event_status_id == 2 || event.event_status_id == 5, 
          blue: event.event_status_id == 3, purple: event.event_status_id == 4, gray: event.event_status_id == 6 || event.event_status_id == 7,
          orange: event.event_status_id == 8}">
          <h5>{{ event.Company.company_name }}</h5>
        </div>
      </div>
    </div>
    <pagination source="events" :pages="pagesEvents"></pagination>
    <popup-virtual-office-details></popup-virtual-office-details>
    <popup-invoice></popup-invoice>
    <popup-virtual-office-status></popup-virtual-office-status>
    <popup-virtual-office-document :ids="choosenIds" ></popup-virtual-office-document>
    </div>
  </div>
</template>

<script>
import Loader from '@/components/Loader.vue'
import Pagination from '@/components/Pagination.vue'
import PopupInvoice from '@/components/popups/Invoice.vue'
import PopupVirtualOfficeStatus from '@/components/popups/VirtualOfficeStatus.vue'
import PopupVirtualOfficeDocument from '@/components/popups/VirtualOfficeDocument.vue'
import PopupVirtualOfficeDetails from '@/components/popups/VirtualOfficeDetails.vue'

export default {
  name: 'VirtualOffice',
  components: {
    Loader,
    Pagination,
    PopupInvoice,
    PopupVirtualOfficeStatus,
    PopupVirtualOfficeDocument,
    PopupVirtualOfficeDetails
  },
  data(){
    return{
      loader: 1,
      searchType: 1,
      searchFilter: [],
      pagesEvents: 0,
      events: 0,
      searchPattern: '',
      permission: 1,
      choosenIds: {
        companyId: 0,
        orderId: 0
      },
    }
  },
  computed: {
    api() {
      return this.$store.state.api;
    },
    loaderTime() {
      return this.$store.state.loaderTime;
    },
    pageEvents() {
      return this.$store.state.pageEvents;
    },
    reloadedEvents() {
      return this.$store.state.reloadedEvents;
    },
  },
  created(){
    if(typeof localStorage.virtualOfficeSearch == 'undefined'){
      localStorage.setItem('virtualOfficeSearch', JSON.stringify({ searchType: 1, searchFilter:  [{ value: 1 }, { value: 0 }, { value: 0 }, { value: 0 }, 
      { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }] }));
      this.searchType = 1;
      this.searchFilter = [{ value: 1 }, { value: 0 }, { value: 0 }, { value: 0 }, 
      { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }];
    }else{
      let virtualOfficeSearch = JSON.parse(localStorage.virtualOfficeSearch);
      this.searchType = virtualOfficeSearch.searchType;
      this.searchFilter = virtualOfficeSearch.searchFilter;
    }
    this.getEventsPages();
  },
  watch: {
    reloadedEvents(){
      this.getEventsPages();
    },
    pageEvents(){
      if(this.pageEvents != -1){
        this.getEvents();
      }
    },
    searchType(){
      this.getEventsPages();
    },
  },
  methods: {
    clearPattern(){
      this.searchPattern = '';
      this.getEventsPages();
    },
    changeStatus(id, status, type){
      if(type != 2 && type != 3 && type != 5 && status != 7 && status != 1){
        this.$store.commit('changeEventEdit', { id: id, status: status, type: type });
        this.$store.commit('togglePopup', 1);
      }
    },
    showDetails(eventId, event){
      if(!(event.event_type_id == 2 || event.event_type_id == 3 || event.event_type_id == 4 || event.event_status_id == 7)) {
        this.$store.commit('togglePopup', 4);
        this.$store.commit('changeEventId', eventId);
      }
    },
    showInvoice(orderId, event){
      if(!(event.event_type_id == 4 || event.event_status_id == 1 || event.event_status_id == 9)) {
        this.$store.commit('changeOrderId', orderId);
        this.$store.commit('togglePopup', 2);
      };
    },
    showDocument(companyId, orderId, event){
      if(!(event.event_type_id == 2 || event.event_type_id == 3 || event.event_type_id == 4 || event.event_status_id == 1 || event.event_status_id == 7 || event.event_status_id == 9)) {
        this.choosenIds.companyId = companyId;
        this.choosenIds.orderId = orderId;
        this.$store.commit('togglePopup', 3);
      };
    },
    changeSearchType(type){
      this.searchType = type;
      localStorage.virtualOfficeSearch = JSON.stringify({ searchType: this.searchType, searchFilter:  this.searchFilter });
    },
    getSearchTypeText(){
      switch(this.searchType){
        case 1:
          return 'Szukaj po NIP';
        break;
        case 2:
          return 'Szukaj po firmie';
        break;
        case 3:
          return 'Szukaj po zdarzeniu';
        break;
        case 4:
          return 'Szukaj po dacie złożenia';
        break;
        case 5:
          return 'Szukaj po dacie rozpoczęcia/zakończenia';
        break;
      }
    },
    changeSearchFilter(index){
      if(this.searchFilter[index].value){
        let on = 0;
        for(let i = 0; i < this.searchFilter.length; i++){
          if(this.searchFilter[i].value == 1){
            on += 1;
          }
        }
        if(on > 1){
          this.searchFilter[index].value = 0;
        }
      }else{
        this.searchFilter[index].value = 1;
      }
      if(index == 0 && this.searchFilter[index].value == 1){
        for(let i = 1; i < this.searchFilter.length; i++){
          this.searchFilter[i].value = 0;
        }
      }
      if(index != 0 && this.searchFilter[index].value == 1){
        this.searchFilter[0].value = 0;
      }
      localStorage.virtualOfficeSearch = JSON.stringify({ searchType: this.searchType, searchFilter:  this.searchFilter });
      this.getEventsPages();
    },
    formatDate(date){
      if(date == null){
        return 'Brak'        
      }
      let dateObj = new Date(date);
      let chosenDays = dateObj.getDate();
      if(chosenDays < 10){
        chosenDays = '0' + chosenDays;
      }
      let chosenMonths = dateObj.getMonth() + 1;
      if(chosenMonths < 10){
        chosenMonths = '0' + chosenMonths
      }
      let chosenHours = dateObj.getHours();
      if(chosenHours < 10){
        chosenHours = '0' + chosenHours;
      }
      let chosenMinutes = dateObj.getMinutes();
      if(chosenMinutes < 10){
        chosenMinutes = '0' + chosenMinutes;
      }
      return dateObj.getFullYear() + '-' + chosenMonths + '-' + chosenDays + ' ' + chosenHours + ':' + chosenMinutes;
    },
    getStartDate(event){
      if(event.event_type_id == 4){
        return '--'
      }else{
        return this.formatDate(event.Order.OrderDetails[0].start_at);
      }
    },
    getEndDate(event){
      if(event.event_type_id == 4){
        return this.formatDate(event.Company.CompanyServices[0].end_at);
      }else{
        return this.formatDate(event.Order.OrderDetails[0].end_at);
      }
    },
    getStatusIcon(status){
    switch(status){
        case 1:
          return 'close'
        break;
        case 2:
          return 'payment'
        break;
        case 3:
          return 'more_horiz'
        break;
        case 4:
          return 'email'
        break;
        case 5:
          return 'done'
        break;
        case 6:
          return 'close'
        break;
        case 7:
          return 'close'
        break;
        case 8:
          return 'place'
        break;
        case 9:
          return 'close';
        break;
      }
    },
    getNetto(order){
      if(order == null){
        return '0.00';
      }else{
        return order.total_netto.toFixed(2);
      }
    },
    getBrutto(order){
      if(order == null){
        return '0.00';
      }else{
        return (order.total_netto * 1.23).toFixed(2);
      }
    },
    getEventsPages(){
      let self = this;
      this.axios
      .get(this.api + 'employee/events/virtual-office', { params: { page: 0, searchType: this.searchType, searchFilter: { searchFilter: this.searchFilter }, searchPattern: this.searchPattern }, headers: { Authorization: localStorage.accessToken } })
      .then(response => {
        if(response.data.permission == 0){
          this.permission = 0;
        }
        self.$store.commit('changePageEvents', -1);
        self.pagesEvents = response.data.pages;
        self.$store.commit('reloadPageEvents');
      });
    },
    getEvents(){
      let self = this;
      this.axios
      .get(this.api + 'employee/events/virtual-office', { params: { page: this.pageEvents, searchType: this.searchType, searchFilter: { searchFilter: this.searchFilter }, searchPattern: this.searchPattern }, headers: { Authorization: localStorage.accessToken } })
      .then(response => {
        self.events = response.data.data;
        setTimeout(function(){ self.loader = 0; }, self.loaderTime);
      });
    }
  }
}
</script>
<style scoped lang="scss">
  div.virtual-office { padding: 10px; }
  div.virtual-office.center { display: flex; justify-content: center; align-items: center; min-height: calc(100vh - 60px); }

  div.search-block { display: flex; flex-direction: column; align-items: flex-end; }
  div.input-search { width: 500px; }

  div.table header div:first-child { width: 130px; }
  div.table header h5:nth-child(2) { width: 150px; }
  div.table header div:nth-child(3) { width: 150px; }
  div.table header div:nth-child(4) { width: 150px; }
  div.table header div:nth-child(5) { width: 80px; }
  div.table header h5:nth-child(6) { width: 200px; }
  div.table header h5:last-child { width: 180px; }

  div.table div.box.row div.info > div:first-child { width: 130px; }
  div.table div.box.row div.info > h5:nth-child(2) { width: 150px; }
  div.table div.box.row div.info > h5::first-letter { text-transform: uppercase; }
  div.table div.box.row div.info div h5 span { text-transform: capitalize; }
  div.table div.box.row div.info > div:nth-child(3) { width: 150px; }
  div.table div.box.row div.info > div:nth-child(4) { width: 150px; }
  div.table div.box.row div.info > div:nth-child(5) { width: 80px; }
  div.table div.box.row div.info > div:nth-child(6) { width: 200px; }

  div.companies.red h5 { color: $colorRed; }
  div.companies.blue h5 { color: $colorBlue; }
  div.companies.green h5 { color: $colorGreen; }
  div.companies.purple h5 { color: $colorPurple; }
  div.companies.gray h5 { color: $grayLight; }
  div.companies.orange h5 { color: $colorOrange; }

</style>
