<template>
   <div id="alert" :class="{ green: alert.type == 1, red: alert.type == 0, blue: alert.type == 2, }" v-if="alert.visibility == 1">
      <div class="close" @click="close()">
        <i class="material-icons">close</i>
      </div>
     <p>{{ alert.msg }}</p>
  </div>
</template>

<script>
export default {
  name: 'Alert',
  data(){
    return{
      timeout: 0,
      visibility: 0,
    }
  },
  computed:{
    alert(){
      return this.$store.state.alert;
    },
  },
  watch:{
    alert:{
      deep: true,
      handler(){
        if(this.alert.visibility == 1){
          this.hideTime();
        }
      }
    },
  },
  methods:{
    close(){
      clearTimeout(this.timeout)
      this.$store.commit('toggleAlert', {
        visibility: 0,
        type: 0,
        msg: ''
      });
    },
    hideTime(){
      clearTimeout(this.timeout)
      if(this.alert.type != 2){
        self = this;
        this.timeout = setTimeout(function(){ 
          self.$store.commit('toggleAlert', {
            visibility: 0,
            type: 0,
            msg: ''
          });
        }, 3000);
      }
    },
  }
}
</script>

<style scoped lang="scss">
  div#alert { display: flex; flex-direction: column; justify-content: center; align-items: center; min-height: 35px; max-height: 70px; position: fixed; bottom: 30px; 
    left: 30px; z-index: 5; border-radius: 3px; }
  div#alert.green { background: $greenLight; box-shadow: 0px 0px 4px $greenLight; }
  div#alert.red { background: $red; box-shadow: 0px 0px 4px $red; }
  div#alert.blue { background: $blueLighter; box-shadow: 0px 0px 4px $blueLighter; }
  div#alert div.close { display: flex; justify-content: center; align-items: center; width: 20px; height: 20px; margin-left: auto; 
    cursor: pointer; position: absolute; right: 5px; border-radius: 50%; }
  div#alert div.close:hover { background: rgba(255, 255, 255, 0.5); }
  div#alert div.close i.material-icons { color: #fff; font-size: 18px; }
  div#alert div.close:hover i.material-icons { color: rgba(0, 0, 0, 0.5); }
  div#alert p { margin: -2.5px 0 0 0; padding: 5px 35px 5px 15px; text-align: center; font-weight: 300; font-size: 16px; color: #fff; }

  @media (max-width: 700px) {
    div#alert { max-width: calc(100vw - 115px); left: 25px; bottom: 25px; }
    div#alert p { font-size: 14px; }
  }
</style>
