<template>
  <div class="popup" v-if="visible == 3">
    <div class="shadow" @click="close()"></div>
    <div class="container">
      <header>
        <h5>Wybierz załącznik</h5>
        <div class="close" @click="close()">
          <i class="material-icons">close</i>
        </div>
      </header>
      <div class="wrapper" v-if="attachments != 0">
        <ul>
          <li :class="{ active: activeAttachment == attachment.id }" :title="attachment.name" v-for="attachment in attachments" :key="attachment.id" @click="activate(attachment.id, attachment.name, attachment.link)">
            <h6>{{ attachment.id }}</h6>
            <h5>{{ attachment.name }}</h5>
            <p>{{ attachment.createdAt }}</p>
          </li>
        </ul>
        <div class="flex-between">
          <button class="default small reverse" @click="close()">Anuluj</button>
          <button class="default small" @click="choose()">Wybierz</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PopupMailingAddAttachment',
  data(){
    return{
      attachments: 0,
      activeAttachment: 0,
      attachment: { attachmentId: 0, attachmentName: '', attachmentLink: '' },
    }
  },
  computed:{
    visible(){
      return this.$store.state.popup;
    },
    api() {
      return this.$store.state.api;
    },
    mailingAttachment() {
      return this.$store.state.mailingAttachment;
    },
  },
  watch: {
    visible(){
      if(this.visible == 3){
        this.getAttachments();
      }
    }
  },
  methods: {
    close(){
      this.$store.commit('togglePopup', 0);
      document.body.style = "overflow-y: overlay";
      this.activeAttachment = 0;
      this.attachment = { attachmentId: 0, attachmentName: '', attachmentLink: ''};
    },
    activate(id, name, link){
      this.activeAttachment = id;
      this.attachment = { attachmentId: id, attachmentName: name, attachmentLink: link };
    },
    choose(){
      if(this.attachment.attachmentId == 0){
        this.$store.commit('toggleAlert', {
            visibility: 1,
            type: 0,
            msg: 'Nie wybrano załącznika'
          });
      }else{
        if(this.$route.params.themeId == 1){
          this.$store.commit('changeMailingAttachment', { index: this.mailingAttachment.index, attachmentId: this.attachment.attachmentId, 
            attachmentName: this.attachment.attachmentName, attachmentLink: this.attachment.attachmentLink });
          this.close();
        }
      }
    },
    getAttachments(){
      let self = this;
      this.axios
      .get(this.api + 'employee/mailing/attachment', { params: { }, headers: { Authorization: localStorage.accessToken } })
      .then(response => {
        self.attachments = response.data;
        setTimeout(function(){ self.loader = 0; }, self.loaderTime);
      });
    },
  },
}
</script>
<style scoped lang="scss">
  div.flex-between { display: flex; justify-content: space-between; margin: 10px 0; }
  ul { margin: 40px 0; padding: 0; list-style: none; }
  ul li { display: flex; margin: 5px; background: $grayLighter4; cursor: pointer; }
  ul li:hover, ul li.active { background: $orangeLight; }
  ul li h6 { width: 20px; margin: 0; padding: 0; font-size: 14px; font-weight: 400; color: $blackLight; text-align: center; flex-shrink: 0;  }
  ul li h5 { margin: 0 10px 0 0; padding: 0; font-size: 14px; font-weight: 400; color: $blackLight; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; }
  ul li p { width: 160px; margin: 0 5px 0 auto; padding: 0; font-size: 14px; font-weight: 400; text-align: right; color: $blackLight; flex-shrink: 0; }
  ul li:hover h6, ul li:hover h5, ul li:hover p, ul li.active h6, ul li.active h5, ul li.active p { color: #fff; }
</style>
