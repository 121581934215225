<template>
  <div class="popup" v-if="visible == 5">
    <div class="shadow" @click="close()"></div>
    <div class="container">
      <header>
        <h5>Pogląd i wysyłanie</h5>
        <div class="close" @click="close()">
          <i class="material-icons">close</i>
        </div>
      </header>
      <div class="wrapper" v-if="this.data != 0 && this.constants != 0">
        <div class="theme1" v-if="data.themeId == 1">
          <table width="600" border="0" cellspacing="0" cellpadding="0" style="width: 600px; margin: 0 auto;">
            <tr style="background-color: #ececec;">
              <td style="padding: 10px 0; width: 100%; text-align: center;"><img src="https://api.afk-cob.pl/assets/test_logo.png"></td>
            </tr>
            <table v-for="(container, index) in data.data.containers" :key="index" width="600" cellspacing="0" cellpadding="0">
              <tr style="width: 600px;" :style="{ 'background-color': findHeadliner(container.categoryId, 'color') }">
                  <td style="padding: 10px; font-size: 20px; color: #fff;">{{ findHeadliner(container.categoryId, 'title')}}</td>
              </tr>
              <tr style="height: 1px; background-color: #ececec;"></tr>
              <tr :style="{ 'background-color': findHeadliner(container.categoryId, 'color') }">
                  <td style="padding: 10px; font-size: 13px; color: #fff;">{{ container.subtitle }}</td>
              </tr>
              <tr style="background-color: #ececec;">
                  <td style="padding: 10px; font-size: 14px;">{{ container.text }}</td>
              </tr>
              <tr style="background-color: #ececec;" v-if="container.attachmentId != 0">
                  <td style="padding: 5px 10px; width: 100%; text-align: right;"><a :href="container.attachmentLink">Dowiedz się więcej</a></td>
              </tr>
            </table>
            <tr v-if="data.data.banner.bannerId != 0">
                <td style="width: 100%; text-align: center;"><img style="vertical-align: top;" :src="data.data.banner.bannerLink"></td>
            </tr>
            <tr style="background-color: #ececec;">
                <td style="padding: 10px; width: 100%;">AFK Centrum Obsługi Biznesu Sp. z o.o<br>ul. Świeradowska 51-57, 50 - 559 Wrocław<br>NIP: 8992664769, REGON: 020936750<br>tel.: +48 71 336 95 21, e-mail: bok@afkcob.pl</td>
            </tr>
          </table>
        </div>
        <div class="flex-between">
          <button class="default small reverse" @click="close()">Wróć</button>
          <button class="default small" @click="send()">Wyślij</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PopupMailingView',
  data(){
    return{
      data: 0,
      constants: 0,
    }
  },
  computed:{
    visible(){
      return this.$store.state.popup;
    },
    api() {
      return this.$store.state.api;
    },
    mailingView() {
      return this.$store.state.mailingView;
    },
  },
  watch: {
    mailingView(){
      this.data = this.mailingView;
      this.getConstants();
    }
  },
  methods: {
    close(){
      this.$store.commit('togglePopup', 0);
      document.body.style = "overflow-y: overlay";
      this.data = 0;
    },
    findHeadliner(id, data){
      let headliner = 0;
      for(let i = 0; i < this.constants.headliners.length; i++){
        if(this.constants.headliners[i].id == id){
          headliner = this.constants.headliners[i];
          break;
        }
      }
      switch(data){
        case 'color':
          return headliner.color;
        break;
        case 'title':
          return headliner.title;
        break;
      }
    },
    getConstants(){
      let self = this;
      this.axios
      .get(this.api + 'employee/mailing/constants', { params: { }, headers: { Authorization: localStorage.accessToken } })
      .then(response => {
        self.constants = response.data;
      });
    },
    send(){
      if(this.data.themeId == 1){
        this.data.data.bannerId = this.data.data.banner.bannerId;
      }
      let self = this;
      this.axios
      .post(this.api + 'employee/mailing/send', this.data, { headers: { Authorization: localStorage.accessToken } })
      .then(response => {
        self.$store.commit('toggleAlert', {
          visibility: 1,
          type: response.data.status,
          msg: response.data.msg
        });
        if(response.data.status == 1 || response.data.status == 2){
          self.close();
        }
      });
    }
  },
}
</script>
<style scoped lang="scss">
  div.popup div.container { width: initial; }
  div.popup div.container div.wrapper { padding: 0 20px }
  div.flex-between { display: flex; justify-content: space-between; margin: 40px 0 20px 0; }
</style>
