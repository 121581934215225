<template>
  <div class="client" :class="{ center: loader  == 1 }">
    <loader v-if="loader == 1"></loader>
    <div class="flex" v-if="loader == 0">
      <div class="box client-info">
        <header class="box">
          <h6>Informacje o kliencie</h6>
        </header>
        <ul>
          <li>
            <h5>ID:</h5>
            <p>{{ client.user.id }}</p>
          </li>
          <li>
            <h5>Email:</h5>
            <p>{{ client.user.email }}</p>
          </li>
          <li>
            <h5>Status:</h5>
            <div class="label" :class="{ blue: client.user.status == 0, green: client.user.status == 1, orange: client.user.status == 2 }">
              <i class="material-icons">{{ getStatusIcon(client.user.status) }}</i>
              <p>{{ getStatusText(client.user.status) }}</p>
            </div>
          </li>
          <li>
            <h5>Data dołączenia:</h5>
            <p>{{ formatDate(client.user.createdAt) }}</p>
          </li>
          <li>
            <h5>Data ostatniego logowania:</h5>
            <p>{{ formatDate(client.user.last_login) }}</p>
          </li>
          <li>
            <h5>Data ostatniej akcji:</h5>
            <p>{{ formatDate(client.user.last_action) }}</p>
          </li>
          <li>
            <h5>Data ostaniego wylogowania:</h5>
            <p>{{ formatDate(client.user.last_logout) }}</p>
          </li>
          <li>
            <h5>Data ostatniego zamówienia:</h5>
            <p>{{ formatDate(client.lastOrderDate) }}</p>
          </li>
          <li>
            <h5>Liczba firm:</h5>
            <p>{{ client.companies.length }}</p>
          </li>
          <li>
            <h5>Liczba aktywnych usług:</h5>
            <p>{{ getActiveServices(client.companies) }}</p>
          </li>
          <li>
            <h5>Wartość netto:</h5>
            <p>{{ client.totalNetto }}</p>
          </li>
          <li>
            <h5>Wartość brutto:</h5>
            <p>{{ client.totalBrutto }}</p>
          </li>
        </ul>
      </div>
      <div class="box client-company" v-for="(company, index1) in client.companies" :key="company.id">
        <header class="box">
          <h6>Informacje o firmie</h6>
        </header>
        <h4>{{ company.company_name }}</h4>
        <ul>
          <li>
            <h5>Typ:</h5>
            <p>{{ company.company_type }}</p>
          </li>
          <li>
            <h5>NIP:</h5>
            <p>{{ company.nip }}</p>
          </li>
          <li>
            <h5>Regon:</h5>
            <p>{{ company.regon }}</p>
          </li>
          <li>
            <h5>Krs:</h5>
            <p>{{ company.krs }}</p>
          </li>
          <li>
            <h5>Data dodania:</h5>
            <p>{{ formatDate(company.createdAt) }}</p>
          </li>
          <li>
            <h5>Miasto:</h5>
            <p>{{ company.city }}</p>
          </li>
          <li>
            <h5>Ulica:</h5>
            <p>{{ company.street }} {{ company.number_house }}{{ getNumberApartment(company.number_apartment) }}</p>
          </li>
          <li>
            <h5>Kod pocztowy:</h5>
            <p>{{ company.post_code }}</p>
          </li>
        </ul>
        <div class="service" :class="{ green: service.CompanyServices.status == 2, gray: service.CompanyServices.status != 2 }" v-for="(service, index2) in company.Services" :key="service.id">
          <div>
            <h5>{{ service.ServiceType.name }}</h5>
            <div class="icon" @click="toggleService(index1, index2)">
              <i class="material-icons">{{ getServiceIcon(service.CompanyServices.status, services[index1][index2].value) }}</i>
            </div>
          </div>
          <div v-if="services[index1][index2].value">
            <h6>Pakiet <span>{{ service.name }}</span> {{ service.period }} m-c</h6>
            <h6>Okres: od {{ formatDate(service.CompanyServices.createdAt) }} do {{ formatDate(service.CompanyServices.end_at) }}</h6>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loader from '@/components/Loader.vue'
import Pagination from '@/components/Pagination.vue'

export default {
  name: 'Client',
  components: {
    Loader,
    Pagination
  },
  data(){
    return{
      loader: 1,
      client: 0,
      services: [],
    }
  },
  computed: {
    api() {
      return this.$store.state.api;
    },
    loaderTime() {
      return this.$store.state.loaderTime;
    },
  },
  created(){
    this.getClient();
  },
  methods: {
    getStatusText(status){
      switch(status){
        case 0:
          return 'niekatywny'
        break;
        case 1:
          return 'aktywny'
        break;
        case 2:
          return 'zbanowany'
        break;
      }
    },
    getStatusIcon(status){
    switch(status){
        case 0:
          return 'more_horiz'
        break;
        case 1:
          return 'done'
        break;
        case 2:
          return 'close'
        break;
      }
    },
    formatDate(date){
      if(date == null){
        return 'Brak'        
      }
      let dateObj = new Date(date);
      let chosenDays = dateObj.getDate();
      if(chosenDays < 10){
        chosenDays = '0' + chosenDays;
      }
      let chosenMonths = dateObj.getMonth() + 1;
      if(chosenMonths < 10){
        chosenMonths = '0' + chosenMonths
      }
      let chosenHours = dateObj.getHours();
      if(chosenHours < 10){
        chosenHours = '0' + chosenHours;
      }
      let chosenMinutes = dateObj.getMinutes();
      if(chosenMinutes < 10){
        chosenMinutes = '0' + chosenMinutes;
      }
      return dateObj.getFullYear() + '-' + chosenMonths + '-' + chosenDays + ' ' + chosenHours + ':' + chosenMinutes;
    },
    getNumberApartment(aparment){
      if(aparment != ''){
        return '/' + aparment;
      }
    },
    getActiveServices(companies){
      let activeServices = 0;
      for(let i = 0; i < companies.length; i++){
        for(let j = 0; j < companies[i].Services.length; j++)
        if(companies[i].Services[j].CompanyServices.status == 1){
          activeServices++;
        }
      }
      return activeServices;
    },
    getServiceIcon(status, value){
      if(status != 2){
        return 'close';
      }else{
        if(value == 0){
          return 'keyboard_arrow_down'
        }else{
          return 'keyboard_arrow_up'
        }
      }
    },
    fillServicessArray(companies){
      for(let i = 0; i < companies.length; i++){
        let array = [];
        for(let j = 0; j < companies[i].Services.length; j++){
          array.push({ value: 0 })
        }
        this.services.push(array);
      }
    },
    toggleService(index1, index2){
      if(this.services[index1][index2].value == 1){
        this.services[index1][index2].value = 0;
      }else{
        this.services[index1][index2].value = 1;
      }
    },
    getClient(){
      let self = this;
      this.axios
      .get(this.api + 'employee/client', { params: { id: this.$route.params.clientId }, headers: { Authorization: localStorage.accessToken } })
      .then(response => {
        if(response.data.status == 0){
          this.$router.push('/klienci')
        }else{
          self.client = response.data.data;
          self.fillServicessArray(self.client.companies);
          setTimeout(function(){ self.loader = 0; }, self.loaderTime);
        }
      });
    },
  }
}
</script>
<style scoped lang="scss">
  div.client { padding: 10px; }
  div.client.center { display: flex; justify-content: center; align-items: center; min-height: calc(100vh - 60px); }
  div.flex { display: flex; flex-wrap: wrap; }

  div.client-info, div.client-company  { width: 400px; }
  ul { margin: 0; padding: 0; list-style: none; }
  ul li { display: flex; justify-content: space-between; margin: 7px 0; }
  ul li h5 { margin: 3px 10px; padding: 0; font-weight: 300; color: $grayLighter2; font-size: 15px; }
  ul li p { margin: 3px 10px; padding: 0; font-size: 16px; color: $blackLight; text-align: right; }
  ul li div { margin: 0 10px; }

  div.client-company h4 { margin: 20px 10px; padding: 0; font-weight: 500; text-align: right; }
  div.client-company div.service { margin: 10px; border-radius: 3px; overflow: auto; }
  div.client-company div.service div:first-child { display: flex; justify-content: space-between; align-items: center; }
  div.client-company div.service.green { background: $greenGradient; }
  div.client-company div.service.gray { background: $grayGradient; }
  div.client-company div.service h5 { margin: 5px 10px; padding: 0; font-weight: 400; font-size: 15px; color: #fff; text-transform: capitalize; }
  div.client-company div.service div.icon { display: flex; justify-content: center; align-items: center; width: 35px; height: 35px; cursor: pointer; }
  div.client-company div.service div.icon i.material-icons { font-size: 24px; color: rgba(255, 255, 255, 0.4) }
  div.client-company div.service h6 { margin: 5px 10px; padding: 0; font-weight: 400; font-size: 13px; color: rgba(255, 255, 255, 0.9); }
  div.client-company div.service h6 span { text-transform: capitalize; }

  div.table header div:first-child { width: 130px; }
  div.table header h5:nth-child(2) { width: 300px; }
  div.table header div:nth-child(3) { width: 150px; }
  div.table header div:nth-child(4) { width: 150px; }
  div.table header div:nth-child(5) { width: 80px; }
  div.table header h5:nth-child(6) { width: 200px; }
  div.table header h5:last-child { width: 40px; }

  div.table div.box.row div.info > div:first-child { width: 130px; }
  div.table div.box.row div.info > h5:nth-child(2) { width: 300px; }
  div.table div.box.row div.info > h5:nth-child(3) { width: 150px; }
  div.table div.box.row div.info > div:nth-child(4) { width: 150px; }
  div.table div.box.row div.info > div:nth-child(5) { width: 80px; }
  div.table div.box.row div.info > div:nth-child(6) { width: 200px; }
</style>
