<template>
  <div class="mailing">
    <section class="themes">
      <div class="box theme" v-for="theme in themes" :key="theme.id">
        <router-link :to="{ name: 'MailingTheme', params: { themeId: theme.id }}">
          <div class="img"><img :src="getThemeImg(theme.img)"></div>
          <footer>
            <h5>{{ theme.name }}</h5>
          </footer>
        </router-link>
      </div>
    </section>
    <aside>
      <mailing-attachments></mailing-attachments>
      <mailing-banners></mailing-banners>
    </aside>
    <popup-mailing-upload-file></popup-mailing-upload-file>
    <popup-mailing-delete-file></popup-mailing-delete-file>
  </div>
</template>

<script>
import Loader from '@/components/Loader.vue'
import MailingAttachments from '@/components/MailingAttachments.vue'
import MailingBanners from '@/components/MailingBanners.vue'
import PopupMailingUploadFile from '@/components/popups/MailingUploadFile.vue'
import PopupMailingDeleteFile from '@/components/popups/MailingDeleteFile.vue'

export default {
  name: 'Mailing',
  components: {
    Loader,
    MailingAttachments,
    MailingBanners,
    PopupMailingUploadFile,
    PopupMailingDeleteFile
  },
  data(){
    return{
      loader: 1,
      themes: 0,
    }
  },
  computed: {
    api() {
      return this.$store.state.api;
    },
  },
  created(){
    this.getThemes();
  },
  methods: {
    getThemeImg(img){
      return '/img/themes/' + img;
    },
    getThemes(){
      let self = this;
      this.axios
      .get(this.api + 'employee/mailing/themes', { params: { }, headers: { Authorization: localStorage.accessToken } })
      .then(response => {
        self.themes = response.data;
      });
    },
  }
}
</script>
<style scoped lang="scss">
  div.mailing { display: flex; justify-content: space-between; padding: 10px; }

  section.themes { display: flex; flex-wrap: wrap; height: 100%; }
  section.themes div.theme { width: 220px; cursor: pointer; }
  section.themes div.theme a { display: block; width: 220px; text-decoration: none; }
  section.themes div.theme div.img { height: 250px; margin: 10px; overflow: hidden; }
  section.themes div.theme div.img img { display: block; width: 200px; }
  section.themes div.theme footer { border-top: 1px solid $grayLighter3; }
  section.themes div.theme footer h5 { margin: 0; padding: 7px 10px; text-align: center; color: $grayLight; font-weight: 400; font-size: 16px; text-transform: capitalize; }
  section.themes div.theme.active footer, section.themes div.theme:hover footer { background: $orangeLight; border-top: 1px solid $orangeLight; }
  section.themes div.theme.active footer h5, section.themes div.theme:hover footer h5 { color: #fff; }

  aside { display: flex; flex-direction: column; }
</style>
