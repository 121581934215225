<template>
 <div class="box files">
    <header class="box">
      <h6>Lista załączników</h6>
      <button class="default small" @click="showUploadFilePopup()">Dodaj</button>
    </header>
    <div class="loader-box" v-if="loader == 1">
      <loader v-if="loader == 1"></loader>
    </div>
    <ul v-if="loader == 0">
      <li class="header">
        <h6>Lp.</h6>
        <h6>Nazwa</h6>
        <h6>Data dodania</h6>
        <h6>Akcje</h6>
      </li>
      <li v-for="attachment in attachments" :key="attachment.id">
        <h6>{{ attachment.id }}</h6>
        <h4>{{ attachment.name }}</h4>
        <h5>{{ attachment.createdAt }}</h5>
        <div class="actions">
          <div class="action" title="Szczegóły">
            <a :href="attachment.link">
                <i class="material-icons">search</i>
            </a>
          </div>
          <div class="action" title="Usuń plik" @click="deleteAttachment(attachment.id, attachment.name)">
            <i class="material-icons">delete</i>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import Loader from '@/components/Loader.vue'

export default {
  name: 'MailingAttachments',
  components: {
    Loader,
  },
  data(){
    return{
      loader: 1,
      attachments: 0,
    }
  },
  computed: {
    api() {
      return this.$store.state.api;
    },
    loaderTime() {
      return this.$store.state.loaderTime;
    },
    mailingReload() {
      return this.$store.state.mailingReload;
    },
  },
  created(){
    this.getAttachments();
  },
  watch:{
    mailingReload(){
      if(this.mailingReload == 1){
        this.loader = 1;
        this.getAttachments();
        this.$store.commit('changeMailingReload', 0);
      }
    }
  },
  methods: {
    showUploadFilePopup(){
      this.$store.commit('togglePopup', 1);
    },
    deleteAttachment(id, name){
      this.$store.commit('changeMailingDeleteFile', { id: id, type: 1, name: name });
      this.$store.commit('togglePopup', 6);
    },
    getAttachments(){
      let self = this;
      this.axios
      .get(this.api + 'employee/mailing/attachment', { params: { }, headers: { Authorization: localStorage.accessToken } })
      .then(response => {
        self.attachments = response.data;
        setTimeout(function(){ self.loader = 0; }, self.loaderTime);
      });
    },
  }
}
</script>
<style scoped lang="scss">
</style>
