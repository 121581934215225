<template>
  <div class="dashboard" :class="{ center: loader  == 1 }">
    <loader v-if="loader == 1"></loader>
    <div v-if="rendered && loader == 0">
    <div class="flex">
      <div class="box stat green">
        <header class="box">
          <h6>Liczba kont</h6>
        </header>
        <div class="flex">
          <h4>{{ accountsCount.amount }}</h4>
          <div class="icon">
            <i class="material-icons">people</i>
          </div>
        </div>
        <h5>Najnowszy użytkownik: {{ accountsCount.date }}</h5>
      </div>
      <div class="box stat blue">
        <header class="box">
          <h6>Liczba firm</h6>
        </header>
        <div class="flex">
          <h4>{{ companiesCount.amount }}</h4>
          <div class="icon">
            <i class="material-icons">apps</i>
          </div>
        </div>
        <h5>Najnowsza firma: {{ companiesCount.date }}</h5>
      </div>
      <div class="box stat purple">
        <header class="box">
          <h6>Liczba wykupionych usług</h6>
        </header>
        <div class="flex">
          <h4>{{ servicesCount.amount }}</h4>
          <div class="icon">
            <i class="material-icons">api</i>
          </div>
        </div>
        <h5>Ostania kupiona usługa: {{ servicesCount.date }}</h5>
      </div>
      <div class="box stat pink">
        <header class="box">
          <h6>Liczba wykonanych akcji</h6>
        </header>
        <div class="flex">
          <h4>{{ actionsCount.amount }}</h4>
          <div class="icon">
            <i class="material-icons">done</i>
          </div>
        </div>
        <h5>Ostatnia wykonana akcja: {{ actionsCount.date }}</h5>
      </div>
    </div>
    <div class="flex">
      <div class="box last green">
        <header>
          <div class="icon">
            <i class="material-icons">people</i>
          </div>
          <h6>Ostatnio utworzone konta</h6>
        </header>
        <div class="items">
          <div class="item" v-for="(account, index) in recentlyCreatedAccounts" :key="'account' + index">
            <router-link :to="{ name: 'Client', params: { clientId: account.id }}">
              <h4>{{ account.email }}</h4>
              <h5>{{ account.createDate }}</h5>
            </router-link>
          </div>
        </div>
      </div>
      <div class="box last blue">
        <header>
          <div class="icon">
            <i class="material-icons">apps</i>
          </div>
          <h6>Ostatnio utworzone firmy</h6>
        </header>
        <div class="items">
          <div class="item" v-for="(company, index) in recentlyAddedCompanies" :key="'company' + index">
            <router-link :to="{ name: 'Client', params: { clientId: company.userId}}">
              <h4>{{ company.name }}</h4>
              <h5>{{ company.createDate }}</h5>
            </router-link>
          </div>
        </div>
      </div>
      <div class="box last big purple">
        <header>
          <div class="icon">
            <i class="material-icons">api</i>
          </div>
          <h6>Ostatnio kupione usługi</h6>
        </header>
        <div class="items">
          <div class="item" v-for="(service, index) in recentlyBoughtServices" :key="'service' + index">
            <router-link :to="getServiceLink(service.serviceType)">
              <h4><span>{{ service.service }}</span> m-c</h4>
              <h5>{{ service.orderType }}</h5>
              <h5 class="wrap">{{ service.companyName }}</h5>
              <h5>{{ service.email }}</h5>
              <h5>{{ service.date }}</h5>
            </router-link>
          </div>
        </div>
      </div>
      <div class="box last big pink">
        <header>
          <div class="icon">
            <i class="material-icons">done</i>
          </div>
          <h6>Ostatnio wykonane akcje</h6>
        </header>
        <div class="items">
          <div class="item" v-for="(action, index) in recentlyMadeActions" :key="'action' + index">
            <h4>{{ action.action }}</h4>
            <h5>{{ action.person }}</h5>
            <h5>{{ action.createDate }}</h5>
          </div>
        </div>
      </div>
    </div>
    <div class="flex">
      <div class="box chart">
        <header class="box">
          <h6>Rozkład wykupionych usług</h6>
        </header>
        <apexchart type="donut" :options="services.options" :series="services.series" height="450"></apexchart>
      </div>
      <div class="box chart">
        <header class="box">
          <h6>Wirtualne Biuro - Pakiety</h6>
        </header>
        <apexchart type="donut" :options="virtualOfficePackets.options" :series="virtualOfficePackets.series" height="220"></apexchart>
      </div>
      <div class="box chart">
        <header class="box">
          <h6>Wirtualne Biuro - Okresy</h6>
        </header>
        <apexchart type="donut" :options="virtualOfficePeriods.options" :series="virtualOfficePeriods.series" height="220"></apexchart>
      </div>
    </div>
    <div class="flex">
      <div class="box chart big">
        <header class="box">
          <h6>Nowe konta i firmy w ciągu ostatnich 7 dni</h6>
        </header>
        <apexchart type="area" :options="accountCompany7days.options" :series="accountCompany7days.series"></apexchart>
      </div>
      <div class="box chart big">
        <header class="box">
          <h6>Nowe usługi w ciągu ostatnich 7 dni</h6>
        </header>
        <apexchart type="area" :options="services7days.options" :series="services7days.series"></apexchart>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
import Loader from '@/components/Loader.vue'

export default {
  name: 'Dashboard',
  components: {
    Loader
  },
  data(){
    return{
      loader: 1,
      rendered: false,
      accountsCount: {
        amount: 0,
        date: 0
      },
      companiesCount: {
        amount: 0,
        date: 0
      },
      servicesCount: {
        amount: 0,
        date: 0
      },
      actionsCount: {
        amount: 0,
        date: 0
      },
      recentlyCreatedAccounts: [],
      recentlyAddedCompanies: [],
      recentlyBoughtServices: [],
      recentlyMadeActions: [],
      services: {
        series: [15, 65, 23, 55],
        options: {
          chart: {
            foreColor: '#313131',
            width: '100%',
            height: 'auto',
          }, 
          labels: ['Wirtualne Biuro', 'Księgowość', 'Marketing', 'Doradztwo'],
          colors:['#27f19b', '#3ebbfb', '#661bf5', '#c13ff2']
        }
      },
      virtualOfficePackets: {
        series: [15, 65, 23],
        options: {
          chart: {
            foreColor: '#313131',
            width: '100%',
            height: 'auto',
          }, 
          labels: ['Mini', 'Standard', 'Komfort'],
          colors:['#27f19b', '#3ebbfb', '#661bf5']
        }
      },
      virtualOfficePeriods: {
        series: [12, 14, 7],
        options: {
          chart: {
            foreColor: '#313131',
            width: '100%',
            height: 'auto',
          }, 
          labels: ['3 m-c', '6 m-c', '12 m-c'],
          colors:['#27f19b', '#3ebbfb', '#661bf5']
        }
      },
      accountCompany7days: {
        series: [{
          name: 'Nowe konta',
          data: []
        },{
          name: 'Nowe firmy',
          data: []
        }],
        options: {
          chart: {
            foreColor: '#313131',
            background: '#ffffff',
            width: "100%",
            height: 350,
            toolbar: {
              show: false,
            }
          },
          stroke: {
            curve: 'smooth',
          },
          xaxis: {
            categories: ['6 dni temu', '5 dni temu', '4 dni temu', '3 dni temu', '2 dni temu', 'Wczoraj', 'Dzisiaj']
          },
          colors:['#27f19b', '#3ebbfb'],
          tooltip: {
            enabled: true,
            fillSeriesColor: true,
            theme: 'dark'
          }
        },
      },
      services7days: {
        series: [],
        options: {
          chart: {
            foreColor: '#313131',
            background: '#ffffff',
            width: "100%",
            height: 350,
            toolbar: {
              show: false,
            },
          },
          stroke: {
            curve: 'smooth',
          },
          xaxis: {
            categories: ['6 dni temu', '5 dni temu', '4 dni temu', '3 dni temu', '2 dni temu', 'Wczoraj', 'Dzisiaj']
          },
          colors:['#27f19b', '#3ebbfb', '#661bf5', '#c13ff2'],
          tooltip: {
            enabled: true,
            fillSeriesColor: true,
            theme: 'dark'
          }
        },
      },
    }
  },
  computed: {
    api() {
      return this.$store.state.api;
    },
  },
  methods: {
    getServiceLink(id){
      switch(id){
        case 1:
          return { name: 'VirtualOffice' }
        break;
      }
    },
    async getDashboardData() {
      let self = this;
      await this.axios
      .get(this.api + 'employee/dashboard', { headers: { Authorization: localStorage.accessToken } })
      .then(response => {
        if(response.data.status == 1) {
          let dashboardData = response.data.dashboardData;
          self.accountsCount.amount = dashboardData.accountsCount;
          if(dashboardData.recentlyCreatedAccounts.length == 0){
            self.accountsCount.date = '';
          }else{
            self.accountsCount.date = dashboardData.recentlyCreatedAccounts[0].createDate;
          }
          self.recentlyCreatedAccounts = dashboardData.recentlyCreatedAccounts;

          self.companiesCount.amount = dashboardData.companiesCount;
          if(dashboardData.recentlyAddedCompanies.length == 0){
            self.companiesCount.date = ''
          }else{
            self.companiesCount.date = dashboardData.recentlyAddedCompanies[0].createDate;
          }
          self.recentlyAddedCompanies = dashboardData.recentlyAddedCompanies;

          self.servicesCount.amount = dashboardData.servicesCount;
          if(dashboardData.recentlyBoughtServices.length == 0){
            self.servicesCount.date = '';
          }else{
            self.servicesCount.date = dashboardData.recentlyBoughtServices[0].date;
          }
          self.recentlyBoughtServices = dashboardData.recentlyBoughtServices;
          self.actionsCount.amount = dashboardData.actionsCount;
          if(dashboardData.recentlyMadeActions.length == 0){
            self.actionsCount.date = '';
          }else{
            self.actionsCount.date = dashboardData.recentlyMadeActions[0].createDate;
          }
          self.recentlyMadeActions = dashboardData.recentlyMadeActions;
          self.accountCompany7days.series[0].data = dashboardData.accountsCreatedInLastWeek;
          self.accountCompany7days.series[1].data = dashboardData.companiesCreatedInLastWeek;

          self.services7days.series = dashboardData.servicesBoughtInLastWeek;
          
          self.rendered = true;
          setTimeout(function(){ self.loader = 0; }, self.loaderTime);
          // =====================================================================================================
          // Dodać ściąganie danych do wykresów kołowych oraz generowanie wykresów kołowych dla konkretnych usług
          // =====================================================================================================
        }
      });
    },
  },
  async created() {
    await this.getDashboardData();
  },
}
</script>
<style scoped lang="scss">
  div.dashboard { padding: 10px; }
  div.dashboard.center { display: flex; justify-content: center; align-items: center; min-height: calc(100vh - 60px); }

  div.flex { display: flex; flex-wrap: wrap; }

  div.box.stat { display: flex; flex-direction: column; justify-content: space-between; width: 390px; height: 150px; }
  div.box.stat.green { background: $greenGradient; }
  div.box.stat.blue { background: $blueGradient; }
  div.box.stat.purple { background: $purpleGradient; }
  div.box.stat.pink { background: $pinkGradient; }
  div.box.stat div.flex { display: flex; justify-content: space-between; }
  div.box.stat header.box h6 { color: rgba(255, 255, 255, 0.7); }
  div.box.stat div.icon { display: flex; justify-content: center; align-items: center; width: 50px; height: 50px; margin: 0 30px; }
  div.box.stat div.icon i.material-icons { font-size: 50px; color:  rgba(255, 255, 255, 0.3); }
  div.box.stat h4 { height: 55px; margin: 0 20px; padding: 0; line-height: 55px; font-size: 45px; color: #fff; font-weight: 400; }
  div.box.stat h5 { margin: 10px; padding: 0; font-size: 12px; color:  rgba(255, 255, 255, 0.8); font-weight: 400; }

  div.box.last { width: 390px; }
  div.box.last header { display: flex; justify-content: space-between; align-items: center; height: 80px; padding: 0 25px; }
  div.box.last.green header { background: $greenGradient; }
  div.box.last.blue header { background: $blueGradient; }
  div.box.last.purple header { background: $purpleGradient; }
  div.box.last.pink header { background: $pinkGradient; }
  div.box.last header div.icon { display: flex; justify-content: center; align-items: center; width: 32px; height: 32px; }
  div.box.last header div.icon i.material-icons { font-size: 38px; color: rgba(255, 255, 255, 0.4); }
  div.box.last header h6 { margin: 0; padding: 0; font-size: 18px; color: rgba(255, 255, 255, 0.8); font-weight: 400; }
  div.box.last div.items { padding: 10px 0; }
  div.box.last div.item { display: flex; flex-direction: column; width: calc(100% - 40px); height: 55px; margin: 10px auto; padding: 7px; border: 1px solid $grayLighter3; border-radius: 3px; }
  div.box.last div.item a { display: flex; flex-direction: column; width: calc(100% - 40px); height: 69px; border-radius: 3px; text-decoration: none; }
  div.box.last.big div.item { height: 120px; }
  div.box.last.big div.item h4:first-child { margin-bottom: auto; }
  div.box.last div.item a { height: 120px; }
  div.box.last div.item h5 { margin: 0; padding: 0; font-weight: 400; font-size: 12px; color: $grayLighter; }
  div.box.last div.item h5.wrap { white-space: nowrap; overflow: hidden; text-overflow: ellipsis; }
  div.box.last div.item h4 { margin: 0; padding: 0; font-weight: 400; font-size: 14px; color: $grayLighter; width: 100%; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; }

  div.box.last.green div.item h4:first-child { color: $colorGreen; }
  div.box.last.blue div.item h4:first-child { color: $colorBlue; }
  div.box.last.purple div.item h4:first-child { color: $colorPurple; }
  div.box.last.pink div.item h4:first-child { color: $colorPink; }

  div.box.last div.item:hover { background: $orangeLight; cursor: pointer; }
  div.box.last div.item:hover h5 { color: rgba(255, 255, 255, 0.8); }
  div.box.last.green div.item:hover h4, div.box.last.blue div.item:hover h4, div.box.last.purple div.item:hover h4, div.box.last.pink div.item:hover h4 { color: #fff; }

  div.box.chart { width: 390px; }
  div.box.chart div { margin: 20px 0; }

  div.box.chart.big { width: 800px; overflow: hidden; }
</style>
