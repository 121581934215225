<template>
  <div class="employees" :class="{ center: loader  == 1 }">
    <loader v-if="loader == 1"></loader>
    <div class="flex" v-if="loader == 0">
      <div class="box person" v-for="employee in employees" :key="employee.id">
        <div class="actions">
          <div class="action" title="Edytuj" v-if="employeeLevel.level >= 10" @click="editEmployee(employee.id)">
            <i class="material-icons">edit</i>
          </div>
          <div class="action" title="Lista akcji">
            <router-link :to="{ name: 'Employee', params: { employeeId: employee.id }}">
              <i class="material-icons">format_list_bulleted</i>
            </router-link>
          </div>
        </div>
        <div class="icon">
          <i class="material-icons">person</i>
        </div>
        <div class="info">
          <h5>{{ employee.name }} {{ employee.surname }}</h5>
          <h6>Email: <span>{{ employee.email }}</span></h6>
          <div class="ranks">
            <h6>Rangi:</h6>
            <ul>
              <li v-for="(rank, index) in employee.EmployeeRanks" :key="rank.name">{{ rank.name }}{{ addChar(index, employee.EmployeeRanks.length) }}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <button class="icon-alone" title="Dodaj pracownika" @click="togglePopup(1)" v-if="loader == 0 && employeeLevel.level >= 10"><i class="material-icons">add</i></button>
    <popup-employee></popup-employee>
  </div>
</template>

<script>
import Loader from '@/components/Loader.vue'
import PopupEmployee from '@/components/popups/Employee.vue'

export default {
  name: 'Employees',
  components: {
    Loader,
    PopupEmployee
  },
  data(){
    return{
      loader: 1,
      employees: 0, 
    }
  },
  computed: {
    api() {
      return this.$store.state.api;
    },
    loaderTime() {
      return this.$store.state.loaderTime;
    },
    reloadedEmployees() {
      return this.$store.state.reloadedEmployees;
    },
    employeeLevel() {
      return this.$store.state.employeeLevel;
    },
  },
  created(){
    this.getEmployees();
  },
  watch: {
    reloadedEmployees(){
      this.getEmployees();
    },
  },
  methods: {
    togglePopup(type){
      this.$store.commit('togglePopup', type);
    },
    addChar(index, length){
      if(index + 1 != length){
        return ', '
      }else{
        return ''
      }
    },
    editEmployee(id){
      this.$store.commit('changeEmployeeEditId', id);
      this.$store.commit('togglePopup', 2);
    },
    getEmployees(){
      let self = this;
      this.axios
      .get(this.api + 'employee/employees', { headers: { Authorization: localStorage.accessToken } })
      .then(response => {
        self.employees = response.data.data;
        setTimeout(function(){ self.loader = 0; }, self.loaderTime);
      });
    },
  }
}
</script>
<style scoped lang="scss">
  div.employees { display: flex; flex-wrap: wrap; padding: 10px; }
  div.employees.center { display: flex; justify-content: center; align-items: center; min-height: calc(100vh - 60px); }

  div.flex { display: flex; flex-wrap: wrap; }

  div.box.person { display: flex; align-items: center; width: 375px; min-height: 110px; position: relative; }
  div.box.person div.icon { display: flex; justify-content: center; align-items: center; width: 100px; height: 100px; }
  div.box.person div.icon i.material-icons { font-size: 45px; color: $grayLighter; }
  div.box.person div.info { display: flex; flex-direction: column; justify-content: center; }
  div.box.person h5 { width: 200px; margin: 0 0 5px 0; padding: 0; font-size: 17px; font-weight: 600; color: $grayLighter;  }
  div.box.person h6 { margin: 0; padding: 0; font-size: 13px; font-weight: 400; color: $grayLighter2;  }
  div.box.person h6 span { color: $grayLighter; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; }
  div.box.person div.info h6:nth-child(3) { text-transform: capitalize; }
  div.box.person div.ranks { display: flex; }
  div.box.person ul { display: flex; flex-wrap: wrap; margin: 0;; padding: 0; list-style: none; }
  div.box.person ul li { margin-left: 3px; font-size: 13px; color: $grayLighter; }
  div.box.person ul li::first-letter { text-transform: uppercase; }

  div.box.person div.actions { display: flex; margin: 0; position: absolute; right: 5px; top: 5px; }
  div.box.person div.actions div.action { display: flex; justify-content: center; align-items: center; flex-shrink: 0; width: 25px; height: 25px; margin: 0 3px; 
    background: $grayLighter2; border-radius: 3px; }
  div.box.person div.actions div.action a { display: flex; justify-content: center; align-items: center; flex-shrink: 0; width: 25px; height: 25px; margin: 0 3px; 
     border-radius: 3px; text-decoration: none; }
  div.box.person div.actions div.action:hover { background: $orangeLight; cursor: pointer; }
  div.box.person div.actions div.action i.material-icons { color: $grayLight; font-size: 16px; }
  div.box.person div.actions div.action:hover i.material-icons { color: #fff; }
</style>
