<template>
  <div class="popup" v-if="visible == 3">
    <div class="shadow" @click="close()"></div>
    <div class="container">
      <header>
        <h5>Umowa najmu</h5>
        <div class="close" @click="close()">
          <i class="material-icons">close</i>
        </div>
      </header>
      <div class="wrapper">
        <button class="default" @click="downloadPdf()" :disabled="downloading.status">{{ downloading.title }}</button>
        <button class="default" @click="downloadDocx()" :disabled="downloadingDocx.status">{{ downloadingDocx.title }}</button>
        <button class="default" @click="downloadHtml()" :disabled="downloadingHtml.status">{{ downloadingHtml.title }}</button>
        <div class="center" v-if="loader"><loader></loader></div>
        <div class="document box" v-if="data">
            <div class="page">
                <div class="banner-first">
                    <img src="../../assets/logo_vo_document.png" alt="">
                </div>
                <div class="content">
                    <div class="header">
                        <header>
                            <b>UMOWA WIRTUALNEGO BIURA <br>
                            Z NAJMEM LOKALU</b>
                        </header>
                    </div>
                    <div class="text">
                        Zawarta dnia {{ data.date }} we Wrocławiu pomiędzy<br><br><br>
                        <b>AFK CENTRUM OBSŁUGI BIZNESU Sp. z o.o., ul. Świeradowska 51-57,</b><br><br>
                        <b>50-559 Wrocław, NIP 8992664769, REGON 020936750, KRS 0000327942</b><br><br>
                        reprezentowaną przez ${ WYNAJMUJĄCY } - Pełnomocnika, zwaną dalej <b>Wynajmującym</b><br><br>
                        a<br><br>
                        <div v-if="data.companyType == 'JEDNOOSOBOWA DZIAŁALNOŚĆ'">
                            {{ data.companyName }}, ul. {{ data.correspondenceAddress.street }} {{ data.correspondenceAddress.houseNumber }}, {{ data.correspondenceAddress.postCode }} {{ data.correspondenceAddress.city }}<br>
                            NIP: {{ data.nip }}, REGON: {{ data.regon }},<br>
                            Reprezentowana/y przez Właściciela {{ data.signers }}, <br>
                            zwanej/go dalej <b>Najemcą.</b>
                        </div>
                        <div v-else-if="data.companyType == 'SPÓŁKI CYWILNE PROWADZĄCE DZIAŁALNOŚĆ NA PODSTAWIE UMOWY ZAWARTEJ ZGODNIE Z KODEKSEM CYWILNYM'">
                            <div v-if="data.contractSigners.length > 1">
                                {{ data.signers }}<br>
                                parowadzący wspólnie działalność gospodarczą pod firmą<br>
                                {{ data.companyName }}, ul. {{ data.correspondenceAddress.street }} {{ data.correspondenceAddress.houseNumber }}, {{ data.correspondenceAddress.postCode }} {{ data.correspondenceAddress.city }}<br>
                                NIP: {{ data.nip }}, REGON: {{ data.regon }},<br>
                                Reprezentowanymi przez:<br>
                                <div v-for="person in data.personalPlusPosition" :key="person.name + person.surname">
                                    {{ person.name }} {{ person.surname }} - {{ person.position }}<br>
                                </div>
                                zwanymi dalej <b>Najemcą.</b>
                            </div>
                            <div v-else>
                                {{ data.signers }} prowadzący działalność gospodarczą pod firmą<br>
                                {{ data.companyName }}, ul. {{ data.correspondenceAddress.street }} {{ data.correspondenceAddress.houseNumber }}, {{ data.correspondenceAddress.postCode }} {{ data.correspondenceAddress.city }}<br>
                                NIP: {{ data.nip }}, REGON: {{ data.regon }},<br>
                                Reprezentowana/y przez:<br>
                                <div v-for="person in data.personalPlusPosition" :key="person.name + person.surname">
                                    {{ person.name }} {{ person.surname }} - {{ person.position }}<br>
                                </div>
                                zwana/y dalej <b>Najemcą.</b>
                            </div>
                        </div>
                        <div v-else>
                            {{ data.companyName }}, ul. {{ data.correspondenceAddress.street }} {{ data.correspondenceAddress.houseNumber }}, {{ data.correspondenceAddress.postCode }} {{ data.correspondenceAddress.city }}<br>
                            NIP: {{ data.nip }}, REGON: {{ data.regon }},<br>
                            Reprezentowana przez:<br>
                            <div v-for="person in data.personalPlusPosition" :key="person.name + person.surname">
                                {{ person.name }} {{ person.surname }} - {{ person.position }}<br>
                            </div> 
                            zwana dalej <b>Najemcą.</b>
                        </div>
                        <div class="clause">§ 1. PRZEDMIOT UMOWY</div>
                        <ol>
                            <li>
                                Niniejsza Umowa określa zakres usług świadczonych przez <b>Wynajmującego</b> na rzecz <b>Najemcy</b> w ramach 
                                Umowy Wirtualnego Biura z Najmem Lokalu. Głównym przedmiotem Umowy jest najem Lokalu <b>Wynajmującego</b> 
                                przez <b>Najemcę</b>. W ramach przedmiotowej Umowy <b>Wynajmujący</b> oferuje <b>Najemcy</b> również inne Usługi na 
                                warunkach określonych w Regulaminie i Cenniku.
                            </li>
                            <li>
                                <b>Wynajmujący</b> udostępnia <b>Najemcy</b>, w zamian za zapłatę przez <b>Najemcę</b> określonego w Umowie Abonamentu powierzchnię 
                                biurową w Lokalu, celem zarejestrowania pod adresem tego Lokalu siedziby prowadzonego przez <b>Najemcę</b> przedsiębiorstwa 
                                oraz posiadania adresu do korespondencji. 
                            </li>
                            <li>
                                <b>Najemcy</b> w stosunku do Lokalu przysługują tylko uprawnienia wyraźnie określone w Umowie. 
                            </li>
                            <li>
                                <b>Najemca</b> oświadcza, że nie ma prawa do ruchomości znajdujących się w Lokalu ani do podnajmu czy użyczenia Lokalu. 
                            </li>
                            <li>
                                <b>Wynajmujący</b> może udostępnić powierzchnię biurową w Lokalu także innym osobom/firmom niż <b>Najemca</b>, 
                                na co <b>Najemca</b> wyraża zgodę. 
                            </li>
                            <li>
                                <b>Strony</b> oświadczają, że <b>Wynajmujący</b> w związku z zakończeniem procesu rejestracji Panelu Klienta 
                                oraz opłaceniu Abonamentu wyraził zgodę jedynie na udostępnienie adresu Lokalu do celów rejestrowych. 
                            </li>
                        </ol>
                    </div>
                </div>
            </div>
    
            <div class="page">
                <div class="banner">
                    
                </div>
                <div class="content">
                    <div class="text">
                        <div class="clause">§ 2. OKRES OBOWIĄZYWANIA UMOWY</div>
                        <ol>
                            <li>
                                Umowa zostaje zawarta na czas określony równy: {{ data.packet.period }} {{ (data.packet.period == 3) ? 'm-ce' : 'm-cy' }}, z zastrzeżeniem dalszej treści Umowy.
                            </li>
                            <li>
                                Czas trwania Umowy jest jednocześnie okresem rozliczeniowym i dotyczy pełnych miesięcy kalendarzowych. 
                            </li>
                            <li>
                                W sytuacji, gdy początek okresu rozliczeniowego przypada po pierwszym dniu miesiąca, za miesiąc kalendarzowy uznaje 
                                się okres 30 dni kalendarzowych. 
                            </li>
                            <li>
                                <b>Pierwszy okres rozliczeniowy obowiązuje:</b> od {{ data.startAt }} do {{ data.endAt }}.
                            </li>
                            <li>
                                Umowa ulega automatycznemu przedłużaniu na kolejny okres rozliczeniowy na tych samych warunkach  
                                z zastrzeżeniem zmian w Cenniku pod warunkiem uiszczenia, najpóźniej w ostatnim dniu obowiązującego 
                                Okresu Rozliczeniowego, Abonamentu za kolejny okres rozliczeniowy. 
                            </li>
                            <li>
                                Brak wpłaty Abonamentu zgodnie z ust. 5 Umowy powyżej, powoduje wygaśnięcie Umowy.
                            </li>
                        </ol>
                        <div class="clause">§ 3. LOKAL</div>
                        <ol>
                            <li>
                                <b>Wynajmujący</b> oświadcza, że dysponuje prawem do lokalu biurowego, położonego we Wrocławiu (50-559) 
                                przy ul. Świeradowskiej 51-57 i tym samym ma prawo do podnajmu tego lokalu oraz do udostępniania w/w adresu.
                            </li>
                            <li>
                                <b>Wynajmujący</b> zezwala <b>Najemcy</b> na używanie części lokalu o powierzchni 3 mkw., o którym mowa w 
                                ust. 1 niniejszego paragrafu jako siedziby działalności gospodarczej <b>Najemcy</b>.
                            </li>
                            <li>
                                Strony ustalają, że korzystanie przez <b>Najemcę</b> z lokalu w sposób inny niż ustalony w ust. 
                                2 niniejszej umowy, wymaga formy pisemnej w postaci aneksu do niniejszej <b>Umowy</b>, lub zawarcia 
                                przez <b>Strony</b> umowy odrębnej.
                            </li>
                            <li>
                                Wszelkie wyposażenie biurowe będące w pomieszczeniach znajdujących się pod 
                                adresem wskazanym w ust. 1 stanowi własność <b>Wynajmującego</b>, a <b>Najemcy</b> nie przysługują 
                                do tego wyposażenia żadne prawa.
                            </li>
                            <li>
                                <b>Najemca</b> przyjmuje do wiadomości, iż lokal opisany w ust. 1 jest używany także przez 
                                inne osoby będące najemcami lokalu na zasadach analogicznych jak w niniejszej Umowie. 
                                <b>Najemca</b> oświadcza, że okoliczność ta nie stanowi naruszenia niniejszej Umowy przez <b>Wynajmującego</b>. 
                            </li>
                            <li>
                                <b>Wynajmujący</b> nie ma obowiązku uzyskiwania zgody ani informowania <b>Najemcy</b> o nawiązaniu lub rozwiązaniu 
                                Umowy najmu z innymi osobami używającymi lokalu, o którym mowa w ust. 1, ani przekazywania <b>Najemcy</b> 
                                jakichkolwiek danych, indywidualnych lub zbiorczych, w tym zakresie.
                            </li>
                        </ol>
                    </div>
                </div>
            </div>
    
            <div class="page">
                <div class="banner">
                    
                </div>
                <div class="content">
                    <div class="text">
                        <div class="clause">§ 4. DANE NAJEMCY</div>
                            <ol>
                                <li>
                                    <b>Najemca</b> przedstawi <b>Wynajmującemu</b> dokumenty rejestracyjne firmy: Umowę spółki (akt notarialny), WPIS do CEIDG, KRS, NIP, Regon.
                                </li>
                                <li>
                                    <b>Wynajmujący</b> zobowiązuje się do traktowania dokumentacji <b>Najemcy</b> oraz informacji związanych z wykonywaniem 
                                    niniejszej Umowy, jako materiałów poufnych, za wyjątkiem sytuacji, gdy ujawnienia informacji lub treści 
                                    zawartych w Umowie, dokumentacji lub korespondencji zażądają uprawnione do tego organy władzy publicznej.
                                </li>
                                <li>
                                    Z datą rozwiązania Umowy <b>Najemca</b> traci prawo do posługiwania się adresem wskazanym   w   
                                    § 3 ust.1. i jest zobowiązany bezzwłocznie, nie później jednak niż w terminie 14 dni, do 
                                    zmiany miejsca prowadzenia działalności oraz zgłoszenia zmiany adresu korespondencyjnego określonego w 
                                    § 3 ust.1 w Krajowym Rejestrze Sądowym lub Centralnej Ewidencji Działalności Gospodarczej.
                                </li>
                                <li>
                                    <b>Wynajmujący</b> zobowiązuje się dostarczyć <b>Najemcy</b> w terminie 14 dni od rozwiązania przedmiotowej 
                                    umowy kopię dokumentacji potwierdzającą zmianę adresu siedziby firmy wraz z potwierdzeniem ich 
                                    złożenia w odpowiednich urzędach lub sądach.
                                </li>
                                <li>
                                    W przypadku nie doręczenia przez <b>Najemcę</b> dokumentów opisanych ust 4, z tytułu dalszego korzystania z 
                                    przedmiotu Umowy bez tytułu prawnego, w sposób bezumowny, <b>Wynajmującemu</b> przysługuje kara umowna w wysokości 
                                    dziesięciokrotności kwoty czynszu, należnego za ostatni okres rozliczeniowy obowiązywania Umowy. 
                                </li>
                            </ol>
                        <div class="clause">§ 5. ABONAMENT</div>
                            <ol>
                                <li>
                                    Za udostępnienie powierzchni biurowej na siedzibę działalności gospodarczej <b>Najemca</b> zapłaci <b>Wynajmującemu</b> 
                                    Abonament zgodnie wybranym pakietem:<br>
                                    Pakiet {{ data.packet.name }} w cenie - {{ data.packet.price }} zł (słownie: {{ data.packet.priceInWords }}) + VAT.<br>
                                    Za inne czynności określone Umową, <b>Wynajmującemu</b> przysługuje wynagrodzenie, na zasadach określonych w Cenniku.
                                </li>
                                <li>
                                    Abonament płatny jest przelewem z góry zgodnie z wybranym okresem rozliczeniowym, na rachunek bankowy <b>Wynajmującego nr</b>: 
                                    <b>91 1020 5242 0000 2302 0254 6190</b> poprzez wybrany i dostępny w Panelu Klienta sposób płatności.
                                </li>
                                <li>
                                    Datą zapłaty czynszu jest data uznania rachunku bankowego <b>Wynajmującego</b> kwotą Abonamentu.
                                </li>
                                <li>
                                    Przed zakończeniem okresu rozliczeniowego na 30, 14, 7, 3, 1 dni przed zakończeniem okresu rozliczeniowego Najemca 
                                    otrzyma od Wynajmującego link przekierowujący do operatora płatniczego w celu opłacenia Abonamentu na kolejny okres rozliczeniowy.  
                                </li>
                                <li>
                                    Po zapłacie przez <b>Najemcę</b> Abonamentu <b>Wynajmujący</b> wystawia <b>Najemcy</b> fakturę VAT w formie elektronicznej, 
                                    którą przesyła na adres e-mail Najemcy. <b>Najemca</b> upoważnia <b>Wynajmującego</b> do wystawiania faktur VAT bez podpisu <b>Najemcy</b>.
                                </li>
                            </ol>
                    </div>
                </div>
            </div>
    
            <div class="page">
                <div class="banner">
                    
                </div>
                <div class="content">
                    <div class="text">
                        <div class="clause">§ 6. ROZWIĄZANIE UMOWY</div>
                        <ol>
                            <li>
                                Rozwiązanie niniejszej Umowy, może nastąpić z zachowaniem jednomiesięcznego okresu wypowiedzenia 
                                za skutkiem na koniec miesiąca jedynie z ważnych powodów, przez każdą ze <b>Stron</b> z zachowaniem formy pisemnej.
                            </li>
                            <li>
                                <b>Niezależnie</b> od §6 ust. 1 Umowy powyżej, <b>Wynajmującemu</b> przysługuje prawo do rozwiązania 
                                Umowy ze skutkiem natychmiastowym, w razie:<br><br>
                                <ol class="alphabeticalList" type="a">
                                    <li>
                                        opóźnienia się przez Najemcę z zapłatą Abonamentu o co najmniej 7 (siedem) dni,
                                    </li>
                                    <li>
                                        z powodu rażącego naruszenia postanowień przedmiotowej Umowy przez <b>Najemcę</b>. Powyższe nie pozbawia 
                                        <b>Wynajmującego</b> prawa do dochodzenia roszczeń od <b>Najemcy</b> z tytułu realizacji niniejszej Umowy wraz z odsetkami ustawowymi.
                                    </li>
                                </ol>
                            </li>
                            <li>
                                W przypadku rozwiązania niniejszej Umowy z winy lub na wniosek <b>Najemcy</b>, nie będzie przysługiwał mu 
                                zwrot żadnej kwoty z tytułu nie korzystania z przedmiotu Umowy.
                            </li>
                            <li>
                                <b>Wynajmujący</b> może rozwiązać Umowę z <b>Najemcą</b> poprzez oświadczenie w formie elektronicznej wysłane 
                                na adres e-mail <b>Najemcy</b> określony w Umowie. Dla skuteczności rozwiązania Umowy przez <b>Najemcę</b> z 
                                <b>Wynajmującym</b> konieczne jest zachowanie formy pisemnej.
                            </li>
                            <li>
                                Przywrócenie umowy z <b>Najemcą</b>, z którym <b>Wynajmujący</b> rozwiązał umowę z przyczyn leżących po 
                                stronie <b>Najemcy</b> podlega dodatkowej opłacie Aktywacyjnej.
                            </li>
                        </ol>
                        <div class="clause">§ 7. WYŁĄCZENIE ODPOWIEDZIALNOŚCI</div>
                        <ol>
                            <li>
                                <b>Wynajmujący</b> nie ponosi odpowiedzialności:<br><br>
                                <ol class="alphabeticalList" type="a">
                                    <li>
                                        za ewentualne roszczenia (w tym budżetowe) spowodowane prowadzoną przez <b>Najemcę</b> działalnością gospodarczą,
                                    </li>
                                    <li>
                                        za szkody powstałe w skutek nieprzestrzegania warunków Umowy, niewłaściwego 
                                        działania bądź zaniechania <b>Najemcy</b> lub w skutek działania siły wyższej, bądź działania lub zaniechania 
                                        osób trzecich,
                                    </li>
                                    <li>
                                        za przedmioty i dokumenty pozostawione przez <b>Najemcę</b> w Lokalu <b>Wynajmującego</b> (np. w sali konferencyjnej).
                                    </li>
                                </ol>
                            </li>
                            <li>
                                Odpowiedzialność odszkodowawcza <b>Wynajmującego</b> ograniczona jest do kwoty jednokrotnego miesięcznego czynszu.
                            </li>
                        </ol>
                    </div>
                </div>
            </div>
    
            <div class="page">
                <div class="banner">
                    
                </div>
                <div class="content">
                    <div class="text">
                        <div class="clause">§ 8. KONTAKT MIĘDZY STRONAMI</div>
                        <ol>
                            <li>
                                <b>Strony</b> zobowiązują się do wzajemnego utrzymywania kontaktu i udzielania sobie wszelkich informacji w sprawach objętych Umową. 
                            </li>
                            <li>
                                Wszelkie uwagi i zastrzeżenia (nie stanowiące zmiany zapisów niniejszej Umowy), jakie mogą powstać przy wykonywaniu niniejszej 
                                Umowy, Strony będą wnosić wzajemnie w formie pisemnej.
                            </li>
                            <li>
                                O ile Umowa nie stanowi inaczej, wszelki kontakt między <b>Stronami</b> odbywać się będzie w formie elektronicznej oraz 
                                telefonicznej na poniżej wskazane adresy e mail oraz numery telefonów:<br><br>
                                <ol class="alphabeticalList" type="a">
                                    <li>
                                        <b>Wynajmujący</b>: AFK Centrum Obsługi Biznesu  sp. z o.o.<br><br>
                                        <ul>
                                            <li>
                                                adres: ul. Świeradowska 51-57, 50-559 Wrocław
                                            </li>
                                            <li>
                                                e-mail: bok@afkcob.pl
                                            </li>
                                            <li>
                                                tel.:  +48 71 336 9521
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <b>Najemca</b>: {{ data.companyName }}<br><br>
                                        <ul>
                                            <li>
                                                adres: ul. {{ data.correspondenceAddress.street }} {{ data.correspondenceAddress.houseNumber }}, {{ data.correspondenceAddress.postCode }} {{ data.correspondenceAddress.city }}
                                            </li>
                                            <li>
                                                e-mail: {{ data.emails }}
                                            </li>
                                            <li>
                                                tel.: {{ data.phones }}
                                            </li>
                                        </ul>
                                    </li>
                                </ol>
                            </li>
                            <li>
                                Momentem doręczenia wiadomości w formie elektronicznej (e-mail) jest moment, w którym wprowadzono 
                                tę wiadomość do środka komunikacji elektronicznej.
                            </li>
                            <li>
                                <b>Strony</b> zgodnie ustalają, że <b>Najemca</b> ma możliwość zapoznać się z treścią wiadomości wysyłanych drogą mailową na bieżąco. 
                            </li>
                            <li>
                                O każdorazowej zmianie adresów mailowych, numerów telefonów lub innych danych kontaktowych 
                                <b>Strony</b> mają obowiązek niezwłocznego zawiadomienia, nie później niż 2 dni przed planowaną zmianą. 
                                W przypadku niedopełnienia powyższych obowiązków wiadomości wysłane na stary adres uważa się za skutecznie doręczone. 
                            </li>
                            <li>
                                <b>Najemca</b> obowiązany jest do niezwłocznego informowania <b>Wynajmującego</b> o zmianach wszelkich danych 
                                ewidencyjnych lub rejestrowych. Za skutki niedopełnienia tych obowiązków <b>Wynajmujący</b> nie ponosi odpowiedzialności. 
                            </li>
                            <li>
                                Jeżeli <b>Najemca</b> będący osobą prawną zaniecha powiadomienia <b>Wynajmującego</b> o zmianie adresu do korespondencji, 
                                przesyłki kierowane na adres członka zarządu, wspólnika uprawnionego do reprezentacji  uznaje się za doręczone 
                                <b>Najemcy</b> w 14 dni awizacji przesyłki rejestrowanej w urzędzie pocztowym.
                            </li>
                            <li>
                                Jeżeli <b>Najemca</b> będący osobą fizyczną  zaniecha powiadomienia <b>Wynajmującego</b> o zmianie adresu do korespondencji, 
                                przesyłki kierowane na adres do korespondencji ujawniony w CEIDG  uznaje się za doręczone w 14 dni awizacji przesyłki 
                                rejestrowanej w urzędzie pocztowym.
                            </li>
                        </ol>
                    </div>
                </div>
            </div>
    
            <div class="page">
                <div class="banner">
                    
                </div>
                <div class="content">
                    <div class="text">
                        <div class="clause">§ 9. POSTANOWIENIA KOŃCOWE</div>
                        <ol>
                            <li>
                                W sprawach nie uregulowanych niniejszą Umową mają zastosowanie obowiązujące przepisy prawa, a w 
                                szczególności Kodeks Cywilny i Kodeks Spółek Handlowych.
                            </li>
                            <li>
                                Nieskuteczność prawna – także częściowa, poszczególnych postanowień niniejszej Umowy nie umniejsza ważności pozostałej części Umowy.
                            </li>
                            <li>
                                Wszelkie spory mogące wynikać z umowy lub z Umową związane, zarówno w trakcie jej obowiązywania, 
                                jak i po jej wygaśnięciu, zerwaniu, wypowiedzeniu, odstąpieniu będą rozstrzygane polubownie, a jeżeli 
                                to okaże się niemożliwe, wyłącznie przez sąd właściwy dla siedziby <b>Wynajmującego</b>.
                            </li>
                            <li>
                                <b>Najemca</b> wyraża zgodę na Cesję przedmiotowej umowy przez <b>Wynajmującego</b> na inny podmiot poprzez 
                                oświadczenie w formie elektronicznej wysłane na adres e-mail <b>Najemcy</b> określony w przedmiotowej 
                                Umowie, bądź używany w korespondencji między <b>Stronami</b>.
                            </li>
                            <li>
                                Wszelkie zmiany postanowień niniejszej Umowy będą dokonywane w formie pisemnej pod rygorem nieważności.
                            </li>
                            <li>
                                Umowa sporządzona została w dwóch jednobrzmiących egzemplarzach, po jednym dla każdej ze stron.
                            </li>
                        </ol>
                        <div class="signatures">
                            <div class="signer">
                                <b>WYNAJMUJĄCY</b>
                            </div>
                            <div class="signer">
                                <b>NAJEMCA</b>
                            </div>
                        </div><br><br>
                    </div>
                </div>
            </div>
    
            <div class="page">
                <div class="banner">
                    
                </div>
                <div class="content">
                    <div class="text">
                        <div class="header"><b>Załącznik nr 1A</b> - Pełnomocnictwo do odbioru / {{ (data.packet.name == 'komfort') ? 'otwierania / skanowania /' : '' }} przekazywania korespondencji</div>
                        <b>PEŁNOMOCNICTWO DO ODBIORU / {{ (data.packet.name == 'komfort') ? 'OTWIERANIA / SKANOWANIA /' : '' }} PRZEKAZYWANIA  KORESPONDENCJI</b><br><br>
                        <div v-if="data.companyType == 'JEDNOOSOBOWA DZIAŁALNOŚĆ'">
                            Ja, niżej podpisana/y jako osoba prowadząca/y jednoosobową działalność gospodarczą niniejszym udzielam pełnomocnictwa:
                        </div>
                        <div v-else-if="data.companyType == 'SPÓŁKI CYWILNE PROWADZĄCE DZIAŁALNOŚĆ NA PODSTAWIE UMOWY ZAWARTEJ ZGODNIE Z KODEKSEM CYWILNYM'">
                            <div v-if="data.contractSigners.length > 1">
                                My niżej podpisani jako wspólnicy spółki cywilnej, niniejszym udzielamy pełnomocnictwa:
                            </div>
                            <div v-else>
                                Ja niżej podpisana/y jako wspólnik spółki cywilnej niniejszym udzielam pełnomocnictwa:
                            </div>
                        </div>
                        <div v-else>
                            <div v-if="data.contractSigners.length > 1">
                                My, niżej podpisani {{ data.signers }} jako osoby upoważnione do działania w imieniu spółki {{ data.companyName }}-dalej: „Najemca” niniejszym udzielam pełnomocnictwa:
                            </div>
                            <div v-else>
                                Ja, niżej podpisana/y {{ data.signers }} jako osoba upoważniona do działania w imieniu spółki {{ data.companyName }}-dalej: „Najemca” niniejszym udzielam pełnomocnictwa:
                            </div>
                        </div>
                        <br>
                        <b>AFK CENTRUM OBSŁUGI BIZNESU sp. z o.o.</b> z siedzibą we Wrocławiu przy ul. Świeradowskiej 51-57, 50-559 Wrocław wpisanej 
                        do rejestru przedsiębiorców Krajowego Rejestru Sądowego prowadzonego przez Sąd Rejonowy dla Wrocławia- Fabrycznej we 
                        Wrocławiu, VI Wydział Gospodarczy KRS o numerze KRS 0000327942, NIP 8992664769, REGON 020936750 <br><br>
                        a w szczególności zatrudnionym w niej poniższym osobom: <br>
                        <ol class="alphabeticalList" type="a">
                            <li>
                                <div><span>Beata Fecica</span> <span>legitymująca się dokumentem DO seria i nr ...</span></div>
                            </li>
                            <li>
                                <div><span>Patrycja Kowalczyk</span> <span>legitymująca się dokumentem DO seria i nr ...</span></div>
                            </li>
                            <li>
                                <div><span>Dawid Łoposzko</span> <span>legitymujący się dokumentem DO seria i nr ...</span> </div>
                            </li>
                        </ol>
                        do ODBIORU / {{ (data.packet.name == 'komfort') ? 'OTWIERANIA / SKANOWANIA /' : '' }} PRZEKAZYWANIA wszelkiej KORESPONDENCJI skierowanej do Spółki <br><br>
                        w tym korespondencji poleconej (przesyłki rejestrowane) z sądów, prokuratur, komornika, urzędu skarbowego, 
                        zakładu ubezpieczeń społecznych, organów administracji, ubezpieczycieli.<br><br>
                        Pełnomocnictwo dotyczy także korespondencji z firm kurierskich w godzinach otwarcia biura znajdującego się 
                        pod adresem ul. Świeradowskiej 51-57, 50-559 Wrocław. <br><br>
                        Pełnomocnictwo udzielone jest na czas trwania Umowy Wirtualnego Biura z Najmem Lokalu. <br><br>
                        <b>Jednocześnie wskazuję, że do obioru korespondencji ze Spółki upoważniam</b> następujące osoby:
                        <ol class="alphabeticalList" type="a" start="5">
                            <li v-for="person in data.correspondencePeopleArr" :key="'corr' + person">
                                {{ person }}
                            </li>
                        </ol>
                        <div class="signatures just-one-right">
                            <div class="signer">
                                (czytelny podpis Najemcy)
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    
            <div class="page">
                <div class="banner">
                    
                </div>
                <div class="content">
                    <div class="text">
                        <div class="header"><b>Załącznik nr 1B</b> - Cennik</div>
                        <b>PAKIET MINI</b><br><br>
                        <table>
                            <tr>
                                <th></th>
                                <th>3 m-ce</th>
                                <th>6 m-cy</th>
                                <th>12 m-cy</th>
                            </tr>
                            <tr>
                                <td><b>cena</b><br> zł netto / m-c</td>
                                <td>{{ data.miniPacketsPriceList[0].priceNetto }}</td>
                                <td>{{ data.miniPacketsPriceList[1].priceNetto }}</td>
                                <td>{{ data.miniPacketsPriceList[2].priceNetto }}</td>
                            </tr>
                            <tr>
                                <td><b>cena</b><br> zł brutto / m-c</td>
                                <td>{{ data.miniPacketsPriceList[0].priceBrutto.toFixed(2) }}</td>
                                <td>{{  data.miniPacketsPriceList[1].priceBrutto.toFixed(2) }}</td>
                                <td>{{  data.miniPacketsPriceList[2].priceBrutto.toFixed(2) }}</td>
                            </tr>
                        </table>
                        <ul class="price-list">
                            <li>
                                rejestracja firmy pod adresem: ul. Świeradowska 51-57, 50-559 Wrocław
                            </li>
                            <li>
                                odbiór korespondencji (poczta/kurier)
                            </li>
                            <li>
                                powiadomienie e-mail/sms o wpłynięciu korespondencji
                            </li>
                            <li>
                                dostęp 24/7 do korespondencji z poziomu Panelu Klienta
                            </li>
                        </ul>
                        <b>PAKIET STANDARD</b><br><br>
                        <table>
                            <tr>
                                <th></th>
                                <th>3 m-ce</th>
                                <th>6 m-cy</th>
                                <th>12 m-cy</th>
                            </tr>
                            <tr>
                                <td><b>cena</b><br> zł netto / m-c</td>
                                <td>{{ data.standardPacketsPriceList[0].priceNetto }}</td>
                                <td>{{ data.standardPacketsPriceList[1].priceNetto }}</td>
                                <td>{{ data.standardPacketsPriceList[2].priceNetto }}</td>
                            </tr>
                            <tr>
                                <td><b>cena</b><br> zł brutto / m-c</td>
                                <td>{{ data. standardPacketsPriceList[0].priceBrutto.toFixed(2) }}</td>
                                <td>{{ data. standardPacketsPriceList[1].priceBrutto.toFixed(2) }}</td>
                                <td>{{ data. standardPacketsPriceList[2].priceBrutto.toFixed(2) }}</td>
                            </tr>
                        </table>
                        <ul class="price-list">
                            <li>
                                rejestracja firmy pod adresem: ul. Świeradowska 51-57, 50-559 Wrocław
                            </li>
                            <li>
                                odbiór korespondencji (poczta/kurier/<b>fax</b>)
                            </li>
                            <li>
                                powiadomienie e-mail/sms o wpłynięciu korespondencji
                            </li>
                            <li>
                                dostęp 24/7 do korespondencji z poziomu Panelu Klienta
                            </li>
                            <li>
                                <b>przesyłanie skanu korespondencji</b>
                            </li>
                        </ul>
                        <b>PAKIET KOMFORT</b><br><br>
                        <table>
                            <tr>
                                <th></th>
                                <th>3 m-ce</th>
                                <th>6 m-cy</th>
                                <th>12 m-cy</th>
                            </tr>
                            <tr>
                                <td><b>cena</b><br> zł netto / m-c</td>
                                <td>{{ data.komfortPacketsPriceList[0].priceNetto }}</td>
                                <td>{{ data.komfortPacketsPriceList[1].priceNetto }}</td>
                                <td>{{ data.komfortPacketsPriceList[2].priceNetto }}</td>
                            </tr>
                            <tr>
                                <td><b>cena</b><br> zł brutto / m-c</td>
                                <td>{{  data.komfortPacketsPriceList[0].priceBrutto.toFixed(2) }}</td>
                                <td>{{  data.komfortPacketsPriceList[1].priceBrutto.toFixed(2) }}</td>
                                <td>{{  data.komfortPacketsPriceList[2].priceBrutto.toFixed(2) }}</td>
                            </tr>
                        </table>
                        <ul class="price-list">
                            <li>
                                rejestracja firmy pod adresem: ul. Świeradowska 51-57, 50-559 Wrocław
                            </li>
                            <li>
                                odbiór korespondencji (poczta/kurier)
                            </li>
                            <li>
                                powiadomienie e-mail/sms o wpłynięciu korespondencji
                            </li>
                            <li>
                                dostęp 24/7 do korespondencji z poziomu Panelu Klienta
                            </li>
                            <li>
                                <b>przesyłanie skanu korespondencji</b>
                            </li>
                            <li>
                                <b>sala konferencyjna 2h/m-c</b>
                            </li>
                            <li>
                                <b>dostęp do platform do zarządzania firmą – Desktomy (bezpłatny Dodatek)</b>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
    
            <div class="page">
                <div class="banner">
                    
                </div>
                <div class="content">
                    <div class="text">
                        <br><br><b>DODATKOWE USŁUGI</b><br><br>
                        <table class="add-services">
                            <tr>
                                <th>
                                    l.p.
                                </th>
                                <th>
                                    Usługa
                                </th>
                                <th>
                                    cena <br>
                                    zł netto
                                </th>
                                <th>
                                    cena <br>
                                    zł brutto
                                </th>
                            </tr>
                            <tr>
                                <td>
                                    1.
                                </td>
                                <td>
                                    Opłata aktywacyjna za przywrócenie umowy
                                </td>
                                <td>
                                    100,00
                                </td>
                                <td>
                                    123,00
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    2.
                                </td>
                                <td>
                                    Jednorazowa wysyłka korespondencji listem poleconym 
                                </td>
                                <td>
                                    25,00
                                </td>
                                <td>
                                    30,75
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    3.
                                </td>
                                <td>
                                    Wynajem sali konferencyjnej - 1H (sala mieszcząca max. 7 - 10 osób wyposażona w klimatyzację, bezpłatny dostęp do wifi,
                                    projektor multimedialny, ekran projekcyjny, flipchart, telewizor)
                                </td>
                                <td>
                                    50,00
                                </td>
                                <td>
                                    61,50
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    4.
                                </td>
                                <td>
                                    Przerwa kawowa (kawa, herbata, woda)
                                </td>
                                <td>
                                    10,00 / osobę
                                </td>
                                <td>
                                    12,30 / osobę
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>

            <div class="page">
                <div class="banner">
                    
                </div>
                <div class="content">
                    <div class="text">
                        <div class="header"><b>Załącznik nr 1C</b> - WZÓR UMOWY POWIERZENIA PRZETWARZANIA DANYCH OSOBOWYCH</div>
                        <div class="header">
                            <header><b>UMOWA powierzenia przetwarzania danych osobowych, zwana dalej Umową</b></header>
                        </div>
                        zawarta we Wrocławiu w dniu {{ data.date }} r. pomiędzy:<br><br>
                        <b>AFK CENTRUM OBSŁUGI BIZNESU</b> Sp. z o.o., ul. Świeradowska 51/57, 
                        50-559 Wrocław, NIP 8992664769, REGON 020936750, KRS 0000327942<br><br>
                        reprezentowaną przez Pełnomocnika: ${ WYNAJMUJĄCY }, 
                        zwaną dalej <b>Wynajmującym</b><br><br>
                        a<br><br>
                        <div v-if="data.companyType == 'JEDNOOSOBOWA DZIAŁALNOŚĆ'">
                            {{ data.companyName }}, ul. {{ data.correspondenceAddress.street }} {{ data.correspondenceAddress.houseNumber }}, {{ data.correspondenceAddress.postCode }} {{ data.correspondenceAddress.city }}<br>
                            NIP: {{ data.nip }}, REGON: {{ data.regon }},<br>
                            Reprezentowana/y przez Właściciela {{ data.signers }}, <br>
                            zwanej/go dalej <b>Najemcą.</b>
                        </div>
                        <div v-else-if="data.companyType == 'SPÓŁKI CYWILNE PROWADZĄCE DZIAŁALNOŚĆ NA PODSTAWIE UMOWY ZAWARTEJ ZGODNIE Z KODEKSEM CYWILNYM'">
                            <div v-if="data.contractSigners.length > 1">
                                {{ data.signers }}<br>
                                parowadzący wspólnie działalność gospodarczą pod firmą<br>
                                {{ data.companyName }}, ul. {{ data.correspondenceAddress.street }} {{ data.correspondenceAddress.houseNumber }}, {{ data.correspondenceAddress.postCode }} {{ data.correspondenceAddress.city }}<br>
                                NIP: {{ data.nip }}, REGON: {{ data.regon }},<br>
                                Reprezentowanymi przez:<br>
                                <div v-for="person in data.personalPlusPosition" :key="person.name + person.surname">
                                    {{ person.name }} {{ person.surname }} - {{ person.position }}<br>
                                </div>
                                zwanymi dalej <b>Najemcą.</b>
                            </div>
                            <div v-else>
                                {{ data.signers }} prowadzący działalność gospodarczą pod firmą<br>
                                {{ data.companyName }}, ul. {{ data.correspondenceAddress.street }} {{ data.correspondenceAddress.houseNumber }}, {{ data.correspondenceAddress.postCode }} {{ data.correspondenceAddress.city }}<br>
                                NIP: {{ data.nip }}, REGON: {{ data.regon }},<br>
                                Reprezentowana/y przez:<br>
                                <div v-for="person in data.personalPlusPosition" :key="person.name + person.surname">
                                    {{ person.name }} {{ person.surname }} - {{ person.position }}<br>
                                </div>
                                zwana/y dalej <b>Najemcą.</b>
                            </div>
                        </div>
                        <div v-else>
                            {{ data.companyName }}, ul. {{ data.correspondenceAddress.street }} {{ data.correspondenceAddress.houseNumber }}, {{ data.correspondenceAddress.postCode }} {{ data.correspondenceAddress.city }}<br>
                            NIP: {{ data.nip }}, REGON: {{ data.regon }},<br>
                            Reprezentowana przez:<br>
                            <div v-for="person in data.personalPlusPosition" :key="person.name + person.surname">
                                {{ person.name }} {{ person.surname }} - {{ person.position }}<br>
                            </div> 
                            zwana dalej <b>Najemcą.</b>
                        </div>
                        <div class="clause">§ 1. <br>Definicje</div>
                        <ol>
                            <li>
                                Podmiot przetwarzający – podmiot, któremu powierzono przetwarzanie danych osobowych na mocy umowy powierzenia z Najemcą, 
                                zwany także Wynajmującym.
                            </li>
                            <li>
                                Administrator - organ, jednostka organizacyjna, podmiot lub osoba, decydujące o celach i środkach przetwarzania danych osobowych, 
                                zwany także Najemcą.
                            </li>
                            <li>
                                Zbiór danych - każdy posiadający strukturę zestaw danych o charakterze osobowym, dostępnych według określonych kryteriów, 
                                niezależnie od tego, czy zestaw ten jest rozproszony lub podzielony funkcjonalnie.
                            </li>
                            <li>
                                Przetwarzanie danych - jakiekolwiek operacje wykonywane na danych osobowych, takie jak zbieranie, 
                                utrwalanie, przechowywanie, opracowywanie, zmienianie, udostępnianie i usuwanie, a zwłaszcza te, które wykonuje 
                                się w systemach informatycznych.
                            </li>
                            <li>
                                Rozporządzenie- Rozporządzenie Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. 
                                w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu 
                                takich danych oraz uchylenia dyrektywy 95/46/WE (ogólne rozporządzenie o ochronie danych).
                            </li>
                            <li>
                                Inny podmiot przetwarzający - podmiot, któremu podmiot przetwarzający w imieniu administratora pod-powierzył w 
                                całości lub częściowo przetwarzanie danych osobowych.
                            </li>
                        </ol>
                    </div>
                </div>
            </div>
    
            <div class="page">
                <div class="banner">
                    
                </div>
                <div class="content">
                    <div class="text">
                        <div class="clause">§ 2. <br>Przedmiot Umowy, cel, charakter i zakres</div>
                        <ol>
                            <li>
                                Przedmiotem umowy jest powierzenie przez Najemcę danych osobowych do przetwarzania przez Wynajmującego.
                            </li>
                            <li>
                                Wynajmujący przetwarza dane osobowe wyłącznie na udokumentowane polecenie administratora – 
                                co dotyczy też przekazywania danych osobowych do państwa trzeciego lub organizacji międzynarodowej – 
                                chyba że obowiązek taki nakłada na niego prawo Unii lub prawo państwa członkowskiego, któremu podlega 
                                podmiot przetwarzający; w takim przypadku przed rozpoczęciem przetwarzania podmiot przetwarzający 
                                informuje administratora o tym obowiązku prawnym, o ile prawo to nie zabrania udzielania takiej 
                                informacji z uwagi na ważny interes publiczny.
                            </li>
                            <li>
                                Celem powierzenia jest świadczenie usług wirtualnego biura z najmem lokalu.
                            </li>
                            <li>
                                Charakter przewarzania danych dotyczy przetwarzania danych osobowych w formie papierowej oraz przy wykorzystaniu systemów informatycznych.
                            </li>
                        </ol>
                        <div class="clause">§ 3. <br>Czas trwania</div>
                        <ol>
                            <li>
                                Podmiot przetwarzający uprawniony jest do przetwarzania powierzonych danych do dnia wygaśnięcia lub rozwiązania Umowy.
                            </li>
                            <li>
                                W terminie 30 dni od ustania Umowy, Podmiot przetwarzający zobowiązany jest do usunięcia powierzonych danych 
                                ze wszystkich nośników, programów i aplikacji, chyba, że obowiązek ich dalszego przetwarzania wynika z odrębnych przepisów prawa.
                            </li>
                            <li>
                                Ponadto, Podmiot przetwarzający w terminie 30 dni od ustania Umowy zobowiązany jest do zwrotu 
                                powierzonych danych na nośnikach papierowych lub elektronicznych.
                            </li>
                        </ol>
                        <div class="clause">§ 4. <br>Obowiązki i prawa</div>
                        <ol>
                            <li>
                                Wynajmujący zobowiązuje się współpracować z Najemcą w zakresie udzielania odpowiedzi na żądania osoby, 
                                której dane dotyczą, opisane w rozdziale III Rozporządzenia (w szczególności informowanie i przejrzysta 
                                komunikacja, dostęp do danych, obowiązek informacyjny, prawo dostępu, prawo do sprostowania danych, usunięcia 
                                danych, ograniczenia przetwarzania, przenoszenia danych, prawo sprzeciwu, zautomatyzowane podejmowanie decyzji).
                            </li>
                            <li>
                                Wynajmujący zobowiązuje się do pomocy Najemcy w wywiązaniu się z obowiązków określonych w art. 32-36 
                                Rozporządzenia (w szczególności dla bezpieczeństwa przetwarzania, zgłaszania naruszenia ochrony danych 
                                osobowych organowi nadzorczemu, zawiadamiania osoby, której dane dotyczą, o naruszeniu ochrony danych osobowych, 
                                przeprowadzania oceny skutków dla ochrony danych osobowych, konsultacji z organem nadzorczym).
                            </li>
                            <li>
                                Wynajmujący zobowiązuje się do udostępnienia Najemcy wszelkich informacji niezbędnych do wykazania 
                                spełnienia obowiązków spoczywających na Wynajmującym oraz umożliwi Najemcy lub audytorowi upoważnionemu 
                                przez Najemcę przeprowadzanie audytów, w tym inspekcji, współpracując przy działaniach sprawdzających i naprawczych.
                            </li>
                        </ol>
                    </div>
                </div>
            </div>
    
            <div class="page">
                <div class="banner">
                    
                </div>
                <div class="content">
                    <div class="text">
                        <div class="clause">§ 5. <br>Zgłaszanie incydentów</div>
                        <ol>
                            <li>
                                Wynajmujący zobowiązuje się po stwierdzeniu naruszenia ochrony danych osobowych do zgłoszenia tego Najemcy 
                                bez zbędnej zwłoki (maksymalnie do 48h).
                            </li>
                            <li>
                                Informacja przekazana Najemcy powinna zawierać co najmniej:<br><br>
                                <ol type="a">
                                    <li>
                                        opis charakteru naruszenia oraz - o ile to możliwe - wskazanie kategorii i przybliżonej liczby osób, 
                                        których dane zostały naruszone i ilości/rodzaju danych, których naruszenie dotyczy,
                                    </li>
                                    <li>
                                        opis możliwych konsekwencji naruszenia,
                                    </li>
                                    <li>
                                        opis zastosowanych lub proponowanych do zastosowania przez Wynajmującego środków w celu zaradzenia naruszeniu, 
                                        w tym minimalizacji jego negatywnych skutków.
                                    </li>
                                </ol>
                            </li>
                        </ol>
                        <div class="clause">§ 6. <br>Korzystanie przez Wynajmującego z usług innego podmiotu przetwarzającego</div>
                        <ol>
                            <li>
                                Wynajmujący w ramach realizacji Umowy korzysta z usług innego podmiotu przetwarzającego (dostawca usług 
                                hostingowych, podmioty współpracujące przy realizacji usług), a Najemca przyjmuje to do wiadomości i wyraża na to zgodę.
                            </li>
                            <li>
                                Jeżeli inny podmiot przetwarzający nie wywiąże się ze spoczywających na nim obowiązków ochrony danych, 
                                pełna odpowiedzialność wobec Najemcy za wypełnienie obowiązków tego innego podmiotu przetwarzającego spoczywa na Wynajmującym.
                            </li>
                        </ol>
                        <div class="clause">§ 7. <br>Deklarowane środki techniczne i organizacyjne</div>
                        <ol>
                            <li>
                                Wynajmujący gwarantuje, że każda osoba realizująca Umowę zobowiązana jest do bezterminowego zapewnienia 
                                poufności danych osobowych przetwarzanych w związku z wykonywaniem Umowy, a w szczególności do tego, że nie 
                                będzie przekazywać, ujawniać i udostępniać tych danych osobom nieuprawnionym. Jednocześnie każda osoba 
                                realizująca Umowę zobowiązana jest do zachowania w tajemnicy sposobów zabezpieczenia danych osobowych.
                            </li>
                            <li>
                                Wynajmujący deklaruje stosowanie środków technicznych i organizacyjnych określonych w art. 32 Rozporządzenia, 
                                jako adekwatnych do zidentyfikowanego ryzyka naruszenia praw lub wolności powierzonych danych osobowych a w szczególności:<br><br>
                                <ol type="a">
                                    <li>
                                        pseudonimizację i szyfrowanie danych osobowych;
                                    </li>
                                    <li>
                                        zdolność do ciągłego zapewnienia poufności, integralności, dostępności i odporności systemów i usług przetwarzania;
                                    </li>
                                    <li>
                                        zdolność do szybkiego przywrócenia dostępności danych osobowych i dostępu do nich w razie incydentu fizycznego lub technicznego;
                                    </li>
                                    <li>
                                        regularne testowanie, mierzenie i ocenianie skuteczności środków technicznych i organizacyjnych mających zapewnić 
                                        bezpieczeństwo przetwarzania;
                                    </li>
                                </ol>
                            </li>
                            <li>
                                Wynajmujący zobowiązuje się stosować ochronę powierzonych danych przed niedozwolonym lub niezgodnym z 
                                prawem przetwarzaniem (zniszczeniem, utraceniem, zmodyfikowaniem, nieuprawnionym ujawnieniem lub nieuprawnionym 
                                dostępem do danych osobowych przesyłanych, przechowywanych lub w inny sposób przetwarzanych) oraz przypadkową utratą, 
                                zniszczeniem lub uszkodzeniem, za pomocą odpowiednich środków technicznych lub organizacyjnych („integralność i poufność”).
                            </li>
                        </ol>
                    </div>
                </div>
            </div>
    
            <div class="page">
                <div class="banner">
                    
                </div>
                <div class="content">
                    <div class="text">
                        <div class="clause">§ 8. <br>Postanowienia końcowe</div>
                        <ol>
                            <li>
                                Umowa zastępuje wszelkie inne ustalenia dokonane pomiędzy Wynajmującym a Najemcą dotyczące przetwarzania 
                                danych osobowych bez względu na to, czy zostały uregulowane umową czy innym instrumentem prawnym. 
                            </li>
                            <li>
                                W zakresie nieuregulowanym Umową mają zastosowanie przepisy prawa obowiązującego na terenie Rzeczypospolitej Polskiej, 
                                w tym Rozporządzenia.
                            </li>
                            <li>
                                Wszelkie zmiany Umowy wymagają formy pisemnej pod rygorem nieważności.
                            </li>
                            <li>
                                Umowę sporządzono w dwóch jednobrzmiących egzemplarzach, po jednym dla każdej ze Stron.
                            </li>
                        </ol>
                        <div class="signatures">
                            <div class="signer">
                                Najemca
                            </div>
                            <div class="signer">
                                Wynajmujący
                            </div>
                        </div>
                    </div>
                </div>
                <div class="footer">
                    <div class="footer-header">
                        AFK  Centrum  Obsługi   Biznesu  Sp. z o. o.
                    </div>
                    <div class="footer-content">
                        ul. Świeradowska 51-57, 50-559 Wrocław | tel.  +48 071 336 95 21 | fax:.  +48 071 336 18 17 | http://www.afkcob.pl | e-mail :bok@afkcob.pl
                        NIP:&nbsp;8992664769 | REGON: 020936750 | KRS: 0000327942 | Sąd  Rejonowy  dla  Wrocławia – Fabrycznej | Kapitał Zakł. 1.000.000,00 PLN                    
                    </div>
                </div>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loader from '@/components/Loader.vue'

export default {
  name: 'PopupVirtualOfficeDocument',
   components: {
    Loader,
  },
  data(){
    return{
        data: '',
        loader: 1,
        downloadingTitles: ['Pobierz PDF', 'Pobieranie...'],
        downloading: {
            title: 'Pobierz PDF',
            status: false
        },
        downloadingDocxTitles: ['Pobierz DOCX', 'Pobieranie...'],
        downloadingDocx: {
            title: 'Pobierz DOCX',
            status: false
        },
        downloadingHtmlTitles: ['Pobierz HTML', 'Pobieranie...'],
        downloadingHtml: {
            title: 'Pobierz HTML',
            status: false
        },
        documentId: 0,
    }
  },
  props: {
      ids: Object
  },
   watch: {
    ids: {
        handler() {
            this.getDocumentInfo();
        },
        deep: true
    },
  },
  computed:{
    visible(){
      return this.$store.state.popup;
    },
    api() {
      return this.$store.state.api;
    },
  },
  methods: {
    close(){
      this.$store.commit('togglePopup', 0);
      document.body.style = "overflow-y: overlay";
    },
    getDocumentInfo() {
        this.loader = 1;
        let self = this;
        this.axios
        .get(this.api + 'employee/documents/virtual-office/data', { params: { orderId: this.ids.orderId, companyId: this.ids.companyId }, headers: { Authorization: localStorage.accessToken } })
        .then(response => {
            if(response.data.status == 1) {
                self.documentId = response.data.data.id;
                let downloadedData = response.data.data.content;
                let counter;
                let date = new Date(downloadedData.date);
                downloadedData.date = `${ (date.getDate() < 10) ? '0' + date.getDate() : date.getDate() }.${ (date.getMonth() < 10) ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1) }.${ date.getFullYear() }`;
                let startAt = new Date(downloadedData.packet.startAt);
                downloadedData.startAt = `${ (startAt.getDate() < 10) ? '0' + startAt.getDate() : startAt.getDate() }.${ (startAt.getMonth() < 10) ? '0' + (startAt.getMonth() + 1) : (startAt.getMonth() + 1) }.${ startAt.getFullYear() }`;
                let endAt = new Date(downloadedData.packet.endAt);
                downloadedData.endAt = `${ (endAt.getDate() < 10) ? '0' + endAt.getDate() : endAt.getDate() }.${ (endAt.getMonth() < 10) ? '0' + (endAt.getMonth() + 1) : (endAt.getMonth() + 1) }.${ endAt.getFullYear() }`;
                downloadedData.personalPlusPosition = [];
                downloadedData.signers = '';
                counter = 0;
                downloadedData.contractSigners.forEach(person => {
                    if(person.position == 'inne') {
                        downloadedData.personalPlusPosition.push({
                            position: person.otherPosition,
                            name: person.name,
                            surname: person.surname
                        });
                    } else {
                        downloadedData.personalPlusPosition.push({
                            position: person.position,
                            name: person.name,
                            surname: person.surname
                        });
                    };
                     if(counter == 0) {
                        downloadedData.signers = downloadedData.signers.concat(`${ person.name } ${ person.surname }`);
                    } else {
                        downloadedData.signers = downloadedData.signers.concat(`, ${ person.name } ${ person.surname }`);
                    };
                    counter++;
                });
                downloadedData.correspondencePeopleArr = [];
                downloadedData.correspondencePeople.forEach(person => {
                    downloadedData.correspondencePeopleArr.push(`
                        ${ person.name } ${ person.surname } legitymująca/y się 
                        ${ (person.identity_card_type == 1) ? 'numerem PESEL' : 'paszportem seria i nr' } ${ person.id }
                    `);
                });
                downloadedData.miniPacketsPriceList = [];
                downloadedData.standardPacketsPriceList = [];
                downloadedData.komfortPacketsPriceList = [];
                downloadedData.packetsPriceList.forEach(packet => {
                    if(packet.name == 'mini') {
                        downloadedData.miniPacketsPriceList.push(packet);
                    } else if(packet.name == 'standard') {
                        downloadedData.standardPacketsPriceList.push(packet);
                    } else {
                        downloadedData.komfortPacketsPriceList.push(packet);
                    }
                });
                downloadedData.miniPacketsPriceList.sort((a, b) => a - b);
                downloadedData.standardPacketsPriceList.sort((a, b) => a - b);
                downloadedData.komfortPacketsPriceList.sort((a, b) => a - b);
                downloadedData.emails = '';
                counter = 0;
                downloadedData.correspondenceEmails.forEach(email => {
                    if(counter == 0) {
                        downloadedData.emails = downloadedData.emails.concat(`${ email.email }`);
                    } else {
                        downloadedData.emails = downloadedData.emails.concat(`, ${ email.email }`);
                    };
                    counter++;
                });
                downloadedData.phones = '';
                counter = 0;
                downloadedData.correspondencePhones.forEach(phone => {
                    if(counter == 0) {
                        downloadedData.phones = downloadedData.phones.concat(`${ phone.number }`);
                    } else {
                        downloadedData.phones = downloadedData.phones.concat(`, ${ phone.number }`);
                    };
                    counter++;
                });
                self.data = downloadedData;
                self.loader = 0;
            } else {
                console.log(response.data.msg)
            };
        });
    },
    downloadPdf() {
        this.downloading.status = true;
        this.downloading.title = this.downloadingTitles[1];
        let self = this;
        this.axios
        .get(this.api + 'employee/documents/virtual-office/download', { params: { documentId: this.documentId }, headers: { Authorization: localStorage.accessToken }, responseType: 'blob' })
        .then(response => {
            if(response.data.status == 0) {
                this.downloading.status = false;
                this.downloading.title = this.downloadingTitles[0];
            } else {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', self.data.nip + 'UmowaWB.pdf');
                document.body.appendChild(link);
                link.click();
                this.downloading.status = false;
                this.downloading.title = this.downloadingTitles[0];
            }
        });
    },
    downloadDocx() {
        this.downloadingDocx.status = true;
        this.downloadingDocx.title = this.downloadingDocxTitles[1];
        let self = this;
        this.axios
        .get(this.api + 'employee/documents/virtual-office/docx/download', { params: { documentId: this.documentId }, headers: { Authorization: localStorage.accessToken }, responseType: 'blob' })
        .then(response => {
            if(response.data.status == 0) {
                this.downloadingDocx.status = false;
                this.downloadingDocx.title = this.downloadingDocxTitles[0];
            } else {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', self.data.nip + 'UmowaWB.docx');
                document.body.appendChild(link);
                link.click();
                this.downloadingDocx.status = false;
                this.downloadingDocx.title = this.downloadingDocxTitles[0];
            }
        });
    },
    downloadHtml() {
        this.downloadingHtml.status = true;
        this.downloadingHtml.title = this.downloadingHtmlTitles[1];
        let self = this;
        this.axios
        .get(this.api + 'employee/documents/virtual-office/html/download', { params: { documentId: this.documentId }, headers: { Authorization: localStorage.accessToken }, responseType: 'blob' })
        .then(response => {
            if(response.data.status == 0) {
                this.downloadingHtml.status = false;
                this.downloadingHtml.title = this.downloadingHtmlTitles[0];
            } else {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', self.data.nip + 'UmowaWB.html');
                document.body.appendChild(link);
                link.click();
                this.downloadingHtml.status = false;
                this.downloadingHtml.title = this.downloadingHtmlTitles[0];
            }
        });
    },
  },
}
</script>
<style scoped lang="scss">
  div.flex { display: flex; flex-wrap: wrap; }
  div.popup div.container { width: 1000px; }

  div.document { padding: 20px; margin: 0 0 20px 0; }
  button { margin: 0 0 20px 0; }
  div.wrapper button:nth-child(2) { margin-left: 20px; }
  div.wrapper button:nth-child(3) { margin-left: 20px; }

  .content {
        margin: 20px 20px;
        // height: 250mm;
    }

    .content:first-child {
        border-top: 1px rgba(255, 109, 0, 0.5) solid;
    }

    .banner-first {
        border-bottom: 3px #FF6D00 solid;
        opacity: 0.5;
    }

    .header {
        width: 100%;
        font-size: 15px;
        margin-top: 30px;
        margin-bottom: 30px;
    }

    div.header header {
        text-align: center;
        display: flex;
        justify-content: center;
    }

    .header-small {
        text-align: center;
        font-size: 14px;
        margin: 20px 0;
    }

    .text {
        font-size: 10pt;
        text-align: justify;
    }

    .margin-text-box {
        margin-left: 47px;
    }

    .clause {
        margin-top: 17px;
        font-weight: bold;
        text-align: center;
    }

    ol {
        padding: 0 0 0 20px;
    }

    li {
        text-align: justify;
        margin-bottom: 15px;
        padding-left: 7px;
    }

    li div {
        margin-right: 120px;
    }

    li div span {
        text-align: left;
        margin-left: 20px;
    }

    li div span:first-child {
        margin: 0;
        min-width: 200px;
    }

    .footer {
        border-top: 3px #FF6D00 solid;
        opacity: 0.5;
        font-size: 10px;
        font-family: Palatino, "Palatino Linotype";
    }

    .footer-header {
        font-weight: bolder;
        text-align: center;
        font-size: 11px;
    }

    .footer-content {
        text-align: justify;
        text-align-last: justify;
    }

    .signatures {
        display: flex;
        justify-content: space-between;
        margin-top: 90px;
        margin-left: 47px;
    }

    .signatures.just-one-right {
        margin-top: 60px;
        flex-direction: column;
        align-items: flex-end;
    }

    .signer {
        width: 30%;
        border-top: 1px dotted;
        text-align: center;
    }

    .attachment {
        margin-left: 20px;
        margin-bottom: 10px;
    }

    table, th, td {
        border: 1px solid black;
        border-collapse: collapse;
    }

    td:first-child {
        background-color: rgb(217, 217, 217);
        text-align: left;
    }

    td {
        text-align: center;
        padding: 5px;
    }

    th {
        text-align: center;
        background-color: rgb(217, 217, 217);
        padding: 5px 20px;
    }

    table.add-services td:first-child {
        background-color: white;
        text-align: center;
    }

    ul.price-list li {
        margin: 0;
    }

    .center {
        display: flex;
        justify-content: center;
        height: 100px;
    }

  @media (max-width: 1000px) {
    div.popup div.container { width: calc(100vw - 20px); }
  }
</style>
