<template>
  <div class="popup" v-if="visible == 1">
    <div class="shadow" @click="close()"></div>
    <div class="container">
      <header>
        <h5>Zmień status</h5>
        <div class="close" @click="close()">
          <i class="material-icons">close</i>
        </div>
      </header>
      <div class="wrapper">
        <div class="flex">
          <div class="status green" :class="{ 'bg-green': clicked == 1 }" v-if="clientEdit.status != 1" @click="confirm(1)">
            <i class="material-icons">payment</i>
            <h5>Aktywny</h5>
          </div>
          <div class="status blue" :class="{ 'bg-blue': clicked == 0 }" v-if="clientEdit.status != 0" @click="confirm(0)">
            <i class="material-icons">payment</i>
            <h5>Nieaktywny</h5>
          </div>
          <div class="status orange" :class="{ 'bg-orange': clicked == 2 }" v-if="clientEdit.status != 2" @click="confirm(2)">
            <i class="material-icons">close</i>
            <h5>Zbanowany</h5>
          </div>
        </div>
        <div class="flex-between">
          <button class="default small reverse" @click="close()">Anuluj</button>
          <button class="default small" @click="changeStatus()">Potwierdź</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PopupClientStatus',
  data(){
    return{
      clicked: -1,
    }
  },
  computed:{
    visible(){
      return this.$store.state.popup;
    },
    api() {
      return this.$store.state.api;
    },
    clientEdit() {
      return this.$store.state.clientEdit;
    },
  },
  methods: {
    close(){
      this.clicked = -1;
      this.$store.commit('togglePopup', 0);
      document.body.style = "overflow-y: overlay";
    },
    confirm(type){
      this.clicked = type;
    },
    changeStatus(){
      if(this.clicked == -1){
        this.$store.commit('toggleAlert', { visibility: 1, type: 0, msg: 'Proszę potwierdzić zmianę klikając na ikonę' });
      }else{
        let self = this;
        this.axios
        .put(this.api + 'employee/client/status', { id: this.clientEdit.id, status: this.clicked }, { headers: { Authorization: localStorage.accessToken } })
        .then(response => {
          if(response.data.status){
            self.close();
            self.$store.commit('reloadClients');
          }
          self.$store.commit('toggleAlert', {
            visibility: 1,
            type: response.data.status,
            msg: response.data.msg
          });
        });
      }
    }
  },
}
</script>
<style scoped lang="scss">
  div.flex { display: flex; flex-wrap: wrap; justify-content: space-around; margin: 40px 0; }
  div.flex-between { display: flex; flex-wrap: wrap; justify-content: space-between; margin: 20px 0; }

  div.status { display: flex; flex-direction: column; justify-content: flex-end; align-items: center; width: 140px; height: 140px; margin: 0 10px 10px 0;
   border: 2px solid $grayLighter2; border-radius: 3px; cursor: pointer; }
  div.status i.material-icons { margin: 0 0 13px 0; font-size: 40px; color: $grayLighter2; }
  div.status h5 { height: 50px; margin: 0; padding: 0 15px; font-weight: 300; font-size: 14px; color: $grayLighter2; text-align: center; }

  div.status.bg-green, div.status.green:hover { background: $greenGradient; border-color: $colorGreen; }
  div.status.bg-green i.material-icons, div.status.green:hover i.material-icons{ color: #fff;}
  div.status.bg-green h5, div.status.green:hover h5 { color: #fff; font-weight: 500; }
  div.status.bg-blue, div.status.blue:hover { background: $blueGradient; border-color: $colorBlue; }
  div.status.bg-blue i.material-icons, div.status.blue:hover i.material-icons{ color: #fff;}
  div.status.bg-blue h5, div.status.blue:hover h5 { color: #fff; font-weight: 500; }
  div.status.bg-orange, div.status.orange:hover { background: $orangeGradient; border-color: $colorOrange; }
  div.status.bg-orange i.material-icons, div.status.orange:hover i.material-icons{ color: #fff;}
  div.status.bg-orange h5, div.status.orange:hover h5 { color: #fff; font-weight: 500; }
</style>
