<template>
  <nav>
    <ul>
      <li :class="{ active: activeRoute == 'Dashboard', inactive: activeRoute != 'Dashboard' }">
        <div class="rect"></div>
        <router-link to="/dashboard"> 
          <i class="material-icons">dashboard</i>
          <p>Dashboard</p>
        </router-link>
      </li>
      <li :class="{ active: activeRoute == 'Clients' || activeRoute == 'Client', inactive: activeRoute != 'Clients' && activeRoute != 'Client' }">
        <div class="rect"></div>
        <router-link to="/klienci"> 
          <i class="material-icons">people</i>
          <p>Klienci</p>
        </router-link>
      </li>
      <li :class="{ active: activeRoute == 'Mailing' || activeRoute == 'MailingTheme', inactive: activeRoute != 'Mailing' && activeRoute != 'MailingTheme' }">
        <div class="rect"></div>
        <router-link to="/mailing"> 
          <i class="material-icons">email</i>
          <p>Mailing</p>
        </router-link>
      </li>
      <li :class="{ active: activeRoute == 'VirtualOffice', inactive: activeRoute != 'VirtualOffice' }">
        <div class="rect"></div>
        <router-link to="/wirtualne-biuro"> 
          <i class="material-icons">important_devices</i>
          <p>Wirtualne Biuro</p>
        </router-link>
      </li>
   </ul>
   <ul>
     <li :class="{ active: activeRoute == 'Employees' || activeRoute == 'Employee', inactive: activeRoute != 'Employees' && activeRoute != 'Employee'  }">
        <div class="rect"></div>
        <router-link to="/pracownicy"> 
          <i class="material-icons">people</i>
          <p>Pracownicy</p>
        </router-link>
      </li>
      <li :class="{ active: activeRoute == '', inactive: activeRoute != 'd' }">
        <div class="rect"></div>
        <router-link to="" @click.native="logoutRequest()"> 
          <i class="material-icons">exit_to_app</i>
          <p>Wyloguj</p>
        </router-link>
      </li>
   </ul>
  </nav>
</template>

<script>
export default {
  name: 'TheNav',
  data(){
    return{ 
      activeRoute: '',
    }
  },
  computed:{
    api(){
      return this.$store.state.api;
    },
  },
  created(){
    this.activeRoute = this.$route.name;
  },
  watch: {
    $route(to, from) {
      this.activeRoute = this.$route.name;
    },
  },
  methods: {
    logoutRequest(){
      let self = this;
      this.axios
      .put(this.api + 'employee/auth/logout', {}, { headers: { Authorization: localStorage.accessToken } })
      .then(response => {
      }).then(function () {
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");
        self.$router.push('/')
      });
    },
  }
}
</script>
<style scoped lang="scss">
  nav { display: flex; flex-direction: column; width: 250px; height: calc(100vh - 60px); background: #fff; overflow-y: auto; 
    box-shadow: 0px 5px 10px $grayLighter2; z-index: 2;  }
  
  nav ul { margin: 0; padding: 0; list-style: none; overflow: hidden; }
  nav ul:nth-child(2) { margin-top: auto; }
  nav ul li { height: 50px; margin: 0; position: relative; border-top: 1px solid $grayLighter5; }
  nav ul li div.rect { display: none; margin-top: 0px; height: 50px; width: 4px; position: absolute; background: $orangeLight; }
  nav ul li a { display: flex; align-items: center; height: 50px; padding: 0 15px; text-decoration: none; }
  nav ul li a i.material-icons { color: $grayLight; font-size: 19px; }
  nav ul li a p { margin: 0 0 0 15px; padding: 0; font-size: 16px; color: $grayLight; font-weight: 400; text-transform: capitalize; }

  nav ul li.active { background: $orangeLight; border-top: 1px solid rgba(255, 255, 255, 0.3); }
  nav ul li.active a i.material-icons { color: #fff; }
  nav ul li.active a p { color: #fff; }

  nav ul li.inactive:hover div.rect { display: block; }
  nav ul li.inactive:hover a i.material-icons { color: $orangeLight; }
  nav ul li.inactive:hover a p { color: $orangeLight; }
</style>
